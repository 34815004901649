import { FC, useContext } from 'react';
import {
  Button, Col,
  Form, Input, Row,
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'features/sidebar/sidebarSlice';

import styles from '../Authentication.module.scss';

interface LoginProps {
  handleSubmit: () => void;
  handleForgotPassword: () => void;
  canSubmit: boolean;
}

const Login: FC<LoginProps> = ({ handleSubmit, handleForgotPassword, canSubmit }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item
          label={intl.formatMessage({ id: 'form.passwordShort' })}
          className={styles.passwordInput}
          name="password"
          rules={[
            {
              required: true,
              message: <FormattedMessage id="validation.password" />,
            },
          ]}
        >
          <Input.Password data-qa="passwordInputField" />
        </Form.Item>
      </Col>
      <Col span={24} className="text-center">
        <Button type="link" className={styles.link} onClick={handleForgotPassword}>
          <FormattedMessage id="form.control.forgotPassword" />
        </Button>
      </Col>
      <Col span={12} className="text-center">
        <Button type="link" className="btn" onClick={() => dispatch(toggleSidebar())}>
          <FormattedMessage id="form.button.cancel" />
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          className="btn btn-primary"
          data-qa="enterCheckEmail"
          onClick={handleSubmit}
          disabled={!canSubmit}
          block
        >
          <FormattedMessage id="form.control.continue" />
        </Button>
      </Col>
    </Row>
  );
};

export default Login;
