import {
  MouseEvent, ReactElement, useState, useEffect,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import Flags, { FlagComponent } from 'country-flag-icons/react/3x2';
import styles from './JobCard.module.scss';
import { useMobileDesign } from 'hooks/useMobileDesign';
import { AMBASSADOR, AMBASSADOR_JOBS, JOBS } from 'constants/pathNames';
import Salary from 'components/Job/Salary';
import { JobRecord } from 'types/airtable';

interface JobCardProps {
  job: {
    id: string;
    fields: JobRecord;
  }
  featured?: boolean;
}

const JobCard = ({ job: { id, fields }, featured = false }: JobCardProps): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useMobileDesign();
  const [colCount, setColCount] = useState<number>(3);

  const renderDetail = (data: any): ReactElement => (
    data && (
      <dl>
        <dd>{data}</dd>
      </dl>
    )
  );

  const renderSubDetail = (labelId: string, data: any): ReactElement => (
    (data || data >= 0) && (
      <p>
        <strong>
          <FormattedMessage id={labelId} />
        </strong>
        :
        {' '}
        {data}
      </p>
    )
  );

  const getCountryFlag = (label: string): FlagComponent | null => {
    switch (label) {
      case 'USA':
        return Flags.US;
      case 'Canada':
        return Flags.CA;
      case 'Lithuania':
        return Flags.LT;
      case 'Israel':
        return Flags.IL;
      default:
        return null;
    }
  };

  const renderCountryName = (labelId: string, countryName: string): ReactElement | null => {
    if (!countryName) return null;
    const Flag = getCountryFlag(countryName);

    return (
      <p>
        <strong>
          <FormattedMessage id={labelId} />
        </strong>
        :
        {' '}
        {Flag && <Flag className={styles.countryCode} />}
        {countryName}
      </p>
    );
  };

  const handleDetailsLink = (e: MouseEvent, newTab?: boolean): void => {
    e.preventDefault();
    if (pathname === AMBASSADOR) {
      return navigate(`${AMBASSADOR_JOBS}/${id}`, { state: { featured } });
    }

    if (pathname === AMBASSADOR_JOBS) {
      return navigate(`${AMBASSADOR_JOBS}/${id}`, { state: { featured } });
    }

    if (newTab) { // @ts-ignore
      return window.open(`${JOBS}/${id}`, '_blank');
    }

    return navigate(`${JOBS}/${id}`, { state: { featured } });
  };

  useEffect(() => {
    if (isMobile) {
      setColCount(1);
    } else {
      setColCount(3);
    }
  }, [isMobile]);

  return (
    <Col span={24 / colCount} key={id} id={id} data-qa="jobCard">
      <div className={styles.JobsCardInfo}>
        <Row className={styles.bottomAlign}>
          <Col xs={12}>
            <div className={styles.JobsInfo}>
              {renderDetail(fields?.['Posted Date'])}
            </div>
          </Col>
          {/* Salary Info */}
          <Col xs={12}>
            <div className={styles.SalaryHolder}>
              <Salary
                jobInfo={fields?.['Total Payment (Salary)']}
                styleName="JobsPrice"
                dataQa={{
                  salary: 'jobCardSalary',
                  period: 'jobCardSalaryPeriod',
                  bonusAmount: 'jobCardBonusAmount',
                  bonusPeriod: 'jobCardBonusPeriod',
                }}
              />
            </div>
          </Col>
        </Row>

        {/* Job Title */}
        <h3 className={styles.JobsCardInfoTitle} data-qa="jobCardTitle">
          {fields?.['Job Title']}
        </h3>

        {/* Job Details */}
        <Row>
          <div className={styles.JobsInfoDesc} data-qa="jobCardDescription">
            {renderSubDetail('job.monthlyHourly', fields?.['Monthly/Hourly'])}
            {renderCountryName('job.location', fields?.['Location (Country)'])}
            {renderSubDetail('job.industry', fields?.Industry?.join(', '))}
            <p />
          </div>
        </Row>
        <Row
          className="text-right-lg"
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            type="link"
            className="link-btn"
            href={undefined}
            data-qa="jobDetailsButton"
            onClick={(e) => {
              handleDetailsLink(e);
              sessionStorage.setItem('scrollPosition', window.scrollY.toString());
            }}
            onContextMenu={(e) => {
              handleDetailsLink(e, true);
              sessionStorage.setItem('scrollPosition', window.scrollY.toString());
            }}
          >
            <FormattedMessage id="job.details" />
          </Button>
        </Row>
      </div>
    </Col>
  );
};

export default JobCard;
