export enum Gender {
    Male = 'MALE',
    Female = 'FEMALE',
    Other = 'OTHER',
}

export enum ApplicationStatus {
    Accepted = 'ACCEPTED',
    AcceptedEurope = 'ACCEPTED_EUROPE',
    AcceptedGlobal = 'ACCEPTED_GLOBAL',
    AcceptedLocal = 'ACCEPTED_LOCAL',
    All = 'ALL',
    Completed = 'COMPLETED',
    Declined = 'DECLINED',
    Incomplete = 'INCOMPLETE',
    KYCRejected = 'KYC_REJECTED',
    New = 'NEW',
    None = 'NONE',
    NotVerified = 'NOT_VERIFIED',
    Pending = 'PENDING',
    ReadyForKYC = 'READY_FOR_KYC',
    Rejected = 'REJECTED',
    Required = 'REQUIRED',
    Verified = 'VERIFIED',
    Unavailable = 'UNAVAILABLE',
    Unknown = 'UNKNOWN',
}

// TODO: seems this values match with ApplicationStatus (except missing NEW, UNAVAILABLE statuses).
// Need to check can we use ApplicationStatus instead.
export enum UserIdentityConfigState {
    Accepted = 'ACCEPTED',
    AcceptedEurope = 'ACCEPTED_EUROPE',
    AcceptedGlobal = 'ACCEPTED_GLOBAL',
    AcceptedLocal = 'ACCEPTED_LOCAL',
    All = 'All',
    Completed = 'COMPLETED',
    Declined = 'DECLINED',
    Incomplete = 'INCOMPLETE',
    None = 'NONE',
    NotVerified = 'NOT_VERIFIED',
    ReadyForKYC = 'READY_FOR_KYC',
    Required = 'REQUIRED',
    Rejected = 'REJECTED',
    Verified = 'VERIFIED',
    Pending = 'PENDING',
    Unknown = 'UNKNOWN',
}

// TODO: remove duplicate object
export enum UserReputation {
    None = 'NONE',
    VIP = 'VIP',
    Pro = 'PRO',
    Custom = 'CUSTOM',
    Verified = 'VERIFIED',
}

export enum UserRole {
    Admin = 'ADMIN',
    Manager = 'MANAGER',
    User = 'USER',
    CampaignAdmin = 'CAMPAIGN_ADMIN',
}

export enum UserStatus {
    Active = 'ACTIVE',
    BlackListed = 'BLACKLISTED',
    Inactive = 'INACTIVE',
    Suspended = 'SUSPENDED',
    Unknown = 'UNKNOWN',
    Test = 'TEST',
}

export enum AuthProvider {
    Email = 'EMAIL',
    Google = 'GOOGLE',
    Facebook = 'FACEBOOK',
    Apple = 'APPLE',
}

export enum Benefit {
    None = 'NONE',
    Housing = 'HOUSING',
    Travel = 'TRAVEL',
    HealthInsurance = 'HEALTH_INSURANCE',
    Meals = 'MEALS',
    Unknown = 'UNKNOWN',
}

export enum BadgeTypes {
    None = 'NONE',
    Official = 'OFFICIAL',
    RequestOfficialResponse = 'REQUEST_OFFICIAL_RESPONSE',
}

export enum BountyType {
    Album = 'ALBUM',
    Autosuggestion = 'AUTOSUGGESTION',
    Badge = 'BADGE',
    BadgeCollection = 'BADGE_COLLECTION',
    Banner = 'BANNER',
    Bundle = 'BUNDLE',
    Checklist = 'CHECKLIST',
    Classified = 'CLASSIFIED',
    Funding = 'FUNDING',
    Job = 'JOB',
    LiveStream = 'LIVE_STREAM',
    Lottery = 'LOTTERY',
    Match = 'MATCH',
    MCQ = 'MCQ',
    News = 'NEWS',
    None = 'NONE',
    Order = 'ORDER',
    PrivateListing = 'PRIVATE_LISTING',
    Product = 'PRODUCT',
    Promotion = 'PROMOTION',
    Quest = 'QUEST',
    Question = 'QUESTION',
    RealEstateCustomer = 'REALESTATE_CUSTOMER',
    RealEstateRecommendation = 'REALESTATE_RECOMMENDATION',
    Score = 'SCORE',
    Survey = 'SURVEY',
    TalentRecommendation = 'TALENT_RECOMMENDATION',
    TalentSearch = 'TALENT_SEARCH',
    Thread = 'THREAD',
    TMOB_Challenge = 'TMOB_CHALLENGE',
    TMOB_Coaching = 'TMOB_COACHING',
    Trivia = 'TRIVIA',
    Deal = 'DEAL',
    Unknown = 'UNKNOWN',
    Post = 'POST',
}

export enum BountyState {
    Accepted = 'ACCEPTED',
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    Closed = 'CLOSED',
    Declined = 'DECLINED',
    Deleted = 'DELETED',
    Draft = 'DRAFT',
    Expired = 'EXPIRED',
    New = 'NEW',
    None = 'NONE',
    Paused = 'PAUSED',
    Pending = 'PENDING',
    Refused = 'REFUSED',
    Rejected = 'REJECTED',
    Retracted = 'RETRACTED',
    Sealed = 'SEALED',
    Unknown = 'UNKNOWN',
}

export enum PayPeriodUnit {
    CubicFoot = 'CUBIC_FOOT',
    CubicMeter = 'CUBIC_METER',
    Day = 'DAY',
    Hour = 'HOUR',
    Job = 'JOB',
    Kilogram = 'KILOGRAM',
    LinearFoot = 'LINEAR_FOOT',
    LinearMeter = 'LINEAR_METER',
    Month = 'MONTH',
    NonePeriod = 'NONE_PERIOD',
    Pound = 'POUND',
    SquareFoot = 'SQ_FOOT',
    SquareMeter = 'SQ_METER',
    UnknownPeriod = 'UNKNOWN_PERIOD',
    Week = 'WEEK',
    Year = 'YEAR',
}

export enum BonusType {
    Loyalty = 'LOYALTY',
    Project = 'PROJECT',
    Other = 'OTHER',
}

export enum SearchMode {
    Default = '',
    All = 'ALL',
}

export enum CompanyType {
    Ambassador = 'AMBASSADOR',
    Applicant = 'APPLICANT',
    Author = 'AUTHOR',
    Charity = 'CHARITY',
    Employer = 'EMPLOYER',
    Family = 'FAMILY',
    Merchant = 'MERCHANT',
    Corporate = 'CORPORATE',
    None = 'NONE',
    Partner = 'PARTNER',
    Unknown = 'UNKNOWN',
    Special = 'SPECIAL',
    Vulgus = 'VULGUS',
}

export enum WorkExperienceDuration {
    UpToOne = 'UP_TO_ONE',
    OneToThree = 'ONE_TO_THREE',
    ThreeToFive = 'THREE_TO_FIVE',
    OverFive = 'OVER_FIVE',
    OverTen = 'OVER_TEN',
    None = 'NONE',
    Unknown = 'UNKNOWN',
}

export enum WorkExperienceLevel {
    None = 'NONE',
    Low = 'LOW',
    Medium = 'MEDIUM',
    High = 'HIGH',
    Unknown = 'UNKNOWN'
}

export enum VaccinationStatus {
    Booster = 'BOOSTER',
    OneDose = 'ONE_DOSE',
    TwoDose = 'TWO_DOSE'
}

export enum VaccinationVendor {
    Johnson = 'JOHNSON',
    Moderna = 'MODERNA',
    PfizerBiontech = 'PFIZER_BIONTECH',
    Other = 'OTHER',
    Unknown = 'UNKNOWN'
}

export enum UserSocialNetwork {
    Viber = 'VIBER',
    WhatsApp = 'WHATSAPP',
    Telegram = 'TELEGRAM',
}

export enum EducationDegree {
    AssociateDegree = 'ASSOCIATE_DEGREE',
    CollegeDegree = 'COLLEGE_DEGREE',
    BachelorsDegree = 'BACHELORS_DEGREE',
    VocationalSchool = 'VOCATIONAL_SCHOOL',
    MiddleSecondary = 'MIDDLE_SECONDARY',
    Higher = 'HIGHER',
    None = 'NONE',
    Unknown = 'UNKNOWN',
}

export enum UserSocial {
    Follow = 'FOLLOW',
    Endorse = 'ENDORSE',
}

export enum UserKind {
    Company = 'COMPANY',
    Group = 'GROUP',
    Unknown = 'UNKNOWN',
    User = 'USER',
}

export enum UserType {
    Anon = 'ANON',
    Applicants = 'APPLICANTS',
    Company = 'COMPANY',
    Employee = 'EMPLOYEE',
    Employees = 'EMPLOYEES',
    None = 'NONE',
    Individual = 'INDIVIDUAL',
    Influencers = 'INFLUENCERS',
    Group = 'GROUP',
    GroupsRoot = 'GROUPS_ROOT',
    Unknown = 'UNKNOWN',
}

export enum StreamListType {
    None = 'NONE',
    Stream = 'STREAM',
    EdgeCoachings = 'EDGE_COACHINGS',
    EdgeInFocus = 'EDGE_IN_FOCUS',
    EdgeChallenges = 'EDGE_CHALLENGES',
    BuySell = 'BUY_SELL',
    Market = 'MARKET',
    Academia = 'ACADEMIA',
    Custom = 'CUSTOM',
    WebView = 'WEBVIEW',
    Favorites = 'FAVORITES',
    Autosuggestions = 'AUTOSUGGESTIONS',
    Jobs = 'JOBS',
    Applications = 'APPLICATIONS',
    MatchDetails = 'MATCH_DETAILS',
}

export enum StreamCodes {
    Regular = 'GG',
    Custom = 'HH',
    InFocus = 'TT',
    Stream = 'STREAM',
}

export enum StreamListKind {
    Inbox = 'INBOX',
    Refs = 'REFS',
    Bucket = 'BUCKET',
    FilterByBountyType = 'FILTER_BY_BOUNTY_TYPE',
    RestCall = 'REST_CALL',
    Special = 'SPECIAL',
    WebView = 'WEBVIEW',
    Multi = 'MULTI',
    Stats = 'STATS',
    Unknown = 'UNKNOWN',
    Target = 'TARGET',
}

export enum ResponseType {
    None = 'NONE',
    Recommendation = 'RECOMMENDATION',
    Note = 'NOTE',
    Choice = 'CHOICE',
    Order = 'ORDER',
    TimelineEvent = 'TIMELINE_EVENT',
}

export enum BountyResponseState {
    None = 'NONE',
    Draft = 'DRAFT',
    Pending = 'PENDING',
    Active = 'ACTIVE',
    Acked = 'ACKED',
    Progressing = 'PROGRESSING',
    Accepted = 'ACCEPTED',
    Refused = 'REFUSED',
    Paying = 'PAYING',
    Paid = 'PAID',
    Retracted = 'RETRACTED',
    Rejected = 'REJECTED',
    Deleted = 'DELETED',
    New = 'NEW',
    Declined = 'DECLINED',
    Unavailable = 'UNAVAILABLE',
}

export enum VaccinationDisease {
    Covid19 = 'COVID-19',
}

export enum SummaryProgressState {
    None = 'NONE',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    Unknown = 'UNKNOWN',
}

export enum RebountyType {
    None = 'NONE',
    Distribution = 'DISTRIBUTION',
    Intermediation = 'INTERMEDIATION',
    Unknown = 'UNKNOWN',
}

export enum BountyDrawerType {
    Bounty = 'BOUNTY',
    Promotion = 'PROMOTION',
    Banner = 'BANNER',
    Product = 'PRODUCT',
    Reply = 'REPLY',
    Comment = 'COMMENT',
}

export enum OrderSectionType {
    InApp = 'IN_APP',
    OnDelivery = 'ON_DELIVERY',
    PrivateUserPayment = 'PRIVATE_USER_PAYMENT',
    InAppResellerPayment = 'IN_APP_RESELLER_PAYMENT',
    OnDeliveryResellerPayment = 'ON_DELIVERY_RESELLER_PAYMENT',
}

export enum Currency {
    None = 'NONE',
    Unknown = 'UNKNOWN',
    ALL = 'ALL',
    USD = 'USD',
    CAD = 'CAD',
    EUR = 'EUR',
    RON = 'RON',
    MDL = 'MDL',
    GBP = 'GBP',
    PLN = 'PLN',
    GEL = 'GEL',
    ARS = 'ARS',
    AED = 'AED',
}

export enum ApplicationJourneyPhaseStatus {
    Pending = 'PENDING',
    InProgress = 'IN_PROGRESS',
    OnHold = 'ON_HOLD',
    Completed = 'COMPLETED',
    Canceled = 'CANCELED',
}

export enum ApplicationJourneyPhase {
    ApplicationAccepted = 'APPLICATION_ACCEPTED',
    AdditionalDocumentsSupplied = 'ADDITIONAL_DOCUMENTS_SUPPLIED',
    EmploymentContractExecuted = 'EMPLOYMENT_CONTRACT_EXECUTED',
    PermitApplicationSubmitted = 'PERMIT_APPLICATION_SUBMITTED',
    WorkPermitReceived = 'WORK_PERMIT_RECEIVED',
    VisaApplicationPackageCompiled = 'VISA_APPLICATION_PACKAGE_COMPILED',
    VisaApplicationSubmitted = 'VISA_APPLICATION_SUBMITTED',
    VisaReceived = 'VISA_RECEIVED',
    TravelArrangements = 'TRAVEL_ARRANGEMENTS',
    EnRouteToLocation = 'EN_ROUTE_TO_LOCATION',
    ArrivedAtLocation = 'ARRIVED_AT_LOCATION',
    Situated = 'SITUATED',
    Working = 'WORKING',
    EmploymentConcluded = 'EMPLOYMENT_CONCLUDED'
}

export enum AttachmentDisplayStatus {
    Inline = 'INLINE',
    Attach = 'ATTACH',
}

export enum AttachmentVisibility {
    All = 'ALL',
    Participants = 'PARTICIPANTS',
    Creator = 'CREATOR',
    None = 'NONE',
}

export enum AttachmentType {
    Image = 'IMAGE',
    Video = 'VIDEO',
    Audio = 'AUDIO',
    PDF = 'PDF',
    File = 'FILE',
    YouTube = 'YOUTUBE',
    Website = 'WEBSITE',
    Other = 'OTHER',
    GIF = 'GIF',
}

export enum AttachmentMeaning {
    Product = 'PRODUCT',
    Promotion = 'PROMOTION',
    Resume = 'RESUME',
    Contract = 'CONTRACT',
    Covid19 = 'COVID19_VAXX',
    WorkPermit = 'WORK_PERMIT',
    Other = 'OTHER',
    OtherDoc = 'OTHER_DOC',
    CriminalBgCheck = 'CRIMINAL_BKGND_CHECK',
    ProofOfSkills = 'PROOF_OF_SKILLS',
}

export enum AttachmentStatus {
    Active = 'ACTIVE',
    Deleted = 'DELETED',
}

export enum SentTypes {
    MyResponses = 'MY_RESPONSES',
    MyDrafts = 'MY_DRAFTS',
    Posted = 'POSTED',
}

export enum CommentType {
    Bounty = 'BOUNTY',
    Response = 'RESPONSE',
    User = 'USER',
}

export enum IdentityType {
    Anon = 'ANON',
    Real = 'REAL',
    Default = 'DEFAULT',
}

export enum CtrlTypes {
    Shown = 'A',
}

export enum BountySocialNetworks {
    InApp = 'IN_APP',
    Rebounty = 'REBOUNTY',
}

export enum AccessMode {
    PIN = 'PIN',
}

export enum MatchStatus {
    None = 'NONE',
    NotStarted = 'NOT_STARTED',
    FirstHalf = 'FIRST_HALF',
    HalfTimeBreak = 'HALF_TIME_BREAK',
    SecondHalf = 'SECOND_HALF',
    ExtraTime = 'EXTRA_TIME',
    Penalty = 'PENALTY',
    Finished = 'FINISHED',
    Cancelled = 'CANCELLED',
    TBD = 'TBD',
    Terminated = 'TERMINATED',
    Interrupted = 'INTERRUPTED',
    Postponed = 'POSTPONED',
    Unknown = 'UNKNOWN',
}

export enum ProgressState {
    None = 'NONE',
    Completed = 'COMPLETED',
    Unknown = 'UNKNOWN'
}

export enum PermissionType {
    Hide = 'HIDE',
    ReadOnly = 'READ_ONLY',
    WriteOnly = 'WRITE_ONLY',
    ReadWrite = 'READ_WRITE',
    Unknown = 'UNKNOWN',
}

export enum BountyUserState {
    None = 'NONE',
    Unknown = 'Unknown',
    InActive = 'IN_ACTIVE',
    InWIP = 'IN_WIP',
    InRebountied = 'IN_REBOUNTIED',
    InPaused = 'IN_PAUSED',
    InClosed = 'IN_CLOSED',
    InReceived = 'IN_RECEIVED',
    InTalentPending = 'IN_TALENT_PENDING',
    InTalentRejected = 'IN_TALENT_REJECTED',
    InTalentInterested = 'IN_TALENT_INTERESTED',
    InExpired = 'IN_EXPIRED',
    OutDraft = 'OUT_DRAFT',
    OutActive = 'OUT_ACTIVE',
    OutPosted = 'OUT_POSTED',
    OutPaused = 'OUT_PAUSED',
    OutClosed = 'OUT_CLOSED',
    OutRejected = 'OUT_REJECTED',
    OutRetracted = 'OUT_RETRACTED',
    OutArchived = 'OUT_ARCHIVED',
    OutExpired = 'OUT_EXPIRED',
}

export enum CompanySentsFolder {
    Autosuggestion = 'AUTOSUGGESTION_FOLDER',
    Listings = 'listings',
    Products = 'products',
    Orders = 'orders',
    BadgeCollections = 'badgeCollections',
    Badges = 'badges',
    Promotions = 'promotions',
    Jobs = 'jobs',
    News = 'news',
    Banners = 'banners',
    Matches = 'matches',
}

export enum DismissReasons {
    TooSmall = 'TOO_SMALL',
    DontKnow = 'DONT_KNOW',
    NoInterest = 'NO_INTEREST',
}

export enum BountyActions {
    None = 'NONE',
    Edit = 'EDIT',
    Activate = 'ACTIVATE',
    Share = 'SHARE',
    Post = 'POST',
    PostRebounty = 'POST_REBOUNTY',
    Pause = 'PAUSE',
    Resume = 'RESUME',
    Close = 'CLOSE',
    Pay = 'PAY',
    Reply = 'REPLY',
    ReplyAsComment = 'REPLY_AS_COMMENT',
    Message = 'MESSAGE',
    Onit = 'ONIT',
    Delete = 'DELETE',
    Interested = 'INTERESTED',
    NotInterested = 'NOT_INTERESTED',
    Comment = 'COMMENT',
    GainInsights = 'GAIN_INSIGHTS',
    AddPosts = 'ADD_POSTS',
    Reorder = 'REORDER',
    SendResume = 'SEND_RESUME',
    Rate = 'RATE',
    Respond = 'RESPOND',
    Rebounty = 'REBOUNTY',
    Recommend = 'RECOMMEND',
    Like = 'LIKE',
    Bookmark = 'BOOKMARK',
    Chat = 'CHAT',
    Open = 'OPEN',
    RegisterToWin = 'REGISTER_TO_WIN',
    Dismiss = 'DISMISS',
    Report = 'REPORT',
    Duplicate = 'DUPLICATE',
    Deactivate = 'DEACTIVATE',
    Retract = 'RETRACT',
    SendPayment = 'SEND_PAYMENT',
    RemindLater = 'REMIND_LATER',
    Simulate = 'SIMULATE',
    RequestOfficialResponse = 'REQUEST_OFFICIAL_RESPONSE',
    Reject = 'REJECT',
    PinToTop = 'PIN_TO_TOP',
    UnpinFromTop = 'UNPIN_FROM_TOP',
    ChangeRatingValue = 'CHANGE_RATING_VALUE',
    MoveToList = 'MOVE_TO_LIST',
    MarkOfficial = 'MARK_OFFICIAL',
    UnmarkOfficial = 'UNMARK_OFFICIAL',
    CancelROR = 'CancelROR',
    AddQuestion = 'ADD_QUESTION',
    AddSuccessMessage = 'ADD_SUCCESS_MESSAGE',
}

export enum ResponseActions {
    None = 'NONE',
    Publish = 'PUBLISH',
    Edit = 'EDIT',
    Rate = 'RATE',
    ChangeOffer = 'CHANGE_OFFER',
    Comment = 'COMMENT',
    Demote = 'DEMOTE',
    Delete = 'DELETE',
    Retract = 'RETRACT',
    ACK = 'ACK',
    Progress = 'PROGRESS',
    Accept = 'ACCEPT',
    Refuse = 'REFUSE',
    Message = 'MESSAGE',
    Report = 'REPORT',
    ChangeRatingValue = 'CHANGE_RATING_VALUE',
    PinToTop = 'PIN_TO_TOP',
    UnpinToTop = 'UNPIN_FROM_TOP',
    Reject = 'REJECT',
    Pay = 'PAY',
    Bookmark = 'BOOKMARK',
    MarkOfficial = 'MARK_OFFICIAL',
    UnmarkOfficial = 'UNMARK_OFFICIAL',
    Like = 'LIKE',
    MarkAsHighlight = 'MARK_AS_HIGHLIGHT',
    UnmarkAsHighlight = 'UNMARK_HIGHLIGHT',
    MessageApplicant = 'MESSAGE_APPLICANT',
    MessageResponder = 'MESSAGE_RESPONDER',
}

export enum ContentType {
    Stream = 'STREAM',
    Posts = 'POSTS',
}

export enum ShowInType {
    Home = 'HOME',
    Explicit = 'EXPLICIT',
    Unknown = 'UNKNOWN',
}

export enum AuthorPref {
    Auto = 'AUTO',
    External = 'EXTERNAL',
    Creator = 'CREATOR',
    Owner = 'OWNER',
}

export enum ProductKind {
    Product = 'PRODUCT',
    GiftCard = 'GIFTCARD',
    Food = 'FOOD',
    Ticket = 'TICKET',
    Unknown = 'UNKNOWN',
}

export enum VisibilityModeType {
    Default = 'DEFAULT',
    Visible = 'VISIBLE',
    Private = 'PRIVATE',
}

export enum KYCLevel {
    Europe = 'EUROPE',
    International = 'INTERNATIONAL',
    Local = 'LOCAL',
}

export enum PlatformLabels {
    Android = 'ANDROID',
    IOS = 'IOS',
    Console = 'WEB',
    JoinWeb = 'JOIN_WEB',
    Server = 'SERVER',
    Unknown = 'UNKNOWN',
}

export enum SalaryType {
    Budget = 'ON_BUDGET_PAY',
    Gross = 'ON_GROSS_PAY',
    Net = 'ON_NET_PAY',
}

export enum ResponseUserState {
    OutDraft = 'OUT_DRAFT',
    OutSubmitted = 'OUT_SUBMITTED',
    OutPending = 'OUT_PENDING',
    OutAcked = 'OUT_ACKED',
    OutAccepted = 'OUT_ACCEPTED',
    OutPaid = 'OUT_PAID',
    OutRefused = 'OUT_REFUSED',
    OutRejected = 'OUT_REJECTED',
    OutRetracted = 'OUT_RETRACTED',
    InSubmitted = 'IN_SUBMITTED',
    InAcked = 'IN_ACKED',
    InAccepted = 'IN_ACCEPTED',
    InPaid = 'IN_PAID',
    InRefused = 'IN_REFUSED',
    Other = 'OTHER',
}

export enum AllowMode {
    Default = 'DEFAULT',
    Always = 'ALWAYS',
    Never = 'NEVER',
    May = 'MAY',
    Should = 'SHOULD',
}

export enum AvatarProperties {
    KeyColor = 'color',
    CodeColor = '#',
}

export enum ProductFlavours {
    Rebounty = 'rebounty',
    Backstage = 'youkno',
    Stash = 'stash',
}

export enum Interactions {
    Chat = 'chat',
    OfficialResponse = 'officialResponse',
    Rebounty = 'rebounty',
    Recommend = 'recommend',
    Seen = 'seen',
    Share = 'share',
}

export enum KeyCategory {
    Important = 'GN',
    Normal = 'NN',
    Unimporant = 'SN',
}

export enum SortBy {
    CreatorUpdatedAt = 'creatorUpdatedAtSortKey',
    TypeCreatedAt = 'typeCreatedAtSortKey',
    TypePopularity = 'typePopularitySortKey',
    TypeDiscussed = 'typeDiscussedSortKey',
    ListCreatedAt = 'listCreatedAtSortKey',
    ListPopularity = 'listPopularitySortKey',
    ListDiscussed = 'listDiscussedSortKey',
    StreamCreatedAt = 'streamCreatedAtSortKey',
    StreamPopularity = 'streamPopularitySortKey',
    StreamDiscussed = 'streamDiscussedSortKey',
    BountyCreated = 'bountyCreatedAtSortKey',
    BountyPopularity = 'bountyPopularitySortKey',
    BountyDiscussed = 'bountyDiscussedSortKey',
}

export enum SortType {
    Recent = 'RECENT',
    Popular = 'POPULAR',
    Discussed = 'DISCUSSED',
}

export enum DealType {
    None = 'NONE',
    Invite = 'INVITE',
    Promotion = 'PROMOTION',
    Unknown = 'UNKNOWN',
}

// TODO: change
export enum ScopedReportType {
    'Local wide' = 'report.scope.local',
    'Company wide' = 'report.scope.company',
    'Global wide' = 'report.scope.global',
}

export enum ReportMetaBoolValues {
    Scoped = 'scoped',
    UserFiltered = 'userFiltered',
}

export enum ReportFiltersField {
    FromDate = 'fromDate',
    ToDate = 'toDate',
}

export enum ChatAction {
    Dismiss = 'dismiss',
    Archive = 'archive',
    Resolve = 'resolve',
    Abandon = 'abandon',
}

export enum SignupSteps {
    NiceToMeet = 'NICE_TO_MEET_STEP',
    Welcome = 'WELCOME_STEP',
}

// TODO: use this enum
export enum ChatMessageType {
    Message = 'MSG',
    Status = 'STATUS',
    Info = 'INFO',
    Help = 'HELP',
}

// TODO: use this enum
export enum ChatRoomType {
    Private = 'PRIVATE',
    Group = 'GROUP',
    Recipients = 'RECIPIENTS',
    Participants = 'PARTICIPANTS',
    SurveyChoice = 'SURVEY_CHOICE',
    Support = 'SUPPORT',
    PSG = 'PSG',
    Unknown = 'UNKNOWN',
}

export enum ChatState {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    Dismissed = 'DISMISSED',
}

export enum DisplayMode {
    None = 'NONE',
    Default = 'DEFAULT',
    Highlighted = 'HIGHLIGHTED',
    Unknown = 'UNKNOWN',
}

export enum AttachmentSourceOptions {
    TakePhoto = 'TAKE_PHOTO',
    PhotoLibrary = 'PHOTO_LIBRARY',
    VideoLibrary = 'VIDEO_LIBRARY',
    AudioLibrary = 'AUDIO_LIBRARY',
    PDFFiles = 'PDF_FILES',
}

export enum RelocateState {
    Ready = 'READY',
    Likely = 'LIKELY',
    NotLikely = 'NOT_LIKELY',
    No = 'NO',
}

export enum SalaryExpectation {
    ProposalMatches = 'PROPOSAL_MATCHES',
    ProposalTooLow = 'PROPOSAL_TOO_LOW',
}

export enum BadgeStyles {
    Official = 'officialBadge',
    Ror = 'rorBadge',
}

export enum ClientColors {
    HeaderColor = '#ffffff',
    SubHeaderColor = '#EBE1D6',
    PrimaryColor = '#0061AF',
    SecondaryColor = '#878686',
}

export enum LanguageLevel {
    Native = 'NATIVE',
    Beginner = 'BEGINNER',
    Intermediate = 'INTERMEDIATE',
    Advanced = 'ADVANCED',
}

export enum EnvironmentMode {
    Default = 'DEFAULT',
    Test = 'TEST',
}

export enum AudienceType {
    Journal = 'JOURNAL',
    Public = 'PUBLIC',
    Unknown = 'UNKNOWN',
}

export enum JobProgram {
    CanadaButcher = 'CANADA_BUTCHER',
    CanadaDriver = 'CANADA_DRIVER',
    CanadaSales = 'CANADA_SALES',
    CanadaPharmacyAssistant = 'CANADA_PHARMACY_ASSISTANT',
    CanadaWarehouse = 'CANADA_WAREHOUSE',
}

export enum JobVariousLicenses {
    CEWithADR = 'CE_WITH_ADR',
}

export enum PhoneStatus {
    Verified = 'VERIFIED',
    Unverified = 'UNVERIFIED',
}

export enum UserIdentityPhotoType {
    Front = 'FRONT',
    Back = 'BACK',
}

export enum AddressType {
    Billing = 'BILLING',
    None = 'NONE',
    Shipping = 'SHIPPING',
    Tax = 'TAX',
    Unknown = 'UNKNOWN',
}

export enum AuditObjectType {
    Address = 'ADDRESS',
    Attachment = 'ATTACHMENT',
    Bounty = 'BOUNTY',
    Chat = 'CHAT',
    Comment = 'COMMENT',
    Event = 'EVENT',
    None = 'NONE',
    PushNotification = 'PUSH_NOTIFICATION',
    Response = 'RESPONSE',
    Seat = 'SEAT',
    Ticket = 'TICKET',
    Venue = 'VENUE',
    User = 'USER',
    Unknown = 'UNKNOWN'
}

export enum AuditVerb {
    AssignCompany = 'ASSIGN_COMPANY',
    AttachmentAdded = 'ATTACHMENT_ADDED',
    AttachmentDeleted = 'ATTACHMENT_DELETED',
    BlacklistedChanged = 'BLACKLISTED_CHANGED',
    InviteToJobReceived = 'INVITE_TO_JOB_RECEIVED',
    InviteToJobSent = 'INVITE_TO_JOB_SENT',
    KYCChanged = 'KYC_CHANGED',
    KYCChangedByPassport = 'KYC_CHANGED_BY_PASSPORT',
    JobApplicationStatusChanged = 'JOB_APPLICATION_STATUS_CHANGED',
    JourneyPhaseUpdated = 'JOURNEY_PHASE_UPDATED',
    PinChanged = 'PIN_CHANGED',
    ReferralCodeChanged = 'REFERRAL_CODE_CHANGED',
    UserAppliedForAJob = 'USER_APPLIED_FOR_A_JOB',
    UserCreated = 'USER_CREATED',
    UserSignedIn = 'USER_SIGNED_IN',
    UserUpdated = 'USER_UPDATED',
}

export enum KYCRejectType {
    Blacklisted = 'BLACKLISTED',
    DoNotMatchUserData = 'DO_NOT_MATCH_USER_DATA',
    Expired = 'EXPIRED',
    Falsified = 'FALSIFIED',
    Fraud = 'FRAUD',
    Incomplete = 'INCOMPLETE',
    None = 'NONE',
    NotAccepted = 'NOT_ACCEPTED',
    Other = 'OTHER',
    Underage = 'UNDERAGE',
    Unknown = 'UNKNOWN',
    Unverifiable = 'UNVERIFIABLE',
}

export enum KYCUserStatus {
    Rejected = 'REJECTED',
    Verified = 'VERIFIED',
}

export enum VoucherType {
    None = 'NONE',
    Intrinsic = 'INTRINSIC',
    Unknown = 'UNKNOWN',
}

export enum RegionLevel {
    Europe = 'EUROPE',
    Local = 'LOCAL',
    Global = 'GLOBAL',
}

export enum PointCurrency {
    Invite = 'INVITE',
    Like = 'LIKE',
    Min = 'MIN',
    None = 'NONE',
    Point = 'POINT',
    Rating = 'RATING',
    Repu = 'REPU',
    Star = 'STAR',
    Star2 = 'STAR2',
    Star3 = 'STAR3',
    Unknown = 'UNKNOWN',
}

export enum IdentityModeType {
    ForcedAnon = 'FORCED_ANON',
    ForcedReal = 'FORCED_REAL',
    Anon = 'ANON',
    Real = 'REAL',
}

export enum ShopActionsType {
    Resync = 'RESYNC',
    CreateBanners = 'CREATE_BANNERS',
}

export enum DeliveryType {
    Standard = 'STANDARD',
    Driver = 'DRIVER',
    Unknown = 'UNKNOWN',
}

export enum DocumentType {
    BankStatement = 'BANK_STATEMENT',
    BirthCertificate = 'BIRTH_CERTIFICATE',
    Covid19Vax = 'COVID19_VAXX',
    CriminalBgCheck = 'CRIMINAL_BKGND_CHECK',
    DrivingLicence = 'DRIVING_LICENCE',
    GovernmentLetter = 'GOVERNMENT_LETTER',
    IdentityCard = 'IDENTITY_CARD',
    NationalId = 'NATIONAL_ID',
    Other = 'OTHER',
    OtherDoc = 'OTHER_DOC',
    Passport = 'PASSPORT',
    ProofOfResidency = 'PROOF_OF_RESIDENCY',
    Product = 'PRODUCT',
    Promotion = 'PROMOTION',
    Resume = 'RESUME',
    SecondId = 'SECOND_ID',
    StateId = 'STATE_ID',
    Unknown = 'UNKNOWN',
    UtilityBill = 'UTILITY_BILL',
    WorkPermit = 'WORK_PERMIT',
}

export enum ClientEnvType {
    Production = 'PROD',
    Sandbox = 'SANDBOX',
}

export enum BannerType {
    None = 'NONE',
    Hero = 'HERO',
    Unknown = 'UNKNOWN',
}

export enum AttachmentAction {
    OpenUrl = 'open_url',
    OpenProduct = 'open_product',
}

export enum TargetApp {
    Shop = 'shop',
}

export enum UserAuthStatus {
    Logged = 'logged',
    NotLogged = 'notLogged',
    Both = 'both',
}

// TODO: move to /types
export type IdentityMode = Record<IdentityModeType, { forced: true; identityType: IdentityType; }>

export enum PromotionType {
    None = 'NONE',
    Discount = 'DISCOUNT',
    Free = 'FREE',
    Unknown = 'UNKNOWN',
}

export enum ProductType {
    Product = 'PRODUCT',
    Coupon = 'COUPON',
}

export enum AreaType {
    None = 'NONE',
    Group = 'GROUP',
    SurveyChoice = 'SURVEY_CHOICE',
    SsoManager = 'SSO_MANAGER',
    Geo = 'GEO',
    FamiliesContent = 'FAMILIES_CONTENT',
    Unknown = 'UNKNOWN',
}

export enum SyncComponentType {
    All = 'all',
    Categories = 'categories',
    Products = 'products',
    Locations = 'locations',
}

export enum UserVerification {
    Pending = 'pending',
    Verified = 'verified',
    Rejected = 'rejected',
}

export enum ConfirmationModalType {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
}
export enum InventoryPolicy {
    Deny = 'DENY',
    Continue = 'CONTINUE',
}

export enum WeightUnit {
    G = 'G',
    KG = 'KG',
    LB = 'LB',
    OZ = 'OZ',
}
export enum PaymentMethod {
    Cash = 'CASH',
    Debit = 'DEBIT',
    BillMyBank = 'BILLMYBANK',
}

export enum DeliveryMethod {
    Pickup = 'PICKUP',
    Delivery = 'DELIVERY',
    // Retail = 'RETAIL',
}

export enum OrderStatus {
    None = 'NONE',
    New = 'NEW',
    Proposed = 'PROPOSED',
    Payed = 'PAYED',
    Pending = 'PENDING',
    Processing = 'PROCESSING',
    ReadyForPickup = 'READY_FOR_PICKUP',
    Shipped = 'SHIPPED',
    MerchReceived = 'MERCH_RECEIVED',
    Settled = 'SETTLED',
    Chargeback = 'CHARGEBACK',
    Disputed = 'DISPUTED',
    PaymentFailed = 'PAYMENT_FAILED',
    Negotiation = 'NEGOTIATION',
    Declined = 'DECLINED',
    Unknown = 'UNKNOWN',
}

export enum ShortcutsLabels {
    Bold = 'bold',
    Unordered = 'unordered',
    Ordered = 'ordered',
}

export enum AgeFilterType {
    Age18 = '18',
    Age21 = '21',
    None = 'none',
}

export enum AnonUsersType {
    AuthAtStart = 'AUTH_AT_START',
    AuthAtOrder = 'AUTH_AT_ORDER',
    AllowPurchase = 'ALLOW_PURCHASE',
}

export enum MedicalIdPromptType {
    AtStart = 'AT_START',
    AtOrder = 'AT_ORDER',
}

export enum CustomerBalanceType {
    InCart = 'IN_CART',
    None = 'NONE',
}

export enum TagVerbs {
    TagAdded = 'TAG_ADDED',
    TagDeleted = 'TAG_DELETED',
}

export enum BoolAnswerClassNames {
    UserStatus = 'userStatus',
    UserWorkStatus = 'userWorkStatus',
}

export enum NegativeAnswerLabels {
    Yes = 'label.yes',
    No = 'label.no',
    Dash = '-',
}

export enum VisaStatus {
    PreparingDocuments = 'PREPARING_DOCUMENTS',
    BiometricsDelivery = 'BIOMETRICS_DELIVERY',
    VisaProcessing = 'VISA_PROCESSING',
    HaveVisa = 'HAVE_VISA',
    DidNotApply = 'DID_NOT_APPLY',
    Unknown = 'UNKNOWN',
    None = 'NONE',
}

export enum ShopScheduleType {
    ShopHoursOfOperation = 'ShopHoursOfOperation',
    ShopSpecialHoursOfOperation = 'ShopSpecialHoursOfOperation',
}

export enum HoursOfOperationType {
    Common = 'common',
    Pickup = 'pickup',
    Delivery = 'delivery',
}

export enum OperationStatus {
    Open = 'OPEN',
    Closed = 'CLOSED',
    AsMon = 'AS_MON',
    Common = 'COMMON',
    Inherit = 'INHERIT',
    Default = 'DEFAULT'
}

export enum RestrictedOperationStatus {
    Open = 'OPEN',
    Closed = 'CLOSED',
}

// TODO: Refactor enums
export enum DayOfWeekType {
    Monday = 'mon',
    Tuesday = 'tue',
    Wednesday = 'wed',
    Thursday= 'thu',
    Friday = 'fri',
    Saturday = 'sat',
    Sunday = 'sun',
}

export enum PDFTitles {
    ApprovalActivityHistory = 'APPROVAL ACTIVITY HISTORY',
    CompanyInformation = 'COMPANY INFORMATION',
    JobRequirementsDescription = 'JOB REQUIREMENTS & DESCRIPTION',
    CompensationBenefits = 'COMPENSATION & BENEFITS',
    CulturalFit = 'CULTURAL FIT & SOFT SKILLS',
    RecruitmentProcess = 'RECRUITMENT PROCESS',
    FeeSchedule = 'FEE SCHEDULE',
}

export enum AuthSteps {
    CheckEmail = 'CHECK EMAIL',
    Login = 'LOGIN',
    Registration = 'REGISTRATION',
    RegistrationOldUsers = 'REGISTRATION OLD USERS',
    ForgotPassword = 'FORGOT PASSWORD',
}

export enum AuthMessages {
    IncorrectPassword = 'Bad credentials',
}
