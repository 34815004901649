import { ReactElement, ReactNode, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Checkbox,
  FormInstance,
  Row,
  Col,
  Radio,
  DatePicker,
} from 'antd';
import type { UploadFile } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import cs from 'classnames';

import { ReactComponent as RotateIcon } from 'assets/icons/rotate-left.svg';
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation-mark-circle.svg';
import { convertArraySorted } from 'utils/convertArray';
import {
  decisionArray,
  shiftSchedule,
} from 'data/containers/formData';
import UploadResume from 'components/UploadResume';
import QuestionnaireProgressBar from 'components/QuestionnaireProgressBar';

import styles from './QuestionnaireForm.module.scss';

interface QuestionnaireFormProps {
  form: FormInstance;
  disable: boolean;
  handleSubmit: () => void;
  jobData: any;
  onChangeResume: (file: UploadFile | null) => void;
  handleSendLinkToEmail: () => void;
}

const QuestionnaireForm = ({
  form, disable, handleSubmit,
  jobData, onChangeResume, handleSendLinkToEmail,
}: QuestionnaireFormProps): ReactElement => {
  const intl = useIntl();
  const dateFormat = 'M/D/YYYY';
  const disabledDate = (current: Dayjs): boolean => current && current < dayjs().endOf('day');
  const fieldsValues = Form.useWatch([], form);

  const showAlternativeInterviewDate = fieldsValues && fieldsValues['Interview Availability'] === 'No';
  const showAlternativeSalaryRange = fieldsValues && fieldsValues['Comfortable with Salary Range'] === 'No';

  const defaultValidateMessages = {
    required: 'Field is required',
  };

  // @ts-ignore
  const validateCheckbox = (rule, value): Promise<string | void> => {
    if (!value) {
      return Promise.reject(new Error('Field is required'));
    }
    return Promise.resolve();
  };

  const handleClearForm = (): void => {
    form.resetFields();
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true });
  }, [fieldsValues]);

  return jobData && (
    <div className="roundedContainer">
      <div className={styles.content}>
        <QuestionnaireProgressBar form={form} />

        <div className={styles.sendEmailNoteContainer}>
          <ExclamationIcon />
          <FormattedMessage id="screening.sendEmail.note" />
        </div>

        <Button
          type="primary"
          className={cs('btn btn-primary', styles.skipEmailBtn)}
          onClick={handleSendLinkToEmail}
          disabled={disable}
        >
          <FormattedMessage id="form.control.label.skipEmailMe" />
        </Button>

        <div>
          <h1 className={styles.contentTitle}>
            {intl.formatMessage({ id: 'screening.welcomeMessage' }, { jobTitle: jobData.jobTitle })}
          </h1>
          <Form
            form={form}
            scrollToFirstError
            name="screeningForm"
            autoComplete="off"
            className="form-container"
            onFinish={handleSubmit}
            validateMessages={defaultValidateMessages}
          >
            {/* Availability */}
            <h2 className={styles.sectionTitle}>
              {intl.formatMessage({ id: 'screening.section.title.availability' })}
            </h2>
            <Row gutter={[24, 16]} className={styles.blockContainer}>
              <Col span={24} md={12} className="offset-right">
                <Form.Item
                  name="Start Date"
                  label={intl.formatMessage({ id: 'screening.form.label.whenStart' })}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    // @ts-ignore
                    disabledDate={disabledDate}
                    format={dateFormat}
                    placeholder={dateFormat}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="Weekend/Overtime Availability"
                  label={intl.formatMessage({ id: 'screening.form.label.areYouOpenToWork' })}
                  className="large-label"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="Preferred Schedule"
                  label={intl.formatMessage({ id: 'screening.form.label.selectShift' })}
                  className="large-label"
                  rules={[{ required: true }]}
                >
                  <Checkbox.Group options={convertArraySorted(shiftSchedule)} />
                </Form.Item>
              </Col>
            </Row>

            {/* Experience */}
            <h2 className={styles.sectionTitle}>
              {intl.formatMessage({ id: 'screening.section.title.experience' })}
            </h2>
            <Row gutter={[24, 16]} className={styles.blockContainer}>
              <Col span={24} md={12} className="offset-right">
                <Form.Item
                  name="Years of Industry Experience"
                  label={intl.formatMessage(
                    { id: 'screening.form.label.yearsOfIndustryExperience' },
                    { industry: jobData.industry },
                  )}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    placeholder={intl.formatMessage(
                      { id: 'screening.form.label.yearsOfIndustryExperience.placeholder' },
                      { industry: jobData.industry },
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Location & Relocation */}
            <h2 className={styles.sectionTitle}>
              {intl.formatMessage({ id: 'screening.section.title.locationRelocation' })}
            </h2>
            <Row gutter={[24, 16]} className={styles.blockContainer}>
              <Col span={24}>
                <Form.Item
                  name="Commuting Method"
                  label={intl.formatMessage(
                    { id: 'screening.form.label.commutingMethod' },
                    { location: jobData.location },
                  )}
                  className="large-label"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
            </Row>

            {/* Legal & Work Authorization */}
            <h2 className={styles.sectionTitle}>
              {intl.formatMessage({ id: 'screening.section.title.legalWorkAuthorization' })}
            </h2>
            <Row gutter={[24, 16]} className={styles.blockContainer}>
              <Col span={24}>
                <Form.Item
                  name="Work Authorization"
                  label={intl.formatMessage(
                    { id: 'screening.form.label.workAuthorization' },
                    { country: jobData.country },
                  )}
                  className="large-label"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
            </Row>

            {/* Salary Expectations */}
            <h2 className={styles.sectionTitle}>
              {intl.formatMessage({ id: 'screening.section.title.salaryExpectations' })}
            </h2>
            <Row gutter={[24, 16]} className={styles.blockContainer}>
              <Col span={24} md={12}>
                <Form.Item
                  name="Comfortable with Salary Range"
                  label={intl.formatMessage({ id: 'screening.form.label.comfortableSalaryRange' })}
                  className="large-label"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
              {showAlternativeSalaryRange && (
                <Col span={24} md={12}>
                  <Form.Item
                    name="Salary Expectation"
                    label={intl.formatMessage({ id: 'screening.form.label.salaryExpectation' })}
                    className="large-label"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'screening.form.label.salaryExpectation.placeholder' })} />
                  </Form.Item>
                </Col>
              )}
            </Row>

            {/* Availability for Interview */}
            <h2 className={styles.sectionTitle}>
              {intl.formatMessage({ id: 'screening.section.title.availabilityForInterview' })}
            </h2>
            <Row gutter={[24, 16]} className={styles.blockContainer}>
              <Col span={24} md={12} className={cs({ 'offset-right': !showAlternativeInterviewDate })}>
                <Form.Item
                  name="Interview Availability"
                  label={intl.formatMessage(
                    { id: 'screening.form.label.interview' },
                    { interviewTime: jobData.interviewTime },
                  )}
                  className="large-label"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
              {showAlternativeInterviewDate && (
                <Col span={24} md={12}>
                  <Form.Item
                    name="Alternate Interview Dates"
                    label={intl.formatMessage({ id: 'screening.form.label.alternativeTime' })}
                    className="large-label"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={intl.formatMessage({ id: 'screening.form.label.alternativeTime.placeholder' })} />
                  </Form.Item>
                </Col>
              )}
            </Row>

            {/* Code of Conduct & Ethical Questions */}
            <h2 className={styles.sectionTitle}>
              {intl.formatMessage({ id: 'screening.section.title.codeOfConduct' })}
            </h2>
            <Row gutter={[24, 16]} className={styles.blockContainer}>
              <Col span={24} md={12}>
                <Form.Item
                  name="Felony Conviction"
                  label={intl.formatMessage({ id: 'screening.form.label.felonyConviction' })}
                  className="large-label"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="Background Check Consent"
                  label={intl.formatMessage({ id: 'screening.form.label.backgroundCheck' })}
                  className="large-label"
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
            </Row>
            {/* Upload my CV/Resume */}
            <h2 className={styles.sectionTitle}>
              {intl.formatMessage({ id: 'screening.section.title.uploadResume' })}
            </h2>
            <div className={styles.blockContainer}>
              <UploadResume onChangeResume={onChangeResume} />
            </div>
            <Row gutter={[24, 24]} className={styles.blockContainer}>
              <Col span={24}>
                <Form.Item name="Confirmation Response" valuePropName="checked" rules={[{ validator: validateCheckbox }]}>
                  <Checkbox className="confirm-checkbox-container">
                    <FormattedMessage id="screening.form.label.confirmationResponse" />
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="Confirmation Read" valuePropName="checked" rules={[{ validator: validateCheckbox }]}>
                  <Checkbox className="confirm-checkbox-container">
                    <FormattedMessage id="screening.form.label.confirmationRead" />
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <div className={styles.buttonContainer}>
                  <Button
                    type="link"
                    className="btn btn-icon rotation-animation"
                    icon={<RotateIcon />}
                    onClick={handleClearForm}
                    disabled={disable}
                  >
                    <FormattedMessage id="form.control.label.clear" />
                  </Button>
                  <Button className="btn btn-info" onClick={handleSendLinkToEmail} disabled={disable}>
                    <FormattedMessage id="form.control.label.skipEmailMe" />
                  </Button>
                  <Button
                    type="primary"
                    disabled={disable}
                    className="btn btn-primary"
                    htmlType="submit"
                  >
                    <FormattedMessage id="form.intake.submit" />
                  </Button>
                </div>
              </Col>
            </Row>

          </Form>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireForm;
