import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from 'features/auth/authSlice';
import localeReducer from 'features/locale/localeSlice';
import sidebarReducer from 'features/sidebar/sidebarSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    locale: localeReducer,
    sidebar: sidebarReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
