import {
  SalesRecord, FormatterClient, TAOFields,
} from 'types/airtable';
import getDateCustomFormat from 'utils/getInfo/getDateCustomFormat';

export const formatFormData = (fields: TAOFields): Record<string, any> => ({
  ...fields,
  'Onboarding Fee': fields['Onboarding Fee Amount'],
  'Monthly ACE Fee': fields['Monthly ACE Fee Amount'],
  'Retention Fee': fields['Retention Fee Amount'],
  'Direct Hire Fee': fields['Direct Hire Fee Amount'],
  'Other Fees': fields['Other Fees'],
  'Number of Openings': fields['Number of Openings'],
  TFA: fields.TFA === 'Yes',
  'Start date': getDateCustomFormat(fields['Start date']),
  'Preferred Interview Date': getDateCustomFormat(fields['Preferred Interview Date']),
  Industry: [fields.Industry],
});

export const formatClientFields = (fields: SalesRecord): FormatterClient => ({
  'Company Name': fields['Company Name'],
  'Public or Private?': fields['Public or Private?'],
  Employer: fields['Company Name'],
  'Company Website': fields['Company Website'],
  'E-mail': fields['E-mail'],
  'Client Country': fields['Client Country'],
  City: fields.City,
  'Company Address': fields['Company Address'],
  'Phone Number': fields['Phone Number'],
  Industry: fields.Industry,
  'Point of Contact': fields['Point of Contact'],
  'Sales Person Name': fields['Sales Person']?.name,
  'Sales Person': fields['Sales Person']?.id,
  'BusDev email': fields['BusDev email'],
  'Client ID': fields.recordid,
  'Years in Business': fields['Years in Business'],
  'POC Position': fields['POC Position'],
  'Invoicing name': fields['Invoicing name'],
  'Invoicing email': fields['Invoicing email'],
});
