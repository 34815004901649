import { incomePDFPreviewFields, PDFPreviewExtraData } from 'types/pdf';
import getFullDate from 'utils/getInfo/getFullDate';
import getPDFNotification from 'utils/getInfo/getPDFNotification';

const formatTalentAcquisitionPDFPreviewExtraData = (
  fields: incomePDFPreviewFields,
): PDFPreviewExtraData => ({
  email: fields['E-mail'],
  notification: getPDFNotification(fields['E-mail'], fields['Company Name'], fields['Created Date']),
  createdDate: getFullDate(fields['Created Date']),
});

export default formatTalentAcquisitionPDFPreviewExtraData;
