import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { enLang } from 'languages';

interface LocaleState {
  appLocaleMessages: Record<string, string>;
  locale: string;
}

const initialState: LocaleState = {
  appLocaleMessages: enLang.messages,
  locale: 'en',
};

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocaleMessages(state, action: PayloadAction<Record<string, string>>) {
      state.appLocaleMessages = action.payload;
    },
    setLocale(state, action: PayloadAction<string>) {
      state.locale = action.payload;
    },
  },
});

export const selectAppLocaleMessages = (state: RootState): Record<string, string> =>
  state.locale.appLocaleMessages;
export const selectAppLocale = (state: RootState): string => state.locale.locale;

export const { setLocaleMessages, setLocale } = localeSlice.actions;

export default localeSlice.reducer;
