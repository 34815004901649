const skillSets = [
  'Administrative assistance',
  'Customer/client relationship management',
  'Data Entry',
  'Logistics and inventory management',
  'Procurement and facilities management',
  'Recordkeeping and document management',
  'Sales management',
  'Fruit',
  'Vegetable & Crop Farming',
  'Meat & Dairy',
  'Fishing',
  'Farm Equipment Operation',
  'Masonry',
  'Carpentry',
  'Painting',
  'Surfacing & Plastering',
  'Drywall',
  'Electrical',
  'Fitting',
  'Plumbing',
  'Framing',
  'Construction management',
  'Concrete',
  'Paving',
  'Roofing & Facade work',
  'Sheet Metal work',
  'Inspecting',
  'Tiling',
  'Engineering',
  'HVAC work',
  'Ironwork (Welding, Tinsmithing)',
  'Construction Equipment',
  'Unskilled Construction work',
  'Teaching',
  'Bakery',
  'Cooking',
  'Meat Processing',
  'Food Processing',
  'Packaging',
  'General warehouse work',
  'Medicine',
  'Nursing',
  'Pharmacy',
  'Childcare',
  'Senior care',
  'Hotel Operations',
  'IT Technician',
  'System Analyst',
  'Customer Support',
  'Benchwork',
  'Metal Fabrication',
  'Joinery',
  'Machining',
  'Shipbuilding',
  'Assembly & Installation',
  'Equipment repair',
  'Unskilled production work',
  'Merchandiser',
  'Cashier',
  'Retail Clerk',
  'Inventory Clerk',
  'Sales Associate',
  'Housekeeping',
  'Domestic Services',
  'Gardening & Groundskeeping',
  'Maintenance & Cleaning',
  'Machinery repairs',
  'Driving/Operating',
  'Taxi',
  'Delivery',
];

export default skillSets;
