import { useEffect, useState } from 'react';

type ArrayItem = {
  key: string;
  value: string;
};

const useSortedArrayByProp = (array: ArrayItem[], propName: keyof ArrayItem): ArrayItem[] => {
  const [sortedArray, setSortedArray] = useState<ArrayItem[]>([]);

  useEffect(() => {
    const sorted = [...array]
      .filter((item) => item.key !== '' && item.value !== '')
      .sort((a, b) => {
        if (typeof a[propName] === 'string' && typeof b[propName] === 'string') {
          return a[propName].localeCompare(b[propName]);
        }
        return 0;
      });

    setSortedArray(sorted);
  }, [array, propName]);

  return sortedArray;
};

export default useSortedArrayByProp;
