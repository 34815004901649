import html2canvas from 'html2canvas';

const resizeSignature = async (canvas: HTMLCanvasElement | null): Promise<string | null> => {
  if (!canvas) {
    return null;
  }

  try {
    const canvasImage = await html2canvas(canvas, { backgroundColor: null });

    const resizedCanvas = document.createElement('canvas');
    const resizedContext = resizedCanvas.getContext('2d');

    if (!resizedContext) {
      throw new Error('Unable to get 2d context for the resized canvas');
    }

    resizedCanvas.width = 100;
    resizedCanvas.height = 50;

    resizedContext.drawImage(canvasImage, 0, 0, resizedCanvas.width, resizedCanvas.height);

    return resizedCanvas.toDataURL('image/png');
  } catch (error) {
    console.error('Error resizing signature:', error);
    return null;
  }
};

export default resizeSignature;
