export const clientCountries = ['USA', 'Canada'];

export const decisionArray = ['Yes', 'No'];

export const companyTypesArray = ['Public', 'Private'];

export const jobTypesArray = [
  'Full Time',
  'Part Time',
  'Seasonal',
  'Contractor',
];

export const shiftSchedule = ['Day Shift', 'Afternoon Shift', 'Night Shift'];

export const candidateLocationArray = ['Domestic', 'Overseas', 'Domestic/Overseas'];

export const numberOfInterviews = ['0', '1', '2', '3', '4', '5+'];

export const yearsInBusiness = ['0-1', '1-3', '3-5', '5+', '10+'];
