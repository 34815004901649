import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'antd';
import styles from './StyledSwitch.module.scss';

interface StyledSwitchProps {
  onChange: () => void;
  leftLabel?: string;
  rightLabel?: string;
}

const StyledSwitch: FC<StyledSwitchProps> = ({
  onChange,
  leftLabel = 'auth.question.label.no',
  rightLabel = 'auth.question.label.yes',
}) => {
  const [checked, setChecked] = useState(false);
  const intl = useIntl();
  const handleChange = useCallback(() => {
    setChecked(!checked);
    onChange?.();
  }, [checked]);
  return (
    <span className={styles.container}>
      {intl.formatMessage({ id: leftLabel })}
      <Switch
        onChange={handleChange}
        checked={checked}
        data-qa="refugeeToggle"
        className={styles.switchBoundaries}
      />
      {intl.formatMessage({ id: rightLabel })}
    </span>
  );
};


export default StyledSwitch;
