import {
  ReactElement, useState, useEffect, FC,
} from 'react';
import { useIntl } from 'react-intl';
import { Modal, Button } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useWindowDimensions from 'hooks/useWindowDimensions';

import styles from './SignatureModal.module.scss';

interface SignatureModalProps {
  handleSubmit: (signature: SignatureCanvas | null) => any;
  handleCancel: () => void;
  loadingButton: boolean;
  open: boolean;
}

const SignatureModal: FC<SignatureModalProps> = ({
  handleSubmit,
  handleCancel,
  loadingButton,
  open,
}): ReactElement => {
  const intl = useIntl();
  const { width: viewportWidth } = useWindowDimensions();
  const [signatureRef, setSignatureRef] = useState<SignatureCanvas | null>(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const handleClear = (): void | null =>
    signatureRef && signatureRef.clear();

  const renderSupportLink = (
    <a href="mailto: legal@joblio.co" rel="noreferrer" title="legal@joblio.co">legal@joblio.co</a>
  );

  const calculateCanvasSize = (): void => {
    const parentContainer = document.querySelector('.ant-modal-content');
    if (parentContainer) {
      const parentWidth = parentContainer.clientWidth;
      const { paddingLeft, paddingRight } = getComputedStyle(parentContainer);
      const parentInnerWidth = parentWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);

      const maxWidth = parentInnerWidth;
      const maxHeight = maxWidth * 0.5;

      setCanvasSize({ width: maxWidth, height: maxHeight });
    }
  };

  useEffect(() => {
    const modalElement = document.querySelector('.ant-modal-content');
    if (modalElement) {
      calculateCanvasSize();
    } else {
      document.addEventListener('DOMNodeInserted', calculateCanvasSize);
    }
    return (): void => {
      document.removeEventListener('DOMNodeInserted', calculateCanvasSize);
    };
  }, []);

  useEffect(() => {
    calculateCanvasSize();
  }, [viewportWidth]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'label.tao.modal.title' })}
      open={open}
      onCancel={handleCancel}
      centered
      footer={[
        <Button key="clear" type="text" className="btn" onClick={handleClear}>
          {intl.formatMessage({ id: 'label.tao.modal.clearButton' })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="btn btn-primary"
          loading={loadingButton}
          onClick={() => handleSubmit(signatureRef)}
        >
          {intl.formatMessage({ id: 'label.tao.modal.submitButton' })}
        </Button>,
      ]}
    >
      <div className={styles.infoContainer}>
        <ExclamationCircleOutlined className={styles.exclamation} />
        <div>
          <h3>{intl.formatMessage({ id: 'label.tao.modal.info.title' })}</h3>
          {intl.formatMessage({ id: 'label.tao.modal.info.text' }, { link: renderSupportLink })}
        </div>
      </div>
      <SignatureCanvas
        ref={(ref) => setSignatureRef(ref)}
        canvasProps={{
          width: canvasSize.width,
          height: canvasSize.height,
          className: styles.canvas,
        }}
      />
    </Modal>
  );
};

export default SignatureModal;
