import { useState, useEffect } from 'react';

interface WindowDimensions {
  width: number;
  height: number;
}

const useWindowDimensions = (debounceTime: number = 100): WindowDimensions => {
  const [dimensions, setDimensions] = useState<WindowDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    let debounceTimer: ReturnType<typeof setTimeout>;

    const updateDimensions = (): void => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const handleResize = (): void => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(updateDimensions, debounceTime);
    };

    window.addEventListener('resize', handleResize);
    return (): void => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(debounceTimer);
    };
  }, [debounceTime]);

  return dimensions;
};

export default useWindowDimensions;
