import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAppLocale } from 'features/locale/localeSlice';
import { ReactComponent as Image } from 'assets/images/svg/gdpr_logo_blue.svg';

// ant design
import { Col, Row } from 'antd';
import { Footer } from 'antd/es/layout/layout';

// other resource
import FindBlock from 'components/FindBlock';
import LOCALES from 'constants/locales';
import { COPYRIGHT_YEAR } from 'constants/application';

// styles
import getLegalDocsLinks from 'utils/getInfo/getLegalDocsLinks';
import styles from './Footer.module.scss';
import { buildVersion } from '../../../main';

const AppFooter = () => {
  const locale = useSelector(selectAppLocale);
  const {
    urlPrivacy,
    urlTerms,
    urlDataPrivacyRights,
    urlCookiePolicy,
  } = getLegalDocsLinks(locale);
  const { pathname } = useLocation();

  return (
    <Footer className={styles.Footer}>
      <div className="container">
        {pathname.includes('/jobs/') && <FindBlock />}
        <div className={styles.linkSection}>
          <Row align="middle" justify="space-between">
            <Col xs={24} lg={4} gutter={12}>
              <p>
                <FormattedMessage id="footer.copyright" values={{ copyrightYear: COPYRIGHT_YEAR }} />
                {' '}
                <FormattedMessage id="footer.build" />
                {' '}
                {buildVersion?.version}
              </p>
            </Col>
            <Col xs={24} lg={11} className={styles.RightContainer}>
              <Image className={styles.Logo} />
              <div className={`${styles.linksContainer} ${locale === LOCALES.ENGLISH ? styles.noWrap : ''}`}>
                <a className={styles.FooterLink} href={urlTerms} target="_blank" data-qa="termsOfUseLink" rel="noreferrer" aria-label="Terms of use">
                  <FormattedMessage id="terms_of_use" />
                </a>
                <a className={styles.FooterLink} href={urlPrivacy} target="_blank" data-qa="privacyPolicyLink" rel="noreferrer" aria-label={urlPrivacy}>
                  <FormattedMessage id="privacy_policy" />
                </a>
                <a className={styles.FooterLink} href={urlCookiePolicy} target="_blank" data-qa="dataCookiePolicyLink" rel="noreferrer" aria-label={urlCookiePolicy}>
                  <FormattedMessage id="GDPR.cookie_policy" />
                </a>
                <a className={styles.FooterLink} href={urlDataPrivacyRights} target="_blank" data-qa="dataPrivacyRightsLink" rel="noreferrer" aria-label={urlDataPrivacyRights}>
                  <FormattedMessage id="data_privacy_rights" />
                </a>
              </div>
              {/* <LazyLoadImage */}
              {/*  style={{ */}
              {/*    width: '10%', */}
              {/*    height: 'auto', */}
              {/*  }} */}
              {/*  src={LogoBusinessCircle} */}
              {/*  alt="logo-business-circle" */}
              {/* /> */}
            </Col>
          </Row>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
