const countriesExtended = [
  {label: 'Argentina', value: 'Argentina', code: 'AR'},
  {label: 'Armenia', value: 'Armenia', code: 'AM'},
  {label: 'Australia', value: 'Australia', code: 'AU'},
  {label: 'Azerbaijan', value: 'Azerbaijan', code: 'AZ'},
  {label: 'Bangladesh', value: 'Bangladesh', code: 'BD'},
  {label: 'Barbados', value: 'Barbados', code: 'BB'},
  {label: 'Belarus', value: 'Belarus', code: 'BY'},
  {label: 'Belize', value: 'Belize', code: 'BZ'},
  {label: 'Benin', value: 'Benin', code: 'BJ'},
  {label: 'Bhutan', value: 'Bhutan', code: 'BT'},
  {label: 'Brazil', value: 'Brazil', code: 'BR'},
  {label: 'Brunei', value: 'Brunei', code: 'BN'},
  {label: 'Bulgaria', value: 'Bulgaria', code: 'BG'},
  {label: 'Burkina Faso', value: 'Burkina Faso', code: 'BF'},
  {label: 'Burundi', value: 'Burundi', code: 'BI'},
  {label: 'Cambodia', value: 'Cambodia', code: 'KH'},
  {label: 'Cameroon', value: 'Cameroon', code: 'CM'},
  {label: 'Canada', value: 'Canada', code: 'CA'},
  {label: 'Central African Republic', value: 'Central African Republic', code: 'CF'},
  {label: 'China', value: 'China', code: 'CN'},
  {label: 'Colombia', value: 'Colombia', code: 'CO'},
  {label: 'Cuba', value: 'Cuba', code: 'CU'},
  {label: 'Congo', value: 'Congo', code: 'CG'},
  {label: 'Dominican Republic', value: 'Dominican Republic', code: 'DO'},
  {label: 'Egypt', value: 'Egypt', code: 'EG'},
  {label: 'Estonia', value: 'Estonia', code: 'EE'},
  {label: 'Ethiopia', value: 'Ethiopia', code: 'ET'},
  {label: 'France', value: 'France', code: 'FR'},
  {label: 'Gambia', value: 'Gambia', code: 'GM'},
  {label: 'Georgia', value: 'Georgia', code: 'GE'},
  {label: 'Germany', value: 'Germany', code: 'DE'},
  {label: 'Ghana', value: 'Ghana', code: 'GH'},
  {label: 'Greece', value: 'Greece', code: 'GR'},
  {label: 'Guatemala', value: 'Guatemala', code: 'GT'},
  {label: 'Guinea', value: 'Guinea', code: 'GN'},
  {label: 'Guyana', value: 'Guyana', code: 'GY'},
  {label: 'Haiti', value: 'Haiti', code: 'HT'},
  {label: 'Honduras', value: 'Honduras', code: 'HN'},
  {label: 'Hungary', value: 'Hungary', code: 'HU'},
  {label: 'India', value: 'India', code: 'IN'},
  {label: 'Indonesia', value: 'Indonesia', code: 'ID'},
  {label: 'Iran', value: 'Iran', code: 'IR'},
  {label: 'Iraq', value: 'Iraq', code: 'IQ'},
  {label: 'Israel', value: 'Israel', code: 'IL'},
  {label: 'Italy', value: 'Italy', code: 'IT'},
  {label: 'Ivory Coast', value: 'Ivory Coast', code: 'CI'},
  {label: 'Jamaica', value: 'Jamaica', code: 'JM'},
  {label: 'Kazakhstan', value: 'Kazakhstan', code: 'KZ'},
  {label: 'Kenya', value: 'Kenya', code: 'KE'},
  {label: 'Kyrgyzstan', value: 'Kyrgyzstan', code: 'KG'},
  {label: 'Latvia', value: 'Latvia', code: 'LV'},
  {label: 'Lebanon', value: 'Lebanon', code: 'LB'},
  {label: 'Libya', value: 'Libya', code: 'LY'},
  {label: 'Lithuania', value: 'Lithuania', code: 'LT'},
  {label: 'Madagascar', value: 'Madagascar', code: 'MG'},
  {label: 'Malawi', value: 'Malawi', code: 'MW'},
  {label: 'Malaysia', value: 'Malaysia', code: 'MY'},
  {label: 'Mali', value: 'Mali', code: 'ML'},
  {label: 'Malta', value: 'Malta', code: 'MT'},
  {label: 'Mauritania', value: 'Mauritania', code: 'MR'},
  {label: 'Mauritius', value: 'Mauritius', code: 'MU'},
  {label: 'Mexico', value: 'Mexico', code: 'MX'},
  {label: 'Moldova', value: 'Moldova', code: 'MD'},
  {label: 'Morocco', value: 'Morocco', code: 'MA'},
  {label: 'Myanmar (Burma)', value: 'Myanmar (Burma)', code: 'MM'},
  {label: 'Namibia', value: 'Namibia', code: 'NA'},
  {label: 'Nepal', value: 'Nepal', code: 'NP'},
  {label: 'Nicaragua', value: 'Nicaragua', code: 'NI'},
  {label: 'Nigeria', value: 'Nigeria', code: 'NG'},
  {label: 'Pakistan', value: 'Pakistan', code: 'PK'},
  {label: 'Peru', value: 'Peru', code: 'PE'},
  {label: 'Philippines', value: 'Philippines', code: 'PH'},
  {label: 'Poland', value: 'Poland', code: 'PL'},
  {label: 'Portugal', value: 'Portugal', code: 'PT'},
  {label: 'Puerto Rico', value: 'Puerto Rico', code: 'PR'},
  {label: 'Romania', value: 'Romania', code: 'RO'},
  {label: 'Russia', value: 'Russia', code: 'RU'},
  {label: 'Rwanda', value: 'Rwanda', code: 'RW'},
  {label: 'Saudi Arabia', value: 'Saudi Arabia', code: 'SA'},
  {label: 'Senegal', value: 'Senegal', code: 'SN'},
  {label: 'Serbia and Montenegro', value: 'Serbia and Montenegro', code: 'SC'},
  {label: 'Sierra Leone', value: 'Sierra Leone', code: 'SL'},
  {label: 'Somalia', value: 'Somalia', code: 'SO'},
  {label: 'South Africa', value: 'South Africa', code: 'ZA'},
  {label: 'South Sudan', value: 'South Sudan', code: 'SS'},
  {label: 'Spain', value: 'Spain', code: 'ES'},
  {label: 'Sri Lanka', value: 'Sri Lanka', code: 'LK'},
  {label: 'Sudan', value: 'Sudan', code: 'SD'},
  {label: 'Syria', value: 'Syria', code: 'SY'},
  {label: 'Tajikistan', value: 'Tajikistan', code: 'TJ'},
  {label: 'Tanzania', value: 'Tanzania', code: 'TZ'},
  {label: 'Tunisia', value: 'Tunisia', code: 'TN'},
  {label: 'Turkey', value: 'Turkey', code: 'TR'},
  {label: 'Turkmenistan', value: 'Turkmenistan', code: 'TM'},
  {label: 'Uganda', value: 'Uganda', code: 'UG'},
  {label: 'Ukraine', value: 'Ukraine', code: 'UA'},
  {label: 'United Arab Emirates', value: 'United Arab Emirates', code: 'AE'},
  {label: 'United Kingdom', value: 'United Kingdom', code: 'GB'},
  {label: 'United States', value: 'United States', code: 'US'},
  {label: 'Uzbekistan', value: 'Uzbekistan', code: 'UZ'},
  {label: 'Venezuela', value: 'Venezuela', code: 'VE'},
  {label: 'Vietnam', value: 'Vietnam', code: 'VN'},
  {label: 'Zambia', value: 'Zambia', code: 'ZM'},
  {label: 'Zimbabwe', value: 'Zimbabwe', code: 'ZW'},
];

export default countriesExtended;
