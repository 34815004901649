import { AIRTABLE_HOST } from 'constants/api';
import { getHeaders } from 'utils/api/getHeaders';
import { buildParams, buildSortParams, fetchData } from 'utils/api/airtableUtils';
import { AirtableParams, AirtableResponse, Records } from 'types/airtable';

const headers = getHeaders();

type FilterParams = {
  filterByFormula?: string;
};

export const AirtableService = {
  async getRecords(options: AirtableParams): Promise<AirtableResponse> {
    const filters: FilterParams = {};
    const {
      fieldName, fieldValue, sortField = '', filterFormula = '',
      sortDirection = 'desc', viewName, pageSize, tableName,
    } = options;

    if (filterFormula) {
      filters.filterByFormula = filterFormula;
    } else if (fieldName && fieldValue) {
      filters.filterByFormula = `{${fieldName}} = "${fieldValue}"`;
    }

    const params = buildParams(pageSize, viewName, filters);
    const sortParams = buildSortParams(sortField, sortDirection);

    return fetchData(`${AIRTABLE_HOST}/${tableName}?${params}&${sortParams}`, { headers });
  },

  async createRecord(options: AirtableParams): Promise<any> {
    const {
      tableName, typecast = false, fields,
    } = options;
    return fetchData(`${AIRTABLE_HOST}/${tableName}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ fields, typecast }),
    });
  },

  async updateRecord(options: AirtableParams): Promise<void> {
    const {
      tableName, recordId, fields, typecast = false,
    } = options;
    return fetchData(`${AIRTABLE_HOST}/${tableName}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ records: [{ id: recordId, fields, typecast }] }),
    });
  },

  async getRecord(options: AirtableParams): Promise<Records> {
    const { tableName, recordId } = options;
    return fetchData(`${AIRTABLE_HOST}/${tableName}/${recordId}`, { headers });
  },

  async deleteRecord(options: AirtableParams): Promise<void> {
    const { tableName, recordId } = options;
    return fetchData(`${AIRTABLE_HOST}/${tableName}/${recordId}`, {
      method: 'DELETE',
      headers,
    });
  },
};

export default AirtableService;
