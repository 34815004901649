import { CSSProperties, ReactElement } from 'react';
import { FormInstance } from 'antd';

export interface Address {
  phoneNumber?: string;
  phoneStatus?: string;
  countryCode?: string;
}

export interface BasicInfo extends StructureSpecial {
  email?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  citizenship?: string;
  gender?: string;
  address?: Address;
}

export interface Skill {
  name: string;
  label: string;
  value: string;
  industry: {
    label: string;
    value: string;
  };
}

export interface Industry {
  [key: string]: Skill[];
}

export interface UserCommon {
  isAnonymous: boolean;
  uid: string;
}

export interface UserProfileData {
  basicInfo: BasicInfo;
  areasExperiences: string[];
  childrenWith?: number;
  countryCitizenship: string;
  driversLicense: any;
  spokenLanguages: string[];
  lengthExperiences: string[];
  socialNetworks: string[];
  educationLevel: string;
  canPayForTransfer: boolean;
  leaveUkraineAfterWarStart: boolean;
  vaccinationVendor: string;
  vaccinationStatus: string;
  cityProvince: string;
  readyToRelocateOntario: boolean;
  variousLicenses: string[];
  relocateState: string;
  salaryExpectation: string;
  workedFullTime: boolean;
  warehouseWork: boolean;
  qualification: string;
  salaryRange: string;
  nativeLanguage: string;
  additionalLanguage: string;
  languageLevel: string;
  languageLevel2: string;
  visaStatus: string;
  locale: string;
  user: any;
  firebase: any;
  pressedEmployerURI: string;
  attachment: any;
  detectComingFromPage: boolean;
  signUpMethod: string;
  resume?: any;
}

export interface UpdateUserProfileData {
  structure: any;
}

export interface ApplyForJobProps {
  navigate: (path: string) => void;
  user: UserCommon;
  jobId: string;
  sourcePage: string;
}

export interface EmployerData {
  country: string;
}

export interface User {
  email: string;
  providerData: any[];
}

export interface DeviceInfo {
  platform: string;
  appVersion: string | undefined;
  timezoneOffset: string;
  timezoneId: string;
  manufacturer: string | undefined;
  model: string | undefined;
}

export interface SkillSet {
  [key: string]: Skill;
}

export interface Industries {
  [key: string]: Industry;
}

export interface StructureSpecial {
  childrenWith?: number;
  spokenLanguages: string[];
  lengthExperiences: string[];
  driversLicense: any;
  countryCitizenship: string;
  areasExperiences: string[] | Skill[];
  socialNetworks: string[];
  canPayForTransfer: boolean;
  leaveUkraineAfterWarStart: boolean;
  cityProvince: string;
  readyToRelocateOntario: boolean;
  workedFullTime: boolean;
  warehouseWork: boolean;
  qualification: string;
  salaryRange: string;
  nativeLanguage: string;
  additionalLanguage: string;
  languageLevel: string;
  languageLevel2: string;
  variousLicenses: string[];
  relocateState: string;
  salaryExpectation: string;
  educationLevel: string;
  vaccinationVendor: string;
  vaccinationStatus: string;
  visaStatus: string;
}

export interface GroupedSkill {
  label: string;
  value: string;
  children: Skill[];
}

export interface FormatJobSpecificDataParams {
  detectComingFromPage?: boolean;
  warehouseWork?: boolean;
  salaryRange?: string;
  qualification?: string;
  readyToRelocateOntario?: boolean;
  relocateState?: string;
  salaryExpectation?: string;
  variousLicenses: string[];
  cityProvince?: string;
  workedFullTime?: boolean;
  structureSpecial: any;
  visaStatus?: string;
}

export interface Language {
  level: string;
}

export interface Languages {
  [key: string]: Language;
}

export interface FormatLanguagesParams {
  nativeLanguage?: string;
  additionalLanguage?: string;
  languageLevel?: string;
  languageLevel2?: string;
  spokenLanguages?: string[] | Languages;
}

export interface ContainerProps {
  label: string;
  name: string;
  data: any[];
  labelStyles?: CSSProperties;
  customStyles?: CSSProperties;
  exception?: string;
  customData?: any;
  form?: FormInstance;
  onChange?: (value: any) => void;
  showSearch?: boolean;
  placeholder: string;
  number?: boolean;
  addonBefore?: string;
  addonAfter?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  options?: Array<{
    value: string;
    label: string;
    children: Array<{
      value: string;
      label: string;
    }>;
  }>;
  componentStyles?: CSSProperties;
  required: boolean;
  children?: ReactElement | ReactElement[] | any;
  dataQa: {
    title: string;
    input: string;
  }
  otherProps?: {
    [key: string]: any;
  }
}

export enum Delimiter {
  QUESTION_MARK = '?',
  AMPERSAND = '&',
}

export enum FLAVOURS_LOOKUPS {
  PSW = 'PSW',
  LABOURER = 'LABOURER',
  WELDERS = 'WELDERS',
}
