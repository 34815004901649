import { useEffect, useState } from 'react';

interface UseAppVersionCheckResult {
  isOldVersion: boolean;
  clearCache: () => void;
  error: Error | null;
}

const useAppVersionCheck = (): UseAppVersionCheckResult => {
  const [isOldVersion, setIsOldVersion] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      const oldVersion = localStorage.getItem('appVersion');
      const newVersion = (document?.querySelector('meta[name="version"]') as HTMLMetaElement)?.content;

      console.info('versions', {
        oldVersion,
        newVersion,
        hasChanged: oldVersion?.toString() !== newVersion?.toString(),
      });

      if (!oldVersion) {
        localStorage.setItem('appVersion', newVersion || '');
      } else {
        setIsOldVersion(oldVersion.toString() !== newVersion?.toString());
      }
    } catch (err: any) {
      setError(err);
    }
  }, []);

  const clearCache = (): void => {
    try {
      const newVersion = (document?.querySelector('meta[name="version"]') as HTMLMetaElement)?.content;

      localStorage.setItem('appVersion', newVersion || '');
      window.location.reload();
    } catch (err: any) {
      setError(err);
    }
  };

  return {
    isOldVersion,
    clearCache,
    error,
  };
};

export default useAppVersionCheck;
