import SignatureCanvas from 'react-signature-canvas';

const MIN_SIGNATURE_SIZE = 1000;

const validateSignature = (signatureRef: SignatureCanvas | null | undefined): boolean => {
  if (!signatureRef || signatureRef.isEmpty()) {
    return false;
  }

  const canvas = signatureRef.getCanvas();
  if (!canvas) {
    return false;
  }

  const signatureSize = calculateSignatureSize(canvas);
  return signatureSize >= MIN_SIGNATURE_SIZE;
};

const calculateSignatureSize = (canvas: HTMLCanvasElement): number => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return 0;
  const { data, width, height } = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let nonTransparentPixels = 0;
  for (let i = 0; i < data.length; i += 4) {
    if (data[i + 3] !== 0) {
      nonTransparentPixels += 1;
    }
  }
  return nonTransparentPixels;
};

export default validateSignature;
