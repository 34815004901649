import { useState, ReactElement } from 'react';
import styles from './Iframe.module.scss';
import LoadingIndicator from '../LoadingIndicator';
import { usePageOffsets } from 'hooks/usePageOffsets';

interface IframeProps {
    title: string;
    src: string;
}

const Iframe = ({ title, src }: IframeProps): ReactElement => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [headerHeight, footerHeight] = usePageOffsets();

  const handleOnLoad = (): void => {
    setIsLoaded(true);
  };

  const iframeWrapperStyle = {
    height: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
  };

  const iframeStyle = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    border: 0,
  };

  return (
    <section>
      <div className={styles.iframeWrapperStyles} style={iframeWrapperStyle}>
        {!isLoaded && <LoadingIndicator />}
        <iframe
          title={title}
          src={src}
          onLoad={handleOnLoad}
          className={`${styles.customFrameStyles} airtable-embed`}
          frameBorder={0}
          loading="lazy"
          sandbox="allow-scripts allow-same-origin"
          scrolling="no"
          style={iframeStyle}
        />
      </div>
    </section>
  );
};

export default Iframe;
