import {
  memo, useMemo, useState, useEffect, ReactNode, ReactElement,
} from 'react';
import { Select, FormInstance } from 'antd';
import { Picker as PickerMobile } from 'antd-mobile';
import { ReactComponent as ArrowDown } from 'assets/icons/selectArrow.svg';
import { PickerColumn } from 'antd-mobile/es/components/picker-view';

interface PickerProps {
  name: string;
  label: string | ReactNode;
  placeholder?: string;
  formatMessage?: (msg: { id: string }) => string;
  columnDataChildren: ReactNode[];
  defaultValue?: string | null;
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  form?: FormInstance;
  onReject?: () => void;
  labelRender?: (data: any) => ReactNode;
  className?: string;
}

const Picker = ({
  name,
  label,
  placeholder = '',
  formatMessage,
  columnDataChildren,
  form,
  onReject,
  defaultValue = null,
  disabled = false,
  labelRender,
  className = '',
  ...otherProps
}: PickerProps): ReactElement | null => {
  const [value, setValue] = useState<ReactNode | null>(defaultValue);
  const [keyValue, setKeyValue] = useState<string | null>(defaultValue);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const fieldValue = form?.getFieldValue(name);
    if (fieldValue !== keyValue) {
      setKeyValue(fieldValue);
      const selectedOption = columnDataChildren.find((child: any) => child.key === fieldValue);
      if (selectedOption) {
        // @ts-ignore
        setValue(selectedOption.props?.selectTitle ? selectedOption.props?.title : selectedOption.props?.children);
      }
    }
  }, [form, name, columnDataChildren, keyValue]);

  const columnData = useMemo<PickerColumn[]>(() => [columnDataChildren?.map((child: any) => ({
    value: child,
    label: child?.props?.children,
    key: child && child?.key,
  }))], [columnDataChildren?.length]);

  if (!columnDataChildren || !columnDataChildren.length) return null;

  const onConfirm = (v: any): void => {
    setValue(v[0]?.props?.selectTitle ? v[0]?.props?.title : v[0]?.props?.children);
    setKeyValue(v[0].key as string);
    otherProps?.onChange?.(v?.[0].key);
    form?.setFieldsValue?.({ [name]: v[0].key });
  };

  const onDismiss = (): void => {
    setVisible(false);
    setValue(null);
    setKeyValue(null);
    if (name) {
      form?.setFieldsValue?.({ [name]: null });
    }
  };

  const renderedLabel = labelRender && value && keyValue
    ? labelRender({ value: keyValue, label: value })
    : value;

  return (
    <>
      <Select
        {...otherProps}
        open={!visible}
        className={className}
        suffixIcon={<ArrowDown className="icon" />}
        placeholder={placeholder}
        onClick={(): boolean | void => !disabled && setVisible(true)}
        // @ts-ignore
        value={renderedLabel && { label: renderedLabel, key: keyValue }}
        dropdownStyle={{ display: 'none' }}
        dropdownRender={(): ReactElement => <span />}
        labelInValue
      />
      <PickerMobile
        title={label}
        visible={visible}
        className={className}
        columns={columnData}
        onConfirm={onConfirm}
        onCancel={onReject || onDismiss}
        confirmText={formatMessage ? formatMessage({ id: 'auth.question.label.confirm' }) : ''}
        cancelText={formatMessage ? formatMessage({ id: 'form.button.cancel' }) : ''}
        onClose={(): void => setVisible(false)}
      />
    </>
  );
};

export default memo(Picker);
