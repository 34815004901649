import JobCard from 'components/Job/JobCard';
import { memo, ReactElement } from 'react';
import LoadingIndicator from '../../LoadingIndicator';
import { JobRecord } from 'types/airtable';
import EmptyList from '../../EmptyList';

export interface JobListProps {
  id: string;
  fields: JobRecord;
}

const JobsList = ({ jobList }: { jobList: JobListProps[] }): ReactElement[] | ReactElement =>
  jobList.map((job: JobListProps) => (
    <JobCard job={job} key={job.id} />
  ));

export default memo(JobsList);
