import { ReactNode } from 'react';
import { notification } from 'antd';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useTypedRedux';
import { selectIsLoggedIn } from 'features/auth/authSlice';
import { MAIN } from 'constants/pathNames';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps): ReactNode => {
  const isAuthenticated = useAppSelector(selectIsLoggedIn);

  if (!isAuthenticated) {
    notification.info({
      message: 'You must be logged in to access this page. Please login to continue.',
      placement: 'topRight',
      duration: 5,
    });
    return <Navigate to={MAIN} replace />;
  }

  return children;
};

export default ProtectedRoute;
