import { FormattedTalentAcquisitionPDF } from 'types/pdf';
import { incomePDFPreviewFields } from 'types/pdf';
import getFullDate from 'utils/getInfo/getFullDate';
import getPDFNotification from 'utils/getInfo/getPDFNotification';

const convertBooleanAnswer = (value: boolean): string => (value ? 'Yes' : 'No');
const convertFee = (value: string | null): string => (value ? `$ ${value}` : 'No');

const formatTalentAcquisitionPDFPreviewData = (
  fields: incomePDFPreviewFields,
): FormattedTalentAcquisitionPDF => ({
  approvalActivity: [
    { label: `${fields['E-mail']}`, value: '', type: 'text' },
    {
      label: 'Notification',
      value: getPDFNotification(fields['E-mail'], fields['Company Name']),
      type: 'text',
    },
  ],
  companyInformation: [
    { label: 'Company Name', value: fields['Company Name'], type: 'text' },
    { label: 'Company Website', value: fields['Company Website'] || '', type: 'link' },
    { label: 'Company Address', value: fields['Company Address'] || '', type: 'text' },
    { label: 'Industry of Business', value: fields.Industry, type: 'rounded' },
    { label: 'Company Type', value: fields['Public or Private?'], type: 'rounded' },
    { label: 'Years in Business', value: fields['Years in Business'] || '', type: 'rounded' },
    { label: 'Point of Contact', value: fields['Point of Contact'], type: 'text' },
    { label: 'Position', value: fields['POC Position'] || '', type: 'text' },
    { label: 'E-mail', value: fields['E-mail'], type: 'link' },
    { label: 'Phone Number', value: fields['Phone Number'], type: 'text' },
    {
      label: 'Invoicing Contact',
      value: fields['Invoicing name'] || fields['Point of Contact'],
      type: 'text',
    },
    {
      label: 'Invoicing Email',
      value: fields['Invoicing email'] || fields['E-mail'],
      type: 'text',
    },
  ],
  jobRequirements: [
    { label: 'Job Title', value: fields['Job Title'], type: 'text' },
    { label: 'Location', value: fields['Full Location (Internal purposes only)'], type: 'text' },
    { label: 'Job Type', value: (fields['Job Type'] && fields['Job Type'].join(' | ')) || '', type: 'rounded' },
    { label: 'Work Schedule', value: fields['Work Schedule'], type: 'text' },
    { label: 'Number of Openings', value: fields['Number of Openings'], type: 'text' },
    {
      label: 'Job Description',
      value: fields['Job Description (EMPLOYER)'],
      type: 'text',
      fullWidth: true,
    },
    { label: 'Previous Experience & Qualifications', value: fields.Qualifications, type: 'text' },
    { label: 'Skillset Requirements', value: fields['Skill Requirement'], type: 'text' },
    { label: 'Drug Test Required', value: fields['Drug Test'], type: 'rounded' },
    { label: 'Background Check Required', value: fields['Back Check'], type: 'rounded' },
    { label: 'Language Requirements', value: fields['Language Requirements'] || '', type: 'text' },
    { label: 'Domestic or International Candidates?', value: fields['Domestic/Overseas'], type: 'rounded' },
  ],
  compensationBenefits: [
    { label: 'Salary Range', value: fields['Starting wage'], type: 'text' },
    { label: 'Benefits', value: fields.Benefits, type: 'rounded' },
  ],
  culturalFit: [
    { label: 'Cultural & Soft Skills', value: fields['Soft Skill'], type: 'text' },
  ],
  recruitmentProcess: [
    { label: 'Timelines & Mutual Expectations', value: fields['Timelines & Comments'] || '', type: 'text' },
    { label: 'Estimated Start Date', value: getFullDate(fields['Start date']), type: 'text' },
    { label: 'Number of Interviews', value: fields['# of Interviews'], type: 'rounded' },
  ],
  feeSchedule: [
    {
      label: 'Onboarding Fee',
      value: convertFee(fields['Onboarding Fee']),
      type: fields['Onboarding Fee'] ? 'text' : 'rounded',
    },
    {
      label: 'Monthly ACE Fee',
      value: convertFee(fields['Monthly ACE Fee']),
      type: fields['Monthly ACE Fee'] ? 'text' : 'rounded',
    },
    {
      label: 'Retention Fee',
      value: convertFee(fields['Retention Fee']),
      type: fields['Retention Fee'] ? 'text' : 'rounded',
    },
    {
      label: 'Direct Hire Fee',
      value: convertFee(fields['Direct Hire Fee']),
      type: fields['Direct Hire Fee'] ? 'text' : 'rounded',
    },
    {
      label: 'Other Fees',
      value: convertFee(fields['Other Fees']),
      type: fields['Other Fees'] ? 'text' : 'rounded',
    },
    { label: 'Timely Feedback Agreement', value: convertBooleanAnswer(fields.TFA), type: 'rounded' },
    { label: 'Submitted By', value: fields['Sales Person']?.name || '', type: 'text' },
  ],
});

export default formatTalentAcquisitionPDFPreviewData;
