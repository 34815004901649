import { memo, useEffect, useContext } from 'react';
import { Col, Row, Switch } from 'antd';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
  exportGeneratedCSS as collectCSS,
  isEnabled as isDarkReaderEnabled,
} from 'darkreader';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as LoginIcon } from 'assets/icons/login.svg';
import { ReactComponent as ProfileCircle } from 'assets/icons/profile-circle.svg';
import Logo from 'assets/images/svg/joblio-logo.svg';

import { toggleSidebar } from 'features/sidebar/sidebarSlice';
import { selectIsLoggedIn, selectUser, logout } from 'features/auth/authSlice';
import { MAIN } from 'constants/pathNames';
import { GTM_EVENTS } from 'constants/analytics';
import LoadingOverlay from 'components/LoadingOverlay';
import { useMobileDesign } from 'hooks/useMobileDesign';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { fbAnalytics } from 'utils/analytics';
import styles from './Header.module.scss';

const configDark = () => {
  enableDarkMode({
    brightness: 100,
    contrast: 90,
    sepia: 10,
  }, {
    ignoreImageAnalysis: ['darkreader-protected', 'svg', 'img'],
    ignoreInlineStyle: ['darkreader-protected', 'svg', 'img'],
  });
};

const DarkModeSwitch = memo(() => {
  const onDarkModeChange = async () => {
    const isEnabled = isDarkReaderEnabled();
    if (!isEnabled) {
      fbAnalytics(GTM_EVENTS.DARK_MODE);
      configDark();
      localStorage.setItem('darkMode', 'true');
      await collectCSS();
    } else {
      disableDarkMode();
      localStorage.setItem('darkMode', 'false');
    }
  };

  return (
    <div>
      <Switch defaultChecked onChange={onDarkModeChange} />
    </div>
  );
});

const UserControls = ({
  isDesktop, isAnonymous, dispatchLogout,
  intl, navigate, login, user,
}) => (
  isAnonymous ? (
    <div
      className={styles.ContainerIcon}
      onClick={login}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          login();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <LoginIcon />
      <span className={styles.linkText} data-qa="logInButton">
        {isDesktop && intl.formatMessage({ id: 'header.logIn' })}
      </span>
    </div>
  ) : (
    <>
      <div
        className={styles.ContainerIcon}
        // onClick={() => {
        //   navigate(USER_PROFILE);
        // }}
        role="button"
        tabIndex={0}
        style={{
          marginRight: 38,
        }}
      >
        <ProfileCircle />
        <span>{user.email}</span>
      </div>
      <div
        className={styles.ContainerIcon}
        onClick={dispatchLogout}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            dispatchLogout();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <LogoutIcon />
        <span className={styles.linkText} data-qa="logOutButton">
          {isDesktop && intl.formatMessage({ id: 'header.logOut' })}
        </span>
      </div>
    </>
  )
);

const AppHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectMain = () => navigate(MAIN);
  const dispatchLogout = () => dispatch(logout());
  const isAnonymous = !useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  const intl = useIntl();
  const isMobile = useMobileDesign();
  const { width: viewportWidth } = useWindowDimensions();
  const isDesktop = viewportWidth >= 1024;

  const login = () => {
    // fbAnalytics(GTM_EVENTS.LOGIN_LINK);
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode');
    if (isDarkMode === 'true') {
      configDark();
    } else {
      disableDarkMode();
    }
  }, []);

  return (
    <header className={styles.Header}>
      <div className={styles.container}>
        <Row justify="space-between" align="middle" wrap={false}>
          <Col className={styles.logoContainer}>
            <Link to={MAIN}>
              <img className={`${styles.JoblioLogo} fade-in`} src={Logo} alt="logo" data-qa="joinLogo" />
            </Link>
          </Col>
          <Col align="right">
            <div className={`${styles.HeaderPanel} fade-in`}>
              {!isMobile && (
                <div className={styles.darkModeSwitcher}>
                  <div className={styles.flagWrapper}>
                    <div className={styles.flag}>
                      <DarkModeSwitch />
                    </div>
                  </div>
                </div>
              )}
              <UserControls isDesktop={isDesktop} isAnonymous={isAnonymous} login={login} dispatchLogout={dispatchLogout} intl={intl} navigate={navigate} user={user} />
            </div>
          </Col>
        </Row>
      </div>
    </header>
  );
};

export default AppHeader;
