import { ReactElement, FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import Picker from 'components/Picker';
import { FormInstance } from 'antd/es/form';
import { useIntl } from 'react-intl';
import { useMobileDesign } from 'hooks/useMobileDesign';

interface SelectCustomProps {
  name: string;
  form: FormInstance;
  options: { value: string; label: string; code?: string }[];
  placeholderId?: string;
  labelId: string;
  optionRender?: (data: any) => ReactElement;
  labelRender?: (data: any) => ReactElement;
}

const SelectCustom: FC<SelectCustomProps> = ({
  name,
  form,
  options,
  placeholderId,
  labelId,
  optionRender,
  labelRender,
}) => {
  const intl = useIntl();
  const isMobile = useMobileDesign();
  const [currentValue, setCurrentValue] = useState<any>(form.getFieldValue(name));

  useEffect(() => {
    const fieldValue = form.getFieldValue(name);
    if (fieldValue !== currentValue) {
      setCurrentValue(fieldValue);
    }
  }, [form, name, currentValue]);

  const handleChange = (value: any): void => {
    form.setFieldsValue({ [name]: value });
    setCurrentValue(value);
  };

  return !isMobile ? (
    <Select
      options={options}
      value={currentValue}
      onChange={handleChange}
      optionRender={optionRender}
      labelRender={labelRender}
      placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : ''}
    />
  ) : (
    <Picker
      name={name}
      form={form}
      formatMessage={intl.formatMessage}
      onReject={() => {}}
      label={intl.formatMessage({ id: labelId })}
      labelRender={labelRender}
      placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : ''}
      columnDataChildren={(
        options.map(({ value, label }) => (
          <Select.Option
            key={value}
            value={value}
            title={label}
            selectTitle={label}
          >
            {optionRender ? optionRender({ value, label }) : label }
          </Select.Option>
        ))
      )}
    />
  );
};

export default SelectCustom;
