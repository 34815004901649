import React, { ReactElement, useEffect, useState } from 'react';
import {
  Layout,
  Input,
  Button,
  Row, Col,
  Avatar,
  List,
  Collapse,
  Card,
} from 'antd';
import {
  PhoneOutlined,
  SendOutlined,
  MessageOutlined,
  UserOutlined,
  PictureOutlined,
  FileOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { useMobileDesign } from '../../hooks/useMobileDesign';
import styles from './ChatPage.module.scss';

const { Sider, Content } = Layout;
const { Panel } = Collapse;

const password = 'admin123';

const permissonsAlertPromtWithPassword = (): boolean => {
  const passwordInput = prompt('Please enter the password to continue');
  if (passwordInput !== password) {
    alert('Incorrect password');
    return false;
  }
  return true;
};

const ChatApp = (): ReactElement => {
  const [collapsed, setCollapsed] = useState(false);
  const [currentChat, setCurrentChat] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [activeContact, setActiveContact] = useState(null);

  const isMobile = useMobileDesign();

  const contacts = [
    { name: 'John Doe', id: 1, email: 'test@joblio.co' },
    { name: 'Jane Smith', id: 2 },
    { name: 'William Johnson', id: 3 },
    { name: 'Linda Brown', id: 4 },
    { name: 'Michael Davis', id: 5 },
    { name: 'Patricia Wilson', id: 6 },
    { name: 'Robert Lee', id: 7 },
    { name: 'Barbara Moore', id: 8 },
    { name: 'David Taylor', id: 9, email: 'test@joblio222.co' },
    { name: 'Karen Anderson', id: 10 },
  ];

  const messages = {
    1: [{ text: 'Hi there!', sender: 'them' }, { text: 'How are you?', sender: 'me' }],
    2: [{ text: 'Hello!', sender: 'them' }, { text: 'What\'s up?', sender: 'me' }],
    3: [{ text: 'Good day!', sender: 'them' }, { text: 'How\'s everything?', sender: 'me' }],
    4: [{ text: 'Hi there!', sender: 'them' }, { text: 'How are you?', sender: 'me' }],
    5: [{ text: 'Hello!', sender: 'them' }, { text: 'What\'s up?', sender: 'me' }],
    6: [{ text: 'Good day!', sender: 'them' }, { text: 'How\'s everything?', sender: 'me' }],
    7: [{ text: 'Hi there!', sender: 'them' }, { text: 'How are you?', sender: 'me' }],
    8: [{ text: 'Hello!', sender: 'them' }, { text: 'What\'s up?', sender: 'me' }],
    9: [{ text: 'Good day!', sender: 'them' }, { text: 'How\'s everything?', sender: 'me' }],
    10: [{ text: 'Hi there!', sender: 'them' }, { text: 'How are you?', sender: 'me' }],
  };

  useEffect(() => {
    permissonsAlertPromtWithPassword();
  }, []);

  useEffect(() => {
    if (contacts.length > 0) {
      loadChat(contacts[0].id);
      // @ts-ignore
      setActiveContact(contacts[0]);
    }
  }, []);

  const loadChat = (contactId: number): void => {
    // @ts-ignore
    setCurrentChat(messages[contactId]);
    // @ts-ignore
    setActiveContact(contacts.find((contact) => contact.id === contactId));
  };

  const handleSendMessage = (): void => {
    if (inputValue.length === 0) return;
    // @ts-ignore
    setCurrentChat([...currentChat, { text: inputValue, sender: 'me' }]);
    setInputValue('');
  };

  const renderRightSidebar = (): ReactElement | null => {
    if (isMobile) return null;
    if (!activeContact) return null;

    // Example of a media gallery card
    const MediaGalleryCard = (
      <Card title="Media Shared" size="small" bordered={false}>
        <PictureOutlined />
        {' '}
        <FileOutlined />
        {' '}
        <LinkOutlined />
        {/* Implement the media gallery here */}
      </Card>
    );

    const StatisticsCard = (
      <Card title="Chat Insights" size="small" bordered={false}>
        <p>
          Messages Sent:
          {' '}
          {currentChat.length}
        </p>
        <p>
          Messages Received:
          {' '}
          {currentChat.length}
        </p>
      </Card>
    );

    return (
      <div>
        {MediaGalleryCard}
        <br />
        {StatisticsCard}
      </div>
    );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        theme="light"
        width={300}
        breakpoint="lg"
        onBreakpoint={(broken) => setCollapsed(broken)}
      >
        <Collapse defaultActiveKey={['1']} ghost>
          <Panel header="" key="1">
            <List
              itemLayout="horizontal"
              dataSource={contacts}
              style={{ overflowY: 'scroll', height: 'calc(100vh - 200px)' }}
              renderItem={(item: any) => (
                <List.Item
                  className={styles.contactItem}
                  actions={[<PhoneOutlined />,
                    <MessageOutlined onClick={() => loadChat(item.id)} />]}
                >
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={item.name}
                    description={item.email}
                  />
                </List.Item>
              )}
            />
          </Panel>
        </Collapse>
      </Sider>
      <Content style={{ padding: '0 24px', marginTop: 64 }}>
        <Row gutter={16}>
          <Col xs={24} md={18} lg={18}>
            <div style={{ height: 'calc(100vh - 240px)', overflowY: 'scroll', padding: '20px' }}>
              <List
                dataSource={currentChat}
                renderItem={(item: any) => (
                  <List.Item style={{ justifyContent: item.sender === 'me' ? 'flex-start' : 'flex-end' }}>
                    <List.Item.Meta
                      avatar={<Avatar icon={<UserOutlined />} />}
                      title={(
                        <p style={{
                          background: item.sender === 'me' ? '#daf8cb' : '#f3f3f3',
                          borderRadius: '20px',
                          padding: '10px 20px',
                        }}
                        >
                          {item.text}
                        </p>
                      )}
                    />
                  </List.Item>
                )}
              />
            </div>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col xs={20} sm={20} md={20} lg={20}>
                <Input
                  placeholder="Type your message here..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={handleSendMessage}
                />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4}>
                <Button type="primary" icon={<SendOutlined />} onClick={handleSendMessage}>
                  Send
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={6} lg={6}>
            {renderRightSidebar()}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ChatApp;
