import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface SidebarState {
  showSidebar: boolean;
  activeComponent: string;
}

const initialState: SidebarState = {
  showSidebar: false,
  activeComponent: '',
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
    setActiveComponent(state, action: PayloadAction<string>) {
      state.activeComponent = action.payload;
    },
  },
});

export const selectShowSidebar = (state: RootState): boolean =>
  state.sidebar.showSidebar;
export const selectActiveComponent = (state: RootState): string =>
  state.sidebar.activeComponent;

export const { toggleSidebar, setActiveComponent } = sidebarSlice.actions;

export default sidebarSlice.reducer;
