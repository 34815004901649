import {
  FC, useState,
  useEffect, FormEvent,
} from 'react';
import { Button, Form, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as ExclamationIcon } from 'assets/icons/exclamation-mark-circle.svg';

import styles from '../Authentication.module.scss';
import postApiData from 'utils/api/postApiData';

interface VerificationCodeProps {
  email: string;
}

const VerificationCode: FC<VerificationCodeProps> = ({ email }) => {
  const intl = useIntl();
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    if (email) {
      resendCode();
    }
  }, [email]);

  const resendCode = (): void => {
    postApiData('/auth/verification_code/send', { email })
      .then(() => {
        setIsTimerActive(true);
        const interval = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer <= 1) {
              clearInterval(interval);
              setIsTimerActive(false);
              return 120;
            }
            return prevTimer - 1;
          });
        }, 1000);
      });
  };

  const handleInput = (e: FormEvent<HTMLInputElement>): void => {
    const input = e.currentTarget;
    input.value = input.value.replace(/\D/g, '').slice(0, 6);
  };

  return (
    <>
      {isTimerActive && (
        <div className={styles.sendEmailNoteContainer}>
          <ExclamationIcon />
          <FormattedMessage id="auth.label.verificationCodeMessage" />
        </div>
      )}
      <Form.Item
        label={intl.formatMessage({ id: 'form.label.verificationCode' })}
        className={styles.verificationCode}
        name="verification"
        rules={[
          {
            required: true,
            message: 'Please input your verification code!',
          },
          {
            len: 6,
            message: 'Verification code must be exactly 6 digits long',
          },
        ]}
      >
        <Input
          onInput={handleInput}
          addonAfter={
            isTimerActive ? (
              <span>
                {timer}
                s
              </span>
            ) : (
              <Button onClick={resendCode}>Resend code</Button>
            )
          }
          placeholder="______"
        />
      </Form.Item>
    </>
  );
};

export default VerificationCode;
