const GDPR_SETTINGS = 'cnst';

const CODE_VALIDITY_SECONDS = 300;

const VALIDATION_CODE_LENGTH = 4;
const DEFAULT_PHONE_COUNTRY = 'US';

const SUPPORT_TELEGRAM = 't.me/joblio_co';
const FACEBOOK = 'https://www.facebook.com/JoblioGlobal/';
const LINKEDIN = 'https://www.linkedin.com/company/joblio/';
const YOUTUBE = 'https://www.youtube.com/c/Joblioglobal';
const ABOUT_LINK = 'https://joblio.co/looking-for-work/';
const LEGAL_BASE_URL = 'https://joblio.co/';
const PRIVACY_PATH = '/privacy-policy';
const TERMS_PATH = '/terms-of-use';
const COOKIE_PATH = '/cookie-policy';
const COPYRIGHT_YEAR = 2024;
const DATA_PRIVACY_PATH = '/notice-exercising-your-data-privacy-rights';

export {
  GDPR_SETTINGS,
  CODE_VALIDITY_SECONDS,
  VALIDATION_CODE_LENGTH,
  DEFAULT_PHONE_COUNTRY,
  SUPPORT_TELEGRAM,
  ABOUT_LINK,
  LEGAL_BASE_URL,
  PRIVACY_PATH,
  TERMS_PATH,
  COOKIE_PATH,
  COPYRIGHT_YEAR,
  DATA_PRIVACY_PATH,
  FACEBOOK,
  LINKEDIN,
  YOUTUBE,
};
