import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Row } from 'antd';
import AirtableService from 'services/airtable';
import createFilterQuery from 'utils/helpers/createFilterQuery';
import { JOBS } from 'constants/pathNames';
import { ReactComponent as Icon } from 'assets/icons/thank-you-check.svg';
import ExtraHeader from 'components/ExtraHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import JobsList from 'components/Job/JobsList';
import { AirtableResponse, Records } from 'types/airtable';
import styles from './QuestionnaireThankYouPage.module.scss';

const QuestionnaireThankYouPage = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobsList, setJobsList] = useState<Records[]>([]);
  const message = location.state?.thankYouMessage ?? 'screening.thankYou.default';
  const userIndustries = location.state?.userIndustries ?? [];

  const selectRandomRecords = (records: Records[], count: number): Records[] => {
    const shuffled = [...records].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  useEffect(() => {
    setLoading(true);
    if (userIndustries && userIndustries.length > 0) {
      const fetchJobs = async (): Promise<void> => {
        try {
          const response = await AirtableService.getRecords({
            tableName: 'Jobs',
            viewName: 'Join Posted Jobs',
            pageSize: 100,
            filterFormula: createFilterQuery(userIndustries, 'Industry'),
          });

          if (response && response.records && response.records.length > 0) {
            const randomRecords = selectRandomRecords(response.records, 3);
            setJobsList(randomRecords);
          } else {
            console.log('No records found or an empty response returned.');
          }
        } catch (error) {
          console.error('Failed to fetch records: ', error);
        } finally {
          setLoading(false);
        }
      };
      fetchJobs();
    } else {
      setLoading(false);
    }
  }, [userIndustries]);

  return (
    loading ? <LoadingIndicator /> : (
      <>
        <ExtraHeader title="screening.header.title" />
        <div className="container">
          <div className={styles.messageContainer}>
            <Icon />
            <FormattedMessage id={message} />
          </div>
          {jobsList.length > 0 && (
            <div className={styles.suggestedJobs}>
              <Row gutter={[24, 24]} className={styles.jobsContainer}>
                <JobsList jobList={jobsList} />
              </Row>
              <p className="text-center">
                <Button
                  type="primary"
                  className={`btn btn-primary ${styles.button}`}
                  onClick={() => navigate(JOBS)}
                >
                  <FormattedMessage id="job.browseMoreJobs" />
                </Button>
              </p>
            </div>
          )}
        </div>
      </>
    ));
};

export default QuestionnaireThankYouPage;
