import { ReactElement, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { notification } from 'antd';
import { ENDPOINT } from 'constants/api';
import postTalentAcquisitionData from 'services/post/postTalentAcquisitionData';
import getApiData from 'utils/api/getApiData';
import validateSignature from 'utils/helpers/validateSignature';
import resizeSignature from 'utils/helpers/resizeSignature';
import formatTalentAcquisitionPDFPreviewData from 'utils/formatting/formatTalentAcquisitionPDFPreviewData';
import formatTalentAcquisitionPDFPreviewExtraData from 'utils/formatting/formatTalentAcquisitionPDFPreviewExtraData';
import { FormattedTalentAcquisitionPDF, PDFPreviewExtraData } from 'types/pdf';
import TalentAcquisitionChangeStatus from 'components/TalentAcquisitionChangeStatus';
import LoadingIndicator from 'components/LoadingIndicator';

const TalentAcquisitionChangeStatusPage = (): ReactElement => {
  const { clientId, jobId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [contractSigned, setContractSigned] = useState<boolean>();
  const [previewExtraData, setPreviewExtraData] = useState<PDFPreviewExtraData>();
  const [previewPDFData, setPreviewPDFData] = useState<FormattedTalentAcquisitionPDF>();

  const url = `${ENDPOINT.TAO}/${clientId}/${jobId}`;

  useEffect(() => {
    if (clientId && jobId) {
      setLoading(true);
      getApiData(url)
        .then((result) => {
          setContractSigned(result['TAO Signed']);
          setPreviewExtraData(formatTalentAcquisitionPDFPreviewExtraData(result));
          setPreviewPDFData(formatTalentAcquisitionPDFPreviewData(result));
        })
        .finally(() => setLoading(false));
    }
  }, [clientId, jobId]);

  const handleSubmit = async (signatureRef: SignatureCanvas | null): Promise<void> => {
    const canvas = signatureRef && signatureRef.getCanvas();

    if (!validateSignature(signatureRef) || !canvas) {
      notification.error({
        message: 'Signature validation error',
        description: 'Please make sure your signature is not blank and is of sufficient size',
        placement: 'topRight',
      });
      return;
    }

    const resizedSignature = await resizeSignature(canvas);

    if (!resizedSignature) {
      console.error('Error resizing signature');
      return;
    }

    try {
      setLoadingButton(true);
      const base64ToBlob = await fetch(resizedSignature).then((res) => res.blob());

      const formData = new FormData();
      formData.append('signature', base64ToBlob, 'signature.png');

      await postTalentAcquisitionData(`${url}/accept`, formData);
      setContractSigned(true);
    } catch (error) {
      setLoadingButton(false);
      notification.error({
        message: `Error submitting data: ${error}`,
        placement: 'topRight',
      });
      return;
    } finally {
      setLoadingButton(false);
    }
  };

  const renderContent = (): ReactElement => {
    if (loading || !previewPDFData || !previewExtraData) return <LoadingIndicator />;

    return (
      <TalentAcquisitionChangeStatus
        handleSubmit={handleSubmit}
        loadingButton={loadingButton}
        data={previewPDFData}
        extraData={previewExtraData}
      />
    );
  };

  const renderContractSignedMessage = (
    <div className="container talent-acquisition-container">
      <h3 style={{ textAlign: 'center' }}>
        <FormattedMessage
          id="label.tao.contractSignedMessage"
        />
      </h3>
    </div>
  );

  return contractSigned ? renderContractSignedMessage : renderContent();
};

export default TalentAcquisitionChangeStatusPage;
