import responseParser from 'utils/api/responseParser';
// @ts-ignore
// import * as Sentry from 'https://cdn.jsdelivr.net/npm/@sentry/browser@7.42.0/+esm';

interface ErrorResponse {
  message: string;
  status: number;
  data?: any;
}

const errorParser = async (error: any): Promise<never> => {
  if (!navigator.onLine) {
    throw new Error('No internet connection');
  }

  try {
    const response = error.response ? await responseParser(error.response) : null;
    const errorDetails: ErrorResponse = {
      message: error.message || 'Unknown error',
      status: error.response ? error.response.status : 500,
      data: response,
    };

    // Sentry.captureException(errorDetails);
    console.error(errorDetails);
    throw errorDetails;
  } catch (parseError) {
    // Sentry.captureException(parseError);
    console.error(parseError);
    throw parseError;
  }
};

export default errorParser;
