import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, notification } from 'antd';
import { TALENT_ACQUISITION_THANK_YOU, NOT_FOUND } from 'constants/pathNames';
import { ENDPOINT } from 'constants/api';
import AirtableService from 'services/airtable';
import postTalentAcquisitionData from 'services/post/postTalentAcquisitionData';
import { FormatterClient, TAOFields } from 'types/airtable';
import { formatClientFields, formatFormData } from 'utils/formatting/formatTalentAcquisitionData';
import LoadingIndicator from 'components/LoadingIndicator';
import TalentAcquisitionForm from 'components/TalentAcquisitionForm';
import TalentAcquisitionPDF from 'components/TalentAcquisitionPDF';
import { pdf } from '@react-pdf/renderer';

const TalentAcquisitionPage = (): ReactElement => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [clientInitialValues, setClientInitialValues] = useState<FormatterClient>();

  useEffect(() => {
    if (id) {
      setLoading(true);

      AirtableService.getRecord({ tableName: 'Sales & Clients', recordId: id })
        .then(({ fields }) => {
          setClientInitialValues(formatClientFields(fields));
        })
        .catch(() => navigate(NOT_FOUND))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const generatePdfDocument = async (fields: TAOFields): Promise<Blob> =>
    pdf(<TalentAcquisitionPDF fields={fields} />).toBlob();

  const handleSubmit = async (): Promise<void> => {
    try {
      setLoadingButton(true);

      const formFields = {
        ...clientInitialValues,
        ...form.getFieldsValue(),
      };

      const file = await generatePdfDocument(formFields);

      const timestamp = new Date().getTime();
      const clientID = formFields['Client ID'];
      const fileName = `${clientID}_${timestamp}.pdf`;

      const formData = new FormData();
      formData.append('tao_document', file, fileName);
      formData.append('tao_fields', new Blob([JSON.stringify(formatFormData(formFields))], { type: 'application/json' }));

      await postTalentAcquisitionData(ENDPOINT.TAO, formData);

      navigate(TALENT_ACQUISITION_THANK_YOU);
    } catch (error) {
      notification.error({
        message: `Error submitting data: ${error}`,
        placement: 'topRight',
      });
      setLoadingButton(false);
    }
  };

  const renderContent = (): ReactElement => {
    if (loading || !clientInitialValues) return <LoadingIndicator />;

    return (
      <div className="container talent-acquisition-container">
        <TalentAcquisitionForm
          form={form}
          clientInitialValues={clientInitialValues}
          submitForm={handleSubmit}
          loading={loadingButton}
        />
      </div>
    );
  };

  return renderContent();
};

export default TalentAcquisitionPage;
