import { ReactElement, useState, FC } from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import cs from 'classnames';
import SignatureCanvas from 'react-signature-canvas';

import { PDFTitles } from 'constants/enums';
import { FormattedTalentAcquisitionPDF, TableRowProps, PDFPreviewExtraData } from 'types/pdf';
import TableRow from './TableRow';
import SignatureModal from './SignatureModal';

import styles from './TalentAcquisitionChangeStatus.module.scss';
import stylesRow from './TableRow/TableRow.module.scss';

interface TalentAcquisitionChangeStatusProps {
  handleSubmit: (signature: SignatureCanvas | null) => any;
  loadingButton: boolean;
  extraData: PDFPreviewExtraData;
  data: FormattedTalentAcquisitionPDF;
}

const TalentAcquisitionChangeStatus: FC<TalentAcquisitionChangeStatusProps> = ({
  handleSubmit,
  loadingButton,
  extraData,
  data,
}): ReactElement => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const {
    companyInformation, jobRequirements, compensationBenefits,
    culturalFit, recruitmentProcess, feeSchedule,
  } = data;

  const showModal = (): void =>
    setOpen(true);

  const handleCancel = (): void =>
    setOpen(false);

  const renderHeader = (): ReactElement => (
    <div className={styles.header}>
      <div className={styles.headerDate}>{extraData.createdDate}</div>
      <h3>Talent Acquisition Order</h3>
    </div>
  );

  const renderTable = (title: string, elements: TableRowProps[]): ReactElement => (
    <div className={styles.table}>
      <div className={styles.tableTitle}>{title}</div>
      {elements.map((item: TableRowProps, order) => (
        <TableRow
          key={item.label}
          value={item.value}
          label={item.label}
          order={order}
          type={item.type}
          fullWidth={item?.fullWidth}
        />
      ))}
    </div>
  );

  const renderApprovalStatus = (): ReactElement => (
    <div className={styles.table}>
      <div className={styles.tableTitle}>{PDFTitles.ApprovalActivityHistory}</div>
      <div className={cs(stylesRow.row, stylesRow.rowGray)}>
        <div className={stylesRow.column}>
          <div className={stylesRow.label}>
            <p>{extraData.email}</p>
            {extraData.createdDate}
          </div>
        </div>
        <div className={stylesRow.column}>
          <Button
            type="primary"
            className={cs(styles.button, 'btn btn-primary')}
            onClick={showModal}
          >
            {intl.formatMessage({ id: 'label.tao.signContractLabel' })}
          </Button>
        </div>
      </div>
      <div className={stylesRow.row}>
        <div className={stylesRow.column}>
          <div className={stylesRow.label}>
            <p>Notification</p>
            {extraData.createdDate}
          </div>
        </div>
        <div className={cs(stylesRow.column, stylesRow.text)}>{extraData.notification}</div>
      </div>
    </div>
  );

  return (
    <div className="roundedContainer">
      <div className={styles.content}>
        {renderHeader()}
        {renderApprovalStatus()}
        {renderTable(PDFTitles.CompanyInformation, companyInformation)}
        {renderTable(PDFTitles.JobRequirementsDescription, jobRequirements)}
        {renderTable(PDFTitles.CompensationBenefits, compensationBenefits)}
        {renderTable(PDFTitles.CulturalFit, culturalFit)}
        {renderTable(PDFTitles.RecruitmentProcess, recruitmentProcess)}
        {renderTable(PDFTitles.FeeSchedule, feeSchedule)}
      </div>
      <SignatureModal
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        loadingButton={loadingButton}
        open={open}
      />
    </div>
  );
};

export default TalentAcquisitionChangeStatus;
