import {
  FC, useRef, useState,
  useEffect, ReactElement,
} from 'react';
import { CheckList, Popup } from 'antd-mobile';
import { Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import styles from './CheckList.module.scss';

type CheckListValue = string | number;

interface ColumnDataChild {
  props: {
    children: any;
  };
  key: string;
}

interface CheckListMobileProps {
  items: { value: string; label: string; code?: string }[];
  name: string;
  label?: string;
  placeholder?: string;
  columnDataChildren: ColumnDataChild[];
  form: FormInstance;
  renderOption?: (data: any) => ReactElement;
  labelRender?: (data: any) => ReactElement;
  onChange?: (values: string[]) => void;
}

const CheckListMobile: FC<CheckListMobileProps> = ({
  items,
  name,
  label = '',
  placeholder = '',
  columnDataChildren,
  form,
  renderOption,
  onChange,
}) => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState<CheckListValue[]>([]);
  const [value, setValue] = useState<CheckListValue[]>([]);

  const selectedRef = useRef(null);

  const formatValues = (values: CheckListValue[]): string[] => values.map(String);

  const findKeyForValue = (values: CheckListValue[]): (string | null)[] => {
    return values.map((val) => {
      const keyComponent = columnDataChildren.find((child) => child.props.children === val);
      return keyComponent ? keyComponent.key : null;
    });
  };

  const handleConfirm = (): void => {
    setVisible(false);
    if (onChange) {
      onChange(value as string[]);
    }
  };

  const handleCancel = (): void => {
    setVisible(false);
    setSelected([]);
    setValue([]);
    form.setFieldsValue({ [name]: [] });
    if (onChange) {
      onChange([]);
    }
  };

  const handleChange = (v: CheckListValue[]): void => {
    const formattedValues = formatValues(v);
    setValue(formattedValues);
    setSelected(formattedValues);
    if (name) {
      const keys = findKeyForValue(formattedValues).filter((key): key is string => key !== null);
      form.setFieldsValue({ [name]: keys });
      if (onChange) {
        onChange(keys);
      }
    }
  };

  useEffect(() => {
    const fieldValue = form.getFieldValue(name);
    if (fieldValue && fieldValue.length !== selected.length) {
      const selectedOptions = fieldValue.map((key: string) => {
        const option = items.find((item) => item.value === key);
        return option ? option.label : key;
      });
      setSelected(selectedOptions);
      setValue(selectedOptions);
    }
  }, [form, name, items, selected.length]);

  return (
    <>
      <Select
        open={!visible}
        onClick={() => setVisible(true)}
        ref={selectedRef}
        value={value}
        mode="multiple"
        showSearch={false}
        placeholder={placeholder}
        maxTagCount="responsive"
        dropdownStyle={{ display: 'none' }}
        dropdownRender={() => <span />}
      >
        {value.map((val) => {
          const option = items.find((item) => item.value === val);
          return (
            <Select.Option key={val} value={val}>
              {renderOption ? renderOption(option) : option?.label}
            </Select.Option>
          );
        })}
      </Select>
      <Popup
        className='adm-picker-popup'
        visible={visible}
        onMaskClick={handleCancel}
        destroyOnClose
      >
        <div className={`adm-picker ${styles.checkListContainer}`}>
          <div className="adm-picker-header">
            <a role="button" onClick={handleCancel} className="adm-picker-header-button">Cancel</a>
            <div className="adm-picker-header-title">{label}</div>
            <a role="button" onClick={handleConfirm} className="adm-picker-header-button">Confirm</a>
          </div>
          <CheckList
            multiple
            className={styles.myCheckList}
            defaultValue={selected}
            onChange={handleChange}
          >
            {items.map((item) => (
              <CheckList.Item key={item.value} value={item.value}>
                {renderOption ? renderOption(item) : item.label}
              </CheckList.Item>
            ))}
          </CheckList>
        </div>
      </Popup>
    </>
  );
};

export default CheckListMobile;
