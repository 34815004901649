const getDateCustomFormat = (date: string): string | null => {
  if (!date) {
    return null;
  }

  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  return `${(month < 10 ? '0' : '') + month}.${(day < 10 ? '0' : '') + day}.${year}`;
};

export default getDateCustomFormat;
