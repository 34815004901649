import { Navigate, useParams } from 'react-router-dom';
import { APPLY_ENROLL } from '../../constants/pathNames';
import { ReactElement } from 'react';

const ApplyInvitePage = (): ReactElement => {
  const { id } = useParams();

  if (id) {
    localStorage.setItem('referralCode', id);
  } else {
    localStorage.removeItem('referralCode');
  }

  return (
    <Navigate to={{ pathname: APPLY_ENROLL }} />
  );
};

export default ApplyInvitePage;
