import { Button } from 'antd';
import { ReactComponent as EmptyImg } from 'assets/images/svg/empty-list.svg';
import { FormattedMessage } from 'react-intl';
import { func } from 'prop-types';
import styles from './EmptyList.module.scss';
import { ReactElement } from 'react';

const EmptyList = ({ setIsModalVisible }:
 {setIsModalVisible: (s: boolean) => void}): ReactElement => {
  const showModal = (): void => {
    setIsModalVisible(true);
  };

  return (
    <section className={styles.EmptyList}>
      <div className="container container-small">
        <h2>
          <FormattedMessage id="empty_list.title" />
        </h2>
        <p>
          <FormattedMessage id="empty_list.text" />
        </p>
        <div className={styles.Image}>
          <EmptyImg />
        </div>
        <Button type="primary" className="btn btn-primary" onClick={showModal}>
          <FormattedMessage id="button.refine_filters" />
        </Button>
      </div>
    </section>
  );
};

EmptyList.propTypes = {
  setIsModalVisible: func.isRequired,
};
export default EmptyList;
