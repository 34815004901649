import Flags from 'country-flag-icons/react/3x2';
import { bool, string } from 'prop-types';
import styles from './JobCountry.module.scss';

export const JobCountry = ({ countryCode, countryName, hideDescription }) => {
  if (!countryCode) return null;

  const FlagComponent = countryCode && Flags[countryCode.toUpperCase()];

  return (
    <div
      className={styles.CountryWrapper}
      data-qa={hideDescription
        ? 'phoneNumberFlagRegistrationInputField'
        : 'phoneNumberFlagsWindowRegistrationInputField'}
    >
      {/* <div className={styles.FlagWrapper} data-qa="jobCardCountryFlag"> */}
      {/*  {FlagComponent && <FlagComponent />} */}
      {/* </div> */}
      {!hideDescription && (
      <div className={styles.CountryNameWrapper} data-qa="jobCardCountryName">
        {countryName}
      </div>
      ) }
    </div>
  );
};

JobCountry.propTypes = {
  countryCode: string.isRequired,
  countryName: string.isRequired,
  hideDescription: bool,
};

JobCountry.defaultProps = {
  hideDescription: false,
};

export default JobCountry;
