import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import cs from 'classnames';
import { FormattedMessage } from 'react-intl';

import { MAIN } from 'constants/pathNames';
import styles from './IntakeThankYouPage.module.scss';

const IntakeThankYouPage = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <div className={cs('container', styles.thankYouMessageWrapper)}>
      <h2 className="title"><FormattedMessage id="intake.thankYouMessage" /></h2>
      <Button
        type="primary"
        className="btn btn-primary"
        onClick={(): void => navigate(MAIN)}
      >
        <FormattedMessage id="button.go_to_main_page" />
      </Button>
    </div>
  );
};

export default IntakeThankYouPage;
