import { ReactElement } from 'react';
import Iframe from '../../components/Iframe';

const AdamoPage = (): ReactElement => (
  <Iframe
    title="Adamo"
    src="https://airtable.com/embed/apppdYf3a0SmbybI9/shrqxjRAuWJKNkFvW?backgroundColor=pinkDusty"
  />
);

export default AdamoPage;
