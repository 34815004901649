import { FC, memo, useMemo } from 'react';
import * as Flags from 'country-flag-icons/react/3x2';
import classnames from 'classnames';
import { flagCodes } from 'constants/flagCodes';
import countries from 'data/containers/countriesExtended';
import * as styles from './index.module.scss';

type FlagsType = {
  [key: string]: FC<any>;
};

interface CountryPreviewProps {
  className?: string;
  countryCode?: string | null;
  countryName?: string | null;
  isCountryCodeLabel?: boolean;
  iconOnly?: boolean;
}

const getCountryCodeByName = (name: string | null): string | null => {
  const country = countries.find(c => c.label === name || c.value === name);
  return country ? country.code : null;
};

const getCountryNameByCode = (code: string | null): string | null => {
  const country = countries.find(c => c.code === code);
  return country ? country.label : null;
};

const CountryPreview: FC<CountryPreviewProps> = ({
  className = '',
  countryCode,
  countryName = '',
  isCountryCodeLabel = false,
  iconOnly = false,
  ...rest
}) => {

  const displayedCountryCode = countryCode ? countryCode.toUpperCase() : getCountryCodeByName(countryName);

  const displayedCountryName = countryName || getCountryNameByCode(displayedCountryCode);

  if (!displayedCountryCode) return null;

  const text = isCountryCodeLabel ? displayedCountryCode : displayedCountryName;

  const FlagIcon = (Flags as FlagsType)[displayedCountryCode];

  const icon = useMemo(
    () =>
      (flagCodes.includes(displayedCountryCode) && FlagIcon
        ? <FlagIcon className={styles.imgIcon} {...rest} /> : null),
    [displayedCountryCode, rest],
  );

  if (iconOnly) return icon;

  return (
    <div
      data-qa="countyPreview"
      data-qa-value={displayedCountryCode}
      className={classnames(styles.countryPreview, { [className]: !!className })}
      title={displayedCountryName || undefined}
    >
      {icon}
      {text}
    </div>
  );
};

export default memo(CountryPreview);
