import { ReactElement, useState } from 'react';
import { notification, Form } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { INTAKE_THANK_YOU } from 'constants/pathNames';
import AirtableService from 'services/airtable';

import { updateUser, selectUser } from 'features/auth/authSlice';
import IntakeForm from 'components/IntakeForm';
import { post } from 'utils/api/api';
import { fbAnalytics } from 'utils/analytics';
import { GTM_EVENTS } from 'constants/analytics';
import styles from './IntakePage.module.scss';

const IntakePage = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const selectedJobId = location.state?.jobId;
  const airtableUserId = useSelector(selectUser)?.airtableUserId;
  const userEmail = useSelector(selectUser)?.email;

  const handleSubmitForm = async (): Promise<void> => {
    setLoadingButton(true);
    try {
      const formFields = form.getFieldsValue();
      const airtableResponse = await AirtableService.createRecord({
        typecast: true,
        tableName: 'Jobseekers',
        viewName: 'All candidates',
        fields: { ...formFields, 'Source Platform': 'join.joblio intake' },
      });

      dispatch(updateUser({ airtableUserId: airtableResponse.id }));

      fbAnalytics(GTM_EVENTS.INTAKE_FORM, {
        jobTitle: form.getFieldValue('Looking for a job in the following areas'),
        jobDepartment: form.getFieldValue('Profession'),
        jobLocation: form.getFieldValue('Currently Residing'),
        jobCountry: form.getFieldValue('Citizenship'),
      });

      if (selectedJobId && airtableUserId) {
        const applicationResponse = await post(`/users/${airtableUserId}/applications`, { job_id: selectedJobId });

        notification.success({
          message: 'You\'ve successfully applied for a job',
          placement: 'topRight',
          duration: 5,
        });
        navigate(
          `/questionnaire/${selectedJobId}/${airtableUserId}/${applicationResponse.applicationRecordId}`,
          { replace: true },
        );
      } else {
        navigate(INTAKE_THANK_YOU);
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Failed to process intake form',
        description: 'Please try again later',
        placement: 'topRight',
        duration: 5,
      });
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <div className="roundedContainer">
      <div className={styles.content}>
        <h2 className="title"><FormattedMessage id="intake.contentTitle" /></h2>
        <p className={styles.textMargin}><FormattedMessage id="intake.contentText" /></p>
        <IntakeForm
          form={form}
          submitForm={handleSubmitForm}
          loading={loadingButton}
          initialValues={{ Email: userEmail }}
        />
      </div>
    </div>
  );
};

export default IntakePage;
