const languages = [
  'Arabic – Advanced',
  'Arabic – Beginner',
  'Arabic – Intermediate',
  'Arabic – Native',
  'Arabic – Pre-Intermediate',
  'Armenian – Advanced',
  'Armenian – Beginner',
  'Armenian – Intermediate',
  'Armenian – Native',
  'Armenian – Pre-Intermediate',
  'Azerbaijani – Advanced',
  'Azerbaijani – Beginner',
  'Azerbaijani – Intermediate',
  'Azerbaijani – Native',
  'Bulgarian – Advanced',
  'Bulgarian – Beginner',
  'Bulgarian – Intermediate',
  'Bulgarian – Native',
  'Bulgarian – Pre-Intermediate',
  'Burmese – Native',
  'Croatian – Beginner',
  'Czech – Advanced',
  'Czech – Intermediate',
  'Czech – Native',
  'Czech – Pre-Intermediate',
  'Danish – Beginner',
  'Danish – Native',
  'English – Advanced',
  'English – Beginner',
  'English – Fluent',
  'English – Intermediate',
  'English – Native',
  'English – Pre-Intermediate',
  'English – Upper Intermediate',
  'Farsi – Advanced',
  'Farsi – Beginner',
  'Farsi – Intermediate',
  'Farsi – Native',
  'Farsi – Pre-Intermediate',
  'French – Advanced',
  'French – Beginner',
  'French – Intermediate',
  'French – Native',
  'French – Pre-Intermediate',
  'Fula – Native',
  'Georgian – Native',
  'German – Advanced',
  'German – Beginner',
  'German – Intermediate',
  'German – Native',
  'German – Pre-Intermediate',
  'Greek – Beginner',
  'Greek – Native',
  'Haitian – Native',
  'Hebrew – Beginner',
  'Hebrew – Native',
  'Hungarian – Beginner',
  'Hungarian – Native',
  'Indonesian – Native',
  'Italian – Advanced',
  'Italian – Beginner',
  'Italian – Native',
  'Italian – Pre-Intermediate',
  'Japanese – Beginner',
  'Japanese – Native',
  'Kazakh – Native',
  'Kinyarwanda – Advanced',
  'Kinyarwanda – Native',
  'Kinyarwanda – Pre-Intermediate',
  'Korean – Beginner',
  'Lingala – Native',
  'Malay – Beginner',
  'Persian – Beginner',
  'Persian – Native',
  'Polish – Advanced',
  'Polish – Beginner',
  'Polish – Intermediate',
  'Polish – Native',
  'Portuguese – Beginner',
  'Portuguese – Native',
  'Portuguese – Pre-Intermediate',
  'Pushto – Advanced',
  'Pushto – Native',
  'Romanian – Advanced',
  'Romanian – Intermediate',
  'Romanian – Native',
  'Russian – Advanced',
  'Russian – Beginner',
  'Russian – Intermediate',
  'Russian – Native',
  'Russian – Pre-Intermediate',
  'Slovakian – Beginner',
  'Slovakian – Native',
  'Spanish – Advanced',
  'Spanish – Intermediate',
  'Spanish – Native',
  'Swahili – Advanced',
  'Swahili – Intermediate',
  'Swahili – Native',
  'Swahili – Pre-Intermediate',
  'Tigrinya – Intermediate',
  'Tigrinya – Native',
  'Turkish – Advanced',
  'Turkish – Intermediate',
  'Turkish – Native',
  'Turkish – Pre-Intermediate',
  'Ukrainian – Advanced',
  'Ukrainian – Beginner',
  'Ukrainian – Intermediate',
  'Ukrainian – Native',
  'Ukrainian – Pre-Intermediate',
];

export default languages;
