import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { AMBASSADOR_JOBS, JOBS } from 'constants/pathNames';

import styles from './FindBlock.module.scss';

const FindBlock = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={styles.FindBlock} data-qa="findJobsBlock">
      <Row align="middle">
        <Col xs={24} lg={16}>
          <p className={styles.FindBlockText}>
            <FormattedMessage id="find_job.text" />
          </p>
        </Col>
        <Col xs={24} lg={8} className="text-right-lg">
          <div className={styles.ButtonHolder}>
            <Button
              type="default"
              className="btn btn-default"
              block
              data-qa="findJobsButton"
              onClick={() => {
                if (pathname === AMBASSADOR_JOBS) {
                  navigate(AMBASSADOR_JOBS);
                  return;
                }
                navigate(JOBS);
              }}
            >
              <FormattedMessage id="find_job.find" />
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FindBlock;
