import { ReactElement, useState, FC } from 'react';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import type { UploadFile } from 'antd';
import { Upload } from 'antd';
import { FormattedMessage } from 'react-intl';

interface UploadResumeProps {
  onChangeResume: (file: UploadFile | null) => void;
}

const UploadResume: FC<UploadResumeProps> = ({ onChangeResume }): ReactElement => {
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleBeforeUpload = (file: UploadFile): boolean => {
    setFileList([file]);
    onChangeResume(file);
    return false;
  };

  const handleRemove = (): void => {
    setFileList([]);
    onChangeResume(null);
  };

  return (
    <div className="upload-button-container">
      <Dragger
        fileList={fileList}
        beforeUpload={handleBeforeUpload}
        accept=".pdf,.doc,.docx"
        maxCount={1}
        onRemove={handleRemove}
      >
        <UploadIcon />
        <span>
          <FormattedMessage id="form.control.label.uploadFile" />
        </span>
      </Dragger>
    </div>
  );
};

export default UploadResume;
