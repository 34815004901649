const jobAreas = [
  'Food and Beverage',
  'Logistics & Warehousing',
  'Manufacturing',
  'Retail',
  'Construction',
  'Automotive',
  'Hospitality',
  'Finance / Bookkeeping',
  'Other',
  'Healthcare',
  'Information Technology',
];

export default jobAreas;
