import * as marked from 'marked';

const formatMarkdown = (markdown?: string): string => {
  if (!markdown) return '';

  const pattern = /\*\*[^*]+\*\*/gm;
  let formatted = markdown.replace(pattern, (match) => {
    if (match.startsWith('** ')) {
      return match.replace('** ', ' **');
    }
    if (match.endsWith(' **')) {
      return `${match.slice(0, -3)} **`;
    }
    return match;
  });

  // Replace new lines with <br/> and <br/><br/>
  formatted = formatted.split('\n\n').join('<br/><br/>');
  formatted = formatted.split('\n').join('<br/>');

  // Convert markdown to HTML using marked
  return marked.parse(formatted);
};

export default formatMarkdown;
