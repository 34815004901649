import { ReactElement } from 'react';
import {
  Page, Text, View, Font, Document, StyleSheet,
} from '@react-pdf/renderer';
import Header from './Header';
import TableRow from './TableRow';
import formatTalentAcquisitionPDFData from 'utils/formatting/formatTalentAcquisitionPDFData';
import RubikMedium from 'assets/fonts/rubik-medium.ttf';
import RubikRegular from 'assets/fonts/rubik-regular.ttf';
import { PDFTitles } from 'constants/enums';
import { TAOFields } from 'types/airtable';
import { TableRowProps } from 'types/pdf';

interface TalentAcquisitionPDFProps {
  fields: TAOFields;
}

const TalentAcquisitionPDF = ({ fields }: TalentAcquisitionPDFProps): ReactElement => {
  const {
    companyInformation, jobRequirements, compensationBenefits,
    culturalFit, recruitmentProcess, feeSchedule, approvalActivity,
  } = formatTalentAcquisitionPDFData(fields);

  const renderTable = (title: string, elements: TableRowProps[]): ReactElement => (
    <View style={styles.table} wrap>
      <Text style={styles.tableTitle}>{title}</Text>
      {elements.map((item: TableRowProps, order) => (
        <TableRow
          key={item.label}
          value={item.value}
          label={item.label}
          order={order}
          type={item.type}
          fullWidth={item?.fullWidth}
        />
      ))}
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        {renderTable(PDFTitles.ApprovalActivityHistory, approvalActivity)}
        {renderTable(PDFTitles.CompanyInformation, companyInformation)}
        {renderTable(PDFTitles.JobRequirementsDescription, jobRequirements)}
        {renderTable(PDFTitles.CompensationBenefits, compensationBenefits)}
        {renderTable(PDFTitles.CulturalFit, culturalFit)}
        {renderTable(PDFTitles.RecruitmentProcess, recruitmentProcess)}
        {renderTable(PDFTitles.FeeSchedule, feeSchedule)}
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Rubik',
  fonts: [
    {
      src: RubikRegular,
    },
    {
      src: RubikMedium,
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    backgroundColor: '#FFFFFF',
    fontFamily: 'Rubik',
  },
  table: {
    marginHorizontal: 48,
    marginBottom: 36,
    borderBottom: '1px solid #E7E8EA',
  },
  tableTitle: {
    marginBottom: 12,
    fontSize: 16,
    lineHeight: 1.25,
    textTransform: 'uppercase',
    color: '#222222',
    fontWeight: 'bold',
  },
});

export default TalentAcquisitionPDF;
