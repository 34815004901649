const MIN_PASSWORD_LENGTH = 8;
const VALIDATION_CODE_STATES = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  NOT_SENT: 'NOT_SENT',
};

export {
  MIN_PASSWORD_LENGTH,
  VALIDATION_CODE_STATES,
};
