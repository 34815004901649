import {
  COOKIE_PATH, DATA_PRIVACY_PATH, LEGAL_BASE_URL, PRIVACY_PATH, TERMS_PATH,
} from 'constants/application';

const getLegalDocsLinks = (locale: string): Record<string, string> => {
  const lang = locale.split('-')[0];

  const urlPrivacy = LEGAL_BASE_URL + lang + PRIVACY_PATH;
  const urlTerms = LEGAL_BASE_URL + lang + TERMS_PATH;
  const urlCookiePolicy = LEGAL_BASE_URL + lang + COOKIE_PATH;
  const urlDataPrivacyRights = LEGAL_BASE_URL + lang + DATA_PRIVACY_PATH;

  return {
    urlPrivacy, urlTerms, urlCookiePolicy, urlDataPrivacyRights,
  };
};

export default getLegalDocsLinks;
