import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { AMBASSADOR_THANK_YOU, INTAKE } from 'constants/pathNames';
import AirtableService from 'services/airtable';
import styles from './AmbassadorIntakePage.module.scss';
import LoadingIndicator from 'components/LoadingIndicator';
import IntakeForm from 'components/IntakeForm';
import Logo from 'assets/images/svg/joblio-logo-color.svg';
import { Form } from 'antd';
import { fbAnalytics } from 'utils/analytics';
import { GTM_EVENTS } from 'constants/analytics';

const IntakePage = (): ReactElement => {
  const navigate = useNavigate();
  const { id: ambassadorId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [ambassadorName, setAmbassadorName] = useState('');

  const extraFields = {
    Ambassador: ambassadorName,
    'Source Platform': 'join.joblio ambassador',
  };

  const createRecordParams = {
    typecast: true,
    tableName: 'Jobseekers',
    viewName: 'All candidates',
  };

  useEffect(() => {
    if (ambassadorId) {
      setLoading(true);

      AirtableService.getRecord({ tableName: 'Ambassadors', recordId: ambassadorId })
        .then(({ fields }) => {
          setAmbassadorName(fields['Ambassador Name']);
        })
        .catch(() => navigate(INTAKE))
        .finally(() => setLoading(false));
    }
  }, [ambassadorId]);

  const handleSubmitForm = (): void => {
    setLoadingButton(true);

    const formFields = form.getFieldsValue();

    AirtableService.createRecord(
      { ...createRecordParams, fields: { ...formFields, ...extraFields } },
    )
      .then(() => {
        fbAnalytics(GTM_EVENTS.INTAKE_FORM, {
          jobTitle: form.getFieldValue('Looking for a job in the following areas'),
          jobDepartment: form.getFieldValue('Profession'),
          jobLocation: form.getFieldValue('Currently Residing'),
          jobCountry: form.getFieldValue('Citizenship'),
        });
        navigate(AMBASSADOR_THANK_YOU);
      })
      .catch((error) => {
        console.error(error);
        setLoadingButton(false);
      });
  };

  const content = (
    <div className="roundedContainer">
      <div className={styles.content}>
        <img className={styles.logo} src={Logo} alt="logo" />
        <h2 className="title"><FormattedMessage id="form.intake.ambassadorHiMessage" values={{ ambassadorName }} /></h2>
        <p className={styles.textMargin}><FormattedMessage id="form.intake.ambassadorGetConnected" /></p>
        <IntakeForm form={form} submitForm={handleSubmitForm} loading={loadingButton} />
      </div>
    </div>
  );

  return loading ? <LoadingIndicator /> : content;
};

export default IntakePage;
