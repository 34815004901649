import {
  ReactElement, useState, useEffect, FC,
} from 'react';
import {
  Form, Progress, FormInstance, Affix,
} from 'antd';
import { ReactComponent as FlashIcon } from 'assets/icons/flash.svg';
import { FormattedMessage } from 'react-intl';
import cs from 'classnames';
import styles from './QuestionnaireProgressBar.module.scss';

interface QuestionnaireProgressBarProps {
  form: FormInstance;
}

const QuestionnaireProgressBar: FC<QuestionnaireProgressBarProps> = ({ form }): ReactElement => {
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [isFixBar, setIsFixBar] = useState<boolean | undefined>(false);
  const fieldsValues = Form.useWatch([], form);

  const handleAffix = (isAffix: boolean | undefined): void => {
    setIsFixBar(isAffix);
  };

  useEffect(() => {
    if (fieldsValues) {
      const totalFields = Object.keys(fieldsValues).length;
      const completedFields = Object.entries(fieldsValues)
        .reduce((count, [fieldName, fieldValue]) => {
          // Check if the value is an empty array
          if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            return count; // Do not increment the counter, the field is considered unfilled
          }
          // Increase the counter only if the value is not empty
          return fieldValue ? count + 1 : count;
        }, 0);

      const percent = Math.round((completedFields / totalFields) * 100);
      setPercentCompleted(percent);
    }
  }, [fieldsValues]);

  return (
    <Affix offsetTop={0} onChange={handleAffix}>
      <div className={cs(styles.container, { [styles.shortContainer]: isFixBar })}>
        <Progress percent={percentCompleted} showInfo={false} />
        <div className={styles.progressText}>
          {percentCompleted}
          %
          <FormattedMessage id="screening.progressBar.completed" />
        </div>
        <div className={styles.flashText}>
          <FlashIcon />
          <FormattedMessage id="screening.progressBar.flashText" />
        </div>
      </div>
    </Affix>
  );
};

export default QuestionnaireProgressBar;
