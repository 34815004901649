import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AirtableResponse } from 'types/airtable';

function useCustomQuery(
  key: string,
  fetchFunction: () => Promise<AirtableResponse>,
): UseQueryResult<any> {
  return useQuery({ queryKey: [key], queryFn: fetchFunction });
}

export default useCustomQuery;