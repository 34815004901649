import {
  ReactElement, useEffect, useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Link, To, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {
  Button, Col, Row, notification,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { toggleSidebar } from 'features/sidebar/sidebarSlice';
import { selectIsLoggedIn } from 'features/auth/authSlice';
import { selectUser } from 'features/auth/authSlice';
import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';
import {
  AMBASSADOR_JOBS,
  INTAKE, JOBS,
} from 'constants/pathNames';
import Salary from 'components/Job/Salary';
import { GTM_EVENTS } from 'constants/analytics';
import { post } from 'utils/api/api';
import { fbAnalytics } from 'utils/analytics';
import formatMarkdown from 'utils/formatting/formatMarkdown';
import styles from './JobDetailsPage.module.scss';
import LoadingIndicator from '../../components/LoadingIndicator';
import AirtableService from 'services/airtable';
import { JobRecord } from 'types/airtable';

const JobDetailsPage = (): ReactElement => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const airtableUserId = useSelector(selectUser)?.airtableUserId;

  const [jobData, setJobData] = useState<JobRecord>();
  const [isLoading, setIsLoading] = useState(false);
  const isAnonymous = !useSelector(selectIsLoggedIn);

  const getRecordParams = {
    tableName: 'Jobs',
    viewName: 'Join Posted Jobs',
    recordId: id,
  };

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    AirtableService.getRecord(getRecordParams)
      .then(({ fields }) => {
        setJobData(fields);
      })
      .catch((error) => console.error('Error fetching job details:', error))
      .finally(() => setIsLoading(false));
  }, [id]);

  const jobTitle = jobData?.['Job Title'];
  const jobLocation = jobData?.['Location (City/State)'];
  const jobCountry = jobData?.['Location (Country)'];
  const totalPayment = jobData?.['Total Payment (Salary)'];
  const paymentType = jobData?.['Monthly/Hourly'];
  const totalApplicationCount = jobData?.['Total Application Count'];
  const jobDescription = jobData?.['Job Description'];
  const jobIndustry = jobData?.Industry?.join(', ');
  const jobId = jobData?.RECORDID;

  const applyForThisJob = async (gtmEventType: string): Promise<void> => {
    setIsLoading(true);
    fbAnalytics(gtmEventType, {
      jobTitle,
      jobLocation,
      jobCountry,
    });

    if (isAnonymous) {
      setIsLoading(false);
      dispatch(toggleSidebar());
      return;
    }

    try {
      if (!airtableUserId) {
        navigate(INTAKE, {
          state: {
            jobId,
          },
          replace: true,
        });
        return;
      }

      const isIntakePassed = await post(`/users/${airtableUserId}/intake-passed`, {});
      if (!isIntakePassed) {
        navigate(INTAKE, {
          state: {
            jobId,
          },
          replace: true,
        });
        return;
      }

      const applicationResponse = await post(`/users/${airtableUserId}/applications`, { job_id: jobId });

      notification.success({
        message: 'You\'ve successfully applied for a job',
        placement: 'topRight',
        duration: 5,
      });
      navigate(`/questionnaire/${jobId}/${airtableUserId}/${applicationResponse.applicationRecordId}`, { replace: true });
    } catch (error) {
      notification.error({
        message: 'We found some error, please try again later',
        placement: 'topRight',
        duration: 5,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const linkBack = (): To | void => {
    if (pathname === AMBASSADOR_JOBS) {
      return navigate(AMBASSADOR_JOBS);
    }
    return navigate(JOBS);
  };

  return (
    <div className={styles.JobDetails}>
      {/* Job Details Info Section */}
      <section className={styles.JobDetailsInfo}>
        <div className={`${styles.JobDetailsInfoContainer} container`}>
          <span
            onClick={linkBack}
            className={styles.JobDetailsLink}
            data-qa="browseAllJobsButtonLink"
            aria-hidden="true"
          >
            <span className={styles.JobDetailsLink} data-qa="browseAllJobsButtonLink">
              <Arrow fill="white" className={styles.JobDetailsLinkIcon} />
              <FormattedMessage id="job.browse" />
            </span>
          </span>
          <div className={styles.JobDetailsCardInfo}>
            <h1 data-qa="jobDetailsTitle">{jobTitle}</h1>
            <Row align="middle">
              <Col xs={24} lg={10}>
                <dl className={styles.JobDetailsList}>
                  <dt data-qa="jobDetailsLocationBlock">
                    <FormattedMessage id="job.location" />
                  </dt>
                  <dd>{jobCountry}</dd>
                </dl>
                <dl className={styles.JobDetailsList}>
                  <dt data-qa="jobDetailsLocationBlock">
                    <FormattedMessage id="job.industry" />
                  </dt>
                  <dd>{jobIndustry}</dd>
                </dl>
                <dl className={styles.JobDetailsList}>
                  <dt data-qa="jobDetailsLocationBlock">
                    <FormattedMessage id="job.totalApplications" />
                  </dt>
                  <dd>{totalApplicationCount}</dd>
                </dl>
              </Col>
              <Col xs={24} lg={14}>
                <ul className={styles.JobDetailsAdditional}>
                  <li>
                    <Salary
                      jobInfo={totalPayment}
                      styleName="JobDetailsList"
                      dataQa={{
                        salary: 'jobDetailsSalary',
                        period: 'jobDetailsSalaryPeriod',
                        bonusAmount: 'jobDetailsBonusAmount',
                        bonusPeriod: 'jobDetailsBonusPeriod',
                      }}
                    />
                  </li>
                  <li>
                    <dl className={styles.JobDetailsList}>
                      <dd data-qa="jobDetailsHoursPerValue">
                        {paymentType}
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <Button
                      block
                      disabled={isLoading}
                      type="default"
                      className="btn btn-default"
                      data-qa="applyForAJobButton"
                      onClick={() => applyForThisJob(GTM_EVENTS.JOB_APPLY_TOP)}
                    >
                      <FormattedMessage id="job.apply" />
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      {/* Job Description Section */}
      <section className={styles.JobDetailsDescription}>
        {!isLoading ? (
          <div className={`${styles.JobDetailsContainer} container`}>
            <section className={styles.Description}>
              <Row>
                <Col span={24}>
                  <div className={styles.JobDetailsText}>
                    {ReactHtmlParser(formatMarkdown(jobDescription))}
                  </div>
                </Col>
              </Row>
            </section>
            <section className={styles.ApplyBlock} data-qa="applyForAJobBlock">
              <Row align="middle">
                <Col xs={24} lg={12}>
                  <p>
                    <FormattedMessage id="job_details.apply" values={{ jobTitle }} />
                  </p>
                </Col>
                <Col xs={24} lg={12} className="text-right-lg">
                  <div className={styles.ButtonHolder}>
                    <Button
                      block
                      type="primary"
                      className="btn"
                      data-qa={(isLoading) ? 'applyDisabledButton' : 'applyActiveButton'}
                      disabled={isLoading}
                      onClick={() => applyForThisJob(GTM_EVENTS.JOB_APPLY_BOTTOM)}
                    >
                      <FormattedMessage id="job.apply" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </section>
          </div>
        ) : <LoadingIndicator />}
      </section>
    </div>
  );
};

export default JobDetailsPage;
