import { useState, useEffect, ReactElement } from 'react';
import PrivacySettings from 'components/GDPRPopup/PrivacySettings';
import CustomizePrivacySettings from 'components/GDPRPopup/CustomizePrivacySettings';
import { getGDPRSettings } from 'utils/localStorage';
import styles from './GDPRPopup.module.scss';

const GDPRPopup = (): ReactElement | null => {
  const [isGDPRVisible, setIsGDPRVisible] = useState(!getGDPRSettings());
  const [customizeSettingsVisible, setCustomizeSettingsVisible] = useState(false);

  const customizeSettingsSettingsVisible = (): void => {
    setCustomizeSettingsVisible(!customizeSettingsVisible);
  };

  useEffect(() => {
    const settings = getGDPRSettings();

    if (!settings) {
      setIsGDPRVisible(true);
    }
  }, []);

  if (!isGDPRVisible) return null;

  return (
    <div className={styles.Popup}>
      {customizeSettingsVisible
        ? (
          <CustomizePrivacySettings
            toggleCustomizeSettings={customizeSettingsSettingsVisible}
            setVisible={setIsGDPRVisible}
            setGDPR={setIsGDPRVisible}
          />
        )
        : (
          <PrivacySettings
            toggleCustomizeSettings={customizeSettingsSettingsVisible}
            setVisible={setIsGDPRVisible}
            setGDPR={setIsGDPRVisible}
          />
        )}
    </div>
  );
};

export default GDPRPopup;
