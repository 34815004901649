export interface FormOptions {
    value: string;
    label: string;
    [key: string]: string;
}

type MapperFunction = (item: string) => FormOptions;

const defaultMapper: MapperFunction = (item) => ({ label: item, value: item });

export const convertArray = (array: string[], mapper: MapperFunction = defaultMapper, sortKey = 'label'): FormOptions[] =>
  array.map(mapper).sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

export const convertArraySorted = (
  array: string[],
  mapper: MapperFunction = defaultMapper,
): FormOptions[] => array.map(mapper);
