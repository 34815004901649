import { ReactElement } from 'react';
import {
  Image, Text, View, StyleSheet,
} from '@react-pdf/renderer';
import logo from 'assets/logo';
import getFullDate from 'utils/getInfo/getFullDate';

const Header = (): ReactElement => (
  <>
    <View style={styles.header}>
      <Image style={styles.logo} src={logo} />
    </View>
    <View style={styles.section}>
      <Text style={styles.date}>{getFullDate()}</Text>
      <Text style={styles.title}>Talent Acquisition Order</Text>
    </View>
  </>
);

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 48,
    paddingVertical: 24,
    marginBottom: 25,
    marginTop: -30,
    backgroundColor: '#F0F7FF',
  },
  section: {
    paddingHorizontal: 48,
  },
  logo: {
    width: 68,
    height: 31,
  },
  date: {
    marginBottom: 8,
    fontSize: 12,
    color: '#677179',
  },
  title: {
    marginBottom: 25,
    textTransform: 'uppercase',
    fontSize: 20,
    fontWeight: 'bold',
  },
  approveContainer: {
    padding: 10,
    marginVertical: 36,
    backgroundColor: '#F2FCFB',
    border: '1px solid #25D2BA',
    borderRadius: 8,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 1.3,
    color: '#677179',
  },
  approveLabel: {
    fontWeight: 'bold',
    color: '#25D2BA',
  },
});

export default Header;
