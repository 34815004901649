import { ReactElement } from 'react';
import {
  Text, View, Link, StyleSheet,
} from '@react-pdf/renderer';

interface TableRowProps {
  label: string;
  value: string;
  type: string;
  order: number;
  fullWidth?: boolean;
}

const TableRow = ({
  label, value, type, order, fullWidth = false,
}: TableRowProps): ReactElement => {
  const rowBackgroundColor = order % 2 === 0 ? '#F9F9F9' : '#ffffff';

  const getValue = (rowType: string, rowValue: string): ReactElement => {
    let getColor;

    switch (rowValue) {
      case 'Yes':
        getColor = styles.roundedPositive;
        break;
      case 'No':
        getColor = styles.roundedNegative;
        break;
      case '':
        getColor = styles.notShow;
        break;
      default:
        getColor = styles.roundedDefault;
    }

    switch (rowType) {
      case 'link':
        return <Link style={styles.link} src={rowValue}>{rowValue}</Link>;
      case 'rounded':
        return <Text style={{ ...styles.rounded, ...getColor }}>{rowValue}</Text>;
      case 'text':
      default:
        return <Text style={styles.text}>{rowValue}</Text>;
    }
  };

  const renderContent = (): ReactElement => (
    <View style={{ ...styles.row, backgroundColor: rowBackgroundColor }}>
      <View style={styles.column}>
        <Text style={styles.label}>{label}</Text>
      </View>
      <View style={styles.column}>{getValue(type, value)}</View>
    </View>
  );

  const renderFullWidthContent = (): ReactElement => (
    <View style={{ ...styles.row, backgroundColor: rowBackgroundColor }}>
      <View style={styles.column}>
        <Text style={styles.label}>{label}</Text>
        <Text style={{ marginTop: 10, color: '#222222' }}>{value}</Text>
      </View>
    </View>
  );

  return fullWidth ? renderFullWidthContent() : renderContent();
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    fontSize: 12,
    lineHeight: 1.3,
  },
  column: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: 1,
    padding: 8,
  },
  label: {
    color: '#677179',
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
    color: '#222222',
  },
  link: {
    color: '#006BEB',
    fontWeight: 'bold',
  },
  rounded: {
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 4,
    fontSize: 14,
    lineHeight: 1.2,
  },
  notShow: {
    display: 'none',
  },
  roundedDefault: {
    backgroundColor: '#E5F0FD',
    color: '#006BEB',
  },
  roundedPositive: {
    backgroundColor: '#F2FCFB',
    color: '#25D2BA',
  },
  roundedNegative: {
    backgroundColor: '#FEF0F5',
    color: '#FC336A',
  },
});

export default TableRow;
