import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { fetchUserData } from './authThunks';

interface User {
  airtableUserId?: string;
  email?: string;
  emailVerified?: boolean;
  id?: string;
  imageUrl?: string;
  name?: string;
  provider?: string;
  providerId?: string;
}

interface AuthState {
  token: string | null;
  isLoggedIn: boolean;
  user: User;
}

const tokenFromStorage = localStorage.getItem('token');

const initialState: AuthState = {
  token: tokenFromStorage,
  isLoggedIn: !!tokenFromStorage,
  user: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<{ token: string; user: User }>) => {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.user = action.payload.user;
      localStorage.setItem('token', action.payload.token);
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      state.user = {};
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
      state.isLoggedIn = true;
    });
  },
});

export const selectAuthToken = (state: RootState): string | null => state.auth.token;
export const selectIsLoggedIn = (state: RootState): boolean => state.auth.isLoggedIn;
export const selectUser = (state: RootState): User => state.auth.user;

export const { setCredentials, updateUser, logout } = authSlice.actions;

export default authSlice.reducer;
