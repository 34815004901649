import { HOST } from 'constants/api';
import { selectAuthToken } from 'features/auth/authSlice';
import responseParser from 'utils/api/responseParser';
import checkStatus from 'utils/api/checkStatus';
import errorParser from 'utils/api/errorParser';
import store from 'app/store';

interface APIConfig {
  method: 'GET' | 'POST';
  headers?: HeadersInit;
  body?: string;
}

const fetchApi = (path: string, config: APIConfig): Promise<any> => {
  const token = selectAuthToken(store.getState());
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    ...(token ? { Authorization: token } : {}),
  };

  const fullConfig: RequestInit = {
    ...config,
    headers: { ...headers, ...config.headers },
  };

  return fetch(`${HOST}${path}`, fullConfig)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export const get = (path: string, headers?: HeadersInit): Promise<any> =>
  fetchApi(path, { method: 'GET', headers });

export const post = (path: string, body: object, headers?: HeadersInit): Promise<any> =>
  fetchApi(path, { method: 'POST', body: JSON.stringify(body), headers });

export default { get, post };
