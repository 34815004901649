import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/api/api';

export const fetchUserData = createAsyncThunk(
  'auth/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const { user, airtableUserId } = await get('/users/me');
      return { ...user, airtableUserId };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
