const getQueryParams = (queryString: string, paramNames: string[])
    : Record<string, string | null> => {
  const params = new URLSearchParams(queryString);
  const result: Record<string, string | null> = {};

  paramNames.forEach((paramName) => {
    result[paramName] = params.get(paramName);
  });

  return result;
};

export default getQueryParams;
