import { array, object, oneOfType } from 'prop-types';
import styles from './PageTitle.module.scss';
import { ReactElement } from 'react';

const PageTitle = ({ children }: { children: ReactElement | ReactElement[] | any }):
    ReactElement => (
      <section className={styles.PageTitle}>
        <div className={`${styles.PageTitleContainer} container`}>
          {children}
        </div>
      </section>
);

PageTitle.propTypes = {
  children: oneOfType([object, array]).isRequired,
};

export default PageTitle;
