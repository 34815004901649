import { ReactElement, useEffect, useState } from 'react';
import {
  Form,
  notification,
} from 'antd';
import type { UploadFile } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { QUESTIONNAIRE_THANK_YOU } from 'constants/pathNames';
import AirtableService from 'services/airtable';
import postMultipartApiData from 'utils/api/postMultipartApiData';
import { post } from 'utils/api/api';

import { selectUser } from 'features/auth/authSlice';
import ExtraHeader from 'components/ExtraHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import QuestionnaireForm from 'components/QuestionnaireForm';

interface Fields {
  [key: string]: any;
}

interface JobFields {
  jobTitle: string;
  industry: string;
  location: string;
  country: string;
  interviewTime: string;
}

interface UserFields {
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
}

const QuestionnairePage = (): ReactElement => {
  const { jobId, userId, applicationId } = useParams();
  const navigate = useNavigate();
  const airtableUserId = useSelector(selectUser)?.airtableUserId;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [jobData, setJobData] = useState<JobFields | null>(null);
  const [userData, setUserData] = useState<UserFields | null>(null);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [resume, setResume] = useState<UploadFile | null>(null);
  const [userIndustries, setUserIndustries] = useState<string[]>([]);

  const fieldsValues = Form.useWatch([], form);

  const createRecordParams = {
    typecast: true,
    tableName: 'Pre-screening',
    viewName: 'Grid view',
  };

  const handleResumeChange = (file: UploadFile | null): void => {
    setResume(file);
  };

  const removeFields = ['Confirmation Read', 'Confirmation Response'];

  const excludeProperties = (obj: any, excludedProperties: string[]): object => {
    const newObj = { ...obj };

    excludedProperties.forEach((prop) => {
      if (prop in newObj) {
        delete newObj[prop];
      }
    });

    return newObj;
  };

  const uploadResume = async (file: UploadFile): Promise<string> => {
    const resumeData = new FormData();
    // @ts-ignore
    resumeData.append('file', file as UploadFile);

    try {
      const { download_link: downloadLink } = await postMultipartApiData('/files/upload', resumeData);
      return downloadLink;
    } catch (error) {
      console.error('Error uploading a resume: ', error);
      notification.error({
        message: 'Error uploading a resume. Please try again later',
        placement: 'topRight',
      });
      throw error;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setDisableButtons(true);

    try {
      let uploadedResumeLink = '';
      if (resume) {
        uploadedResumeLink = await uploadResume(resume);
      }

      const fields: Fields = {
        ...excludeProperties(fieldsValues, removeFields),
        Name: userData?.full_name,
        Application: applicationId,
        'Jobseeker Profile': airtableUserId,
      };

      if (uploadedResumeLink) {
        fields['CV/Resume Upload'] = uploadedResumeLink;
      }

      await AirtableService.createRecord({
        ...createRecordParams,
        fields,
      });

      await post(`/users/${airtableUserId}/pre-screening/submit`, { applicationId, jobId });

      setResume(null);
      navigate(QUESTIONNAIRE_THANK_YOU, {
        state: {
          userIndustries,
          thankYouMessage: 'screening.thankYou.submission',
        },
      });
    } catch (error) {
      setDisableButtons(false);
      console.error('Error handling form submission: ', error);
      notification.error({
        message: 'Error handling form submission. Please try again later',
        placement: 'topRight',
      });
    } finally {
      setDisableButtons(false);
    }
  };

  const handleSendLinkToEmail = async (): Promise<void> => {
    setDisableButtons(true);

    const data = {
      ...userData,
      job_title: jobData?.jobTitle,
      job_id: jobId,
      application_id: applicationId,
    };

    try {
      await post(`/users/${userId}/pre-screening/start`, data);
      navigate(QUESTIONNAIRE_THANK_YOU, {
        state: {
          userIndustries,
          thankYouMessage: 'screening.thankYou.email',
        },
      });
    } catch (error) {
      setDisableButtons(false);
      console.error('Error handling sending email: ', error);
      notification.error({
        message: 'Error handling sending email. Please try again later',
        placement: 'topRight',
      });
    } finally {
      setDisableButtons(false);
    }
  };

  useEffect(() => {
    if (jobId) {
      setLoading(true);

      AirtableService.getRecord({ tableName: 'Jobs', recordId: jobId })
        .then(({ fields }) => {
          setJobData({
            jobTitle: fields?.['Job Title'] || 'n/a',
            industry: fields?.Industry.join(', ') || 'n/a',
            location: fields?.['Full Location (Internal purposes only)'] || 'n/a',
            country: fields?.['Location (Country)'] || 'n/a',
            interviewTime: fields?.['Start date'] || 'n/a',
          });
        })
        .finally(() => setLoading(false));
    }
  }, [jobId]);

  useEffect(() => {
    if (userId) {
      setLoading(true);

      AirtableService.getRecord({ tableName: 'Jobseekers', recordId: userId })
        .then(({ fields }) => {
          setUserIndustries(fields?.['Looking for a job in the following areas'] || []);
          setUserData({
            first_name: fields?.['First Name'] || '',
            last_name: fields?.['Last Name'] || '',
            full_name: fields?.['Full Name'] || '',
            email: fields?.Email || '',
          });
        })
        .finally(() => setLoading(false));
    }
  }, [userId]);

  return (
    <>
      <ExtraHeader title="screening.header.title" description="screening.header.description" />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <QuestionnaireForm
          form={form}
          disable={disableButtons}
          jobData={jobData}
          onChangeResume={handleResumeChange}
          handleSubmit={handleSubmit}
          handleSendLinkToEmail={handleSendLinkToEmail}
        />
      )}
    </>
  );
};

export default QuestionnairePage;
