import 'styles/Main.scss';
import './styles/Media.scss';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import sentryConfig from 'config/sentryConfig';
import store from 'app/store';
import { Provider } from 'react-redux';
import App from './App';
import packageJson from '../package.json';
import { ReactElement } from 'react';

export const buildVersion = packageJson;

const container = document.getElementById('root');
container?.setAttribute('data-version', packageJson.version);
if (!container) throw new Error('container not found!');

const root = createRoot(container);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30, // 30 minutes
    },
  },
});

// if (process.env.NODE_ENV === 'production') {
//   // @ts-ignore
//   import('https://cdn.jsdelivr.net/npm/@sentry/react@7.42.0/+esm').then(({ init }) => {
//     init(sentryConfig);
//   });
// }

const Main = (): ReactElement => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);

root.render(<Main />);
