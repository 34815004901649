import { ReactElement } from 'react';
import cs from 'classnames';
import styles from './TableRow.module.scss';

interface TableRowProps {
  label: string;
  value: string | [];
  type: string;
  order: number;
  fullWidth?: boolean;
}

const TableRow = ({
  label, value, type, order, fullWidth = false,
}: TableRowProps): ReactElement => {
  const grayBg = order % 2 === 0;

  const getValue = (rowType: string, rowValue: string | []): ReactElement => {
    let getColor;
    const getRowValue = Array.isArray(rowValue) ? rowValue.join(', ') : rowValue;

    switch (getRowValue) {
      case 'Yes':
        getColor = styles.roundedPositive;
        break;
      case 'No':
        getColor = styles.roundedNegative;
        break;
      case '':
        getColor = styles.notShow;
        break;
      default:
        getColor = styles.roundedDefault;
    }

    switch (rowType) {
      case 'link':
        return <a className={styles.link} href={getRowValue}>{getRowValue}</a>;
      case 'rounded':
        return <div className={cs(styles.rounded, getColor)}>{getRowValue}</div>;
      case 'text':
      default:
        return <div className={styles.text}>{getRowValue}</div>;
    }
  };

  return (
    <div
      className={cs(styles.row, { [styles.rowGray]: grayBg, [styles.fullWidth]: fullWidth })}
    >
      <div className={styles.column}>
        <p className={styles.label}>{label}</p>
      </div>
      <div className={styles.column}>{getValue(type, value)}</div>
    </div>
  );
};

export default TableRow;
