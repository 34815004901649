import { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'utils/api/api';
import { USER_PROFILE } from 'constants/pathNames';
import { HOST } from 'constants/api';

const UserProfilePage = (): ReactElement => {
  useEffect(() => {
    const fetchData = async (): Promise<any> => {
      try {
        const response = await get(USER_PROFILE);
        console.log('response: ', response);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <h2 className="title"><FormattedMessage id="user.profile.title" values={{ name: 'Test' }} /></h2>
    </div>
  );
};

export default UserProfilePage;
