import {
  Dispatch, ReactElement, SetStateAction, useContext,
} from 'react';
import { Button } from 'antd';
import { func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAppLocale } from 'features/locale/localeSlice';
import { saveGDPRSettings } from 'utils/localStorage';
import { setupAnalytics } from 'utils/analytics';
import getLegalDocsLinks from 'utils/getInfo/getLegalDocsLinks';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './PrivacySettings.module.scss';

interface PrivacySettingsProps {
    toggleCustomizeSettings: () => void;
    setVisible: (visible: boolean) => void;
    setGDPR: Dispatch<SetStateAction<boolean>>;
}

const PrivacySettings = ({
  toggleCustomizeSettings,
  setVisible,
  setGDPR,
}: PrivacySettingsProps): ReactElement => {
  const locale = useSelector(selectAppLocale);

  const { urlCookiePolicy } = getLegalDocsLinks(locale);

  const onAccept = (): void => {
    const settings: any = { A: true, F: true, T: true };
    saveGDPRSettings(settings);
    setGDPR(settings);
    setVisible(false);
    setupAnalytics();
  };

  const onDecline = (): void => setVisible(false);

  return (
    <div className={styles.ContainerSettings}>
      <div className={styles.ContainerInner}>
        <div className={styles.ContainerMain}>
          <h3 className={styles.Header}>
            <FormattedMessage id="GDPR.we_care" />
            <Button
              type="link"
              className={`${styles.NoButton} link-btn`}
              onClick={onDecline}
            >
              <CloseIcon />
            </Button>
          </h3>
          <div className={styles.Text}>
            <p>
              <FormattedMessage id="GDPR.main_text" />
              &nbsp;
              <a
                href={urlCookiePolicy}
                target="_blank"
                data-qa="dataCookiePolicyLink"
                rel="noreferrer"
                aria-label={urlCookiePolicy}
              >
                <FormattedMessage id="GDPR.cookie_policy" />
              </a>
              .
            </p>
            <p>
              <FormattedMessage id="GDPR.main_text2" />
            </p>
          </div>
        </div>
        <div className={styles.ContainerActions}>
          <Button
            type="link"
            className={`${styles.CustomizeSettings} link-btn`}
            onClick={(): void => toggleCustomizeSettings()}
          >
            <FormattedMessage id="GDPR.cookie_preferences" />
          </Button>
          <Button
            type="primary"
            data-qa="acceptCookieOKButton"
            className={`${styles.AcceptButton} btn btn-small`}
            onClick={onAccept}
          >
            <FormattedMessage id="GDPR.accept_all" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings;

PrivacySettings.propTypes = {
  toggleCustomizeSettings: func.isRequired,
  setVisible: func.isRequired,
  setGDPR: func.isRequired,
};
