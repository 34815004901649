import { memo, ReactElement } from 'react';
import { Select, Form, FormInstance } from 'antd';
import Picker from 'components/Picker';
import { useIntl } from 'react-intl';
import { useMobileDesign } from 'hooks/useMobileDesign';
import { convertArray, convertArraySorted, FormOptions } from 'utils/convertArray';

interface IntakeSelectProps {
  list: string[];
  form: FormInstance;
  fieldName:string;
  fieldLabel: string;
  required?: boolean;
  placeholder?: string;
  sortArray?: boolean;
  disabled?: boolean;
}

const IntakeSelect = ({
  list,
  form,
  fieldName,
  fieldLabel,
  required = false,
  placeholder,
  sortArray = true,
  disabled = false,
}: IntakeSelectProps): ReactElement => {
  const intl = useIntl();
  const isMobile = useMobileDesign();
  const selectPlaceholder = placeholder ?? 'form.default.selectPlaceholder';
  const fieldValue = form?.getFieldValue(fieldName);

  const convertedArray = (array: string[]): FormOptions[] =>
    (sortArray ? convertArray(array) : convertArraySorted(array));

  return (
    <Form.Item
      name={fieldName}
      label={intl.formatMessage({ id: fieldLabel })}
      rules={[{ required }]}
      className="picker-mobile"
    >
      {!isMobile ? (
        <Select
          options={convertedArray(list)}
          placeholder={intl.formatMessage({ id: selectPlaceholder })}
          disabled={disabled}
        />
      ) : (
        <Picker
          name={fieldName}
          form={form}
          label={intl.formatMessage({ id: fieldLabel })}
          placeholder={intl.formatMessage({ id: selectPlaceholder })}
          formatMessage={intl.formatMessage}
          defaultValue={fieldValue}
          onReject={() => {}}
          disabled={disabled}
          columnDataChildren={(
            convertedArray(list).map(({ value, label }) => (
              <Select.Option
                key={value}
                value={value}
                title={label}
                selectTitle={label}
              >
                {label}
              </Select.Option>
            ))
          )}
        />
      )}
    </Form.Item>
  );
};

export default memo(IntakeSelect);
