const industryOfBusiness = [
  'Automotive',
  'Baking Business',
  'Commercial/Industrial Foam Specialists',
  'Construction',
  'Food Manufacturing',
  'Healthcare',
  'Homecare',
  'Hospitality',
  'Landscaping',
  'Logistics',
  'Maintenance',
  'Manufacturing',
  'Manufacturing- Ceramic vats for steel',
  'Media / PR',
  'Precision Manufacturing',
  'Real Estate/Development',
  'Restaurant',
  'Retail',
  'School',
  'Tire and Auto',
  'Warehouse',
];

export default industryOfBusiness;
