import { useContext, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import getLegalDocsLinks from 'utils/getInfo/getLegalDocsLinks';

const TermsAndConditions = (): ReactElement => {
  const { urlPrivacy, urlTerms } = getLegalDocsLinks('en');

  const privacyLink = (
    <Button type="link" className="link" href={urlTerms} target="_blank" data-qa="signInWindowTermsLink">
      <FormattedMessage id="terms_of_service" />
    </Button>
  );

  const termsLink = (
    <Button type="link" className="link" href={urlPrivacy} target="_blank" data-qa="signInWindowPrivacyLink">
      <FormattedMessage id="privacy_policy" />
    </Button>
  );

  return (
    <p>
      <FormattedMessage
        id="terms_and_policy_text"
        values={{ terms: termsLink, policy: privacyLink }}
      />
    </p>
  );
};

export default TermsAndConditions;
