const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

const GPDROptions = {
  ANALYTICS: 'A',
  FUNCTIONAL_COOKIES: 'F',
  TARGETING_COOKIES: 'T',
};

const PHONE_STATUS = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
};

export {
  GENDER,
  GPDROptions,
  PHONE_STATUS,
};
