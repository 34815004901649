import LOCALES from 'constants/locales';

import ENGLISH from './translations/join-strings-en.json';
import RUSSIAN from './translations/join-strings-ru.json';
import TAJIK from './translations/join-strings-tg.json';
import ROMANIAN from './translations/join-strings-ro.json';
import KAZAKH from './translations/join-strings-kk.json';
import KYRGYZ from './translations/join-strings-ky.json';
import UZBEK from './translations/join-strings-uz.json';
import TURKMENI from './translations/join-strings-tk.json';
import AZERBIJANI from './translations/join-strings-az.json';
import UKRAINIAN from './translations/join-strings-uk.json';
import ARMENIAN from './translations/join-strings-hy.json';
import ITALIAN from './translations/join-strings-it.json';
import GERMAN from './translations/join-strings-de.json';
import FRENCH from './translations/join-strings-fr.json';
import POLAND from './translations/join-strings-pl.json';
import GEORGIAN from './translations/join-strings-ka_GE.json';
import TURKISH from './translations/join-strings-tr.json';
import CROATIAN from './translations/join-strings-hr_HR.json';
import SPANISH from './translations/join-strings-es.json';
import ARABIC from './translations/join-strings-ar.json';
import BURMESE from './translations/join-strings-my.json';

interface LangObject {
  [key: string]: any;
}

interface LocaleMessage {
  messages: LangObject;
  locale: string;
}

/**
 * This function is a wrapper for the react-intl provider
 * @param messages Inject with translations
 * @param locale
 * @returns {{messages, locale}}
 */
const langSelector = (messages: LangObject, locale: string): LocaleMessage =>
  ({ messages: { ...messages }, locale });

export const enLang = langSelector(ENGLISH, LOCALES.ENGLISH);

const languages = [
  { lang: ENGLISH, locale: LOCALES.ENGLISH },
  { lang: RUSSIAN, locale: LOCALES.RUSSIAN },
  { lang: TAJIK, locale: LOCALES.TAJIK },
  { lang: ROMANIAN, locale: LOCALES.ROMANIAN },
  { lang: KAZAKH, locale: LOCALES.KAZAKH },
  { lang: KYRGYZ, locale: LOCALES.KYRGYZ },
  { lang: UZBEK, locale: LOCALES.UZBEK },
  { lang: TURKMENI, locale: LOCALES.TURKMENI },
  { lang: AZERBIJANI, locale: LOCALES.AZERBIJANI },
  { lang: UKRAINIAN, locale: LOCALES.UKRAINIAN },
  { lang: ARMENIAN, locale: LOCALES.ARMENIAN },
  { lang: ITALIAN, locale: LOCALES.ITALIAN },
  { lang: GERMAN, locale: LOCALES.GERMAN },
  { lang: FRENCH, locale: LOCALES.FRENCH },
  { lang: POLAND, locale: LOCALES.POLAND },
  { lang: GEORGIAN, locale: LOCALES.GEORGIAN },
  { lang: TURKISH, locale: LOCALES.TURKISH },
  { lang: CROATIAN, locale: LOCALES.CROATIAN },
  { lang: SPANISH, locale: LOCALES.SPANISH },
  { lang: ARABIC, locale: LOCALES.ARABIC },
  { lang: BURMESE, locale: LOCALES.BURMESE },
];

const languagesObj = languages.reduce((acc, curr) => {
  const { lang, locale } = curr;
  const langSelectorResult = langSelector(lang, locale);
  return { ...acc, [`${locale}Lang`]: langSelectorResult };
}, {});

export default languagesObj;
