import { useEffect, useState } from 'react';
import {
  Button, Checkbox, Modal,
} from 'antd';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/clear-icon.svg';

import { FormattedMessage } from 'react-intl';
import JobCountry from 'components/Job/JobCountry';
import useSortedArrayByProp from 'hooks/useSortedArrayByProp';
import { array, bool, func, string } from 'prop-types';

import { GTM_EVENTS } from 'constants/analytics';
import { fbAnalytics } from 'utils/analytics';
import styles from './FilterJobs.module.scss';
import { useMobileDesign } from 'hooks/useMobileDesign';

export const renderSelectedCountries = ({ countries, checkedCountries, max }) => {
  const selectedCountries = countries
    .filter((country) => checkedCountries.find(
      (checkedCountry) => checkedCountry === country.value,
    )).map(
      ({ key, value }) => (<JobCountry key={key} countryName={value} countryCode={key} />),
    );

  if (max) return [...selectedCountries.slice(0, max), selectedCountries.length > max ? '...' : ''];

  return selectedCountries;
};

export const renderSelectedDepartments = ({ departments, checkedDepartments, max }) => {
  const needComma = checkedDepartments.length > 1;
  const lastElement = checkedDepartments.length - 1;
  const selectedDepartments = departments
    .filter((department) => checkedDepartments.find(
      (checkedDepartment) => checkedDepartment === department.value,
    )).map(
      ({ key, value }) => (
        <span key={key}>
          {value}
          {needComma && checkedDepartments[lastElement] !== value && ', '}
        </span>
      ),
    );

  if (max) return [...selectedDepartments.slice(0, max), selectedDepartments.length > max ? '...' : ''];

  return selectedDepartments;
};

const renderSelectedIndustries = ({ industries, checkedIndustries, max }) => {
  const needComma = checkedIndustries.length > 1;
  const lastElement = checkedIndustries.length - 1;
  const selectedIndustries = industries
    .filter((industry) => checkedIndustries.find(
      (checkedIndustry) => checkedIndustry === industry,
    )).map(
      (value) => (
        <span key={value}>
          {value}
          {needComma && checkedIndustries[lastElement] !== value && ', '}
        </span>
      ),
    );

  if (max) return [...selectedIndustries.slice(0, max), selectedIndustries.length > max ? '...' : ''];

  return selectedIndustries;
};

const getCountryOptions = (countries) => countries.map(({ key, value }) => (
  {
    label: (<JobCountry countryName={value} countryCode={key} />),
    value: value || '',
  }
));

const getIndustryOptions = (industries) => industries.map((value) => (
  {
    label: value,
    value,
  }
));

const FilterJobs = ({
  updateFilters,
  country,
  industry,
  department,
  isModalVisible,
  setIsModalVisible,
  withDataQa,
  jobsData,
}) => {
  const [checkedCountries, setCheckedCountries] = useState(country?.split(',') || []);
  const [checkedIndustries, setCheckedIndustries] = useState(industry?.split(',') || []);
  const [checkedDepartments, setCheckedDepartments] = useState(department?.split(',') || []);
  const [industries, setIndustries] = useState([]);
  const [countries, setCountryCodes] = useState([]);
  const isMobile = useMobileDesign(500);

  useEffect(() => {
    const seenCountries = new Set();

    const extractedCountries = jobsData
      .map((record) => ({ key: record.id, value: record.fields['Location (Country)'] }))
      .sort((a, b) => a?.value?.[0]?.localeCompare(b?.value?.[0]))
      .filter((coun) => {
        if (seenCountries.has(coun.value)) {
          return false;
        }
        seenCountries.add(coun.value);
        return true;
      });

    const extractedIndustries = new Set();
    jobsData.forEach((record) => {
      if (record.fields.Industry) {
        record.fields.Industry.forEach((industry) => {
          extractedIndustries.add(industry);
        });
      }
    });

    const sortedIndustries = Array.from(extractedIndustries).sort((a, b) => a.localeCompare(b));
    setCountryCodes(extractedCountries);
    setIndustries(Array.from(sortedIndustries));
  }, [jobsData]);

  const sortedCountries = useSortedArrayByProp(countries, 'value');

  const closeModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onClearFiltersModal = () => {
    setCheckedCountries([]);
    setCheckedDepartments([]);
    setCheckedIndustries([]);
    updateFilters({ countryFilter: '', departmentFilter: '', industryFilter: '' });
  };

  const onClearFilters = () => {
    fbAnalytics(GTM_EVENTS.CLEAR_JOBS);
    updateFilters({ countryFilter: '', departmentFilter: '', industryFilter: '' });
    onClearFiltersModal();
  };

  const onApplyFilters = () => {
    fbAnalytics(GTM_EVENTS.FILTER_JOBS);
    updateFilters({
      countryFilter: checkedCountries.toString(),
      departmentFilter: checkedDepartments.toString(),
      industryFilter: checkedIndustries.toString(),
    });
    closeModal();
  };

  const isShowFilters = country;

  useEffect(() => {
    if (country) {
      setCheckedCountries(country?.split(',') || []);
    }

    if (industry) {
      setCheckedIndustries(industry?.split(',') || []);
    }
  }, [country, industry]);

  return (
    <div className={styles.FilterJobs} style={{ width: isMobile ? '100%' : 'auto' }}>
      <div className={styles.FilterJobsButtons}>
        <Button
          type="default"
          data-qa={withDataQa ? 'filterJobsButton' : ''}
          className={isShowFilters ? `${styles.FilterButton} btn filter-btn` : 'btn filter-btn'}
          onClick={() => setIsModalVisible(true)}
          style={{ width: isMobile ? '100%' : 'auto' }}
        >
          <FilterIcon />
          <FormattedMessage id="filter.jobs" />
        </Button>
        {(country || industry) && (
        <Button
          type="default"
          className={`${styles.ClearButton} btn filter-btn `}
          onClick={onClearFilters}
          style={{
            marginTop: isMobile ? '8px' : '0',
            marginLeft: isMobile ? '0' : '20px',
            width: isMobile ? '100%' : 'auto',
          }}
        >
          <ClearIcon width="14px" height="14px" />
          <FormattedMessage id="button.clear_filters" />
        </Button>
        )}
      </div>
      {!isShowFilters && (
      <p>
        <FormattedMessage id="filter_jobs.text" />
      </p>
      )}
      <div className={styles.selectedWrap}>
        {country && (
        <div className={styles.SelectedFilters}>

          <span className={styles.CategoryTitle}>
            <FormattedMessage id="filter.country" />
            :
          </span>
          <div className={styles.CategoryList}>
            {renderSelectedCountries({ countries, checkedCountries })}
          </div>
        </div>
        )}
        {industry && (
        <div className={styles.SelectedFilters}>
          <span className={styles.CategoryTitle}>
            <FormattedMessage id="filter.industry" />
            :
          </span>
          <div className={styles.CategoryList}>
            {renderSelectedIndustries({ industries, checkedIndustries })}
          </div>
        </div>
        )}
      </div>
      <Modal
        open={isModalVisible}
        onCancel={closeModal}
        title={<FormattedMessage id="filter.jobs" />}
        className="modal filter-modal"
        wrapProps={{
          'data-qa': withDataQa ? 'filtersWindow' : '',
        }}
        footer={[
          <Button
            key="btn-filter-apply"
            type="primary"
            className="btn btn-primary"
            onClick={onApplyFilters}
          >
            <FormattedMessage id="button.apply_filters" />
          </Button>,
          <Button
            key="btn-filter-clear"
            type="link"
            className="link-btn icon-link"
            onClick={onClearFiltersModal}
          >
            <ClearIcon />
            <FormattedMessage id="button.clear_filters" />
          </Button>,
        ]}
      >
        <div className="block-scroll">
          <h3>
            <FormattedMessage id="filter.country" />
            :
          </h3>
          <Checkbox.Group
            className={styles.FilterList}
            options={getCountryOptions(sortedCountries)}
            value={checkedCountries || []}
            onChange={(checkedValue) => {
              setCheckedCountries(checkedValue);
            }}
          />
          <h3>
            <FormattedMessage id="filter.industry" />
            :
          </h3>
          <Checkbox.Group
            className={styles.FilterList}
            options={getIndustryOptions(industries)}
            value={checkedIndustries || []}
            onChange={(checkedValue) => {
              setCheckedIndustries(checkedValue);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

FilterJobs.propTypes = {
  updateFilters: func.isRequired,
  country: string,
  industry: string,
  department: string,
  isModalVisible: bool.isRequired,
  setIsModalVisible: func.isRequired,
  withDataQa: bool,
  jobsData: array.isRequired,
};

FilterJobs.defaultProps = {
  industry: '',
  country: '',
  department: '',
  withDataQa: false,
};

export default FilterJobs;
