import { useRoutes, RouteObject } from 'react-router-dom';
import ProtectedRoute from 'routes/components/ProtectedRoute';
import Index from 'routes';
import NotFoundPage from 'pages/NotFoundPage';
import { ReactElement } from 'react';

type RouteWithChildren = RouteObject & {
  children?: RouteWithChildren[];
  key: string;
  protectedRoute?: boolean;
};

const RenderRoutes = (): ReactElement | null => {
  const routes: RouteWithChildren[] = [
    ...Index.map((route: RouteWithChildren) => ({
      ...route,
      element:
        route.protectedRoute ? <ProtectedRoute>{route.element}</ProtectedRoute> : route.element,
      children:
        route.children ? route.children.map((child: RouteWithChildren) => ({
          ...child,
          element:
            child.protectedRoute ? <ProtectedRoute>{child.element}</ProtectedRoute> : child.element,
        })) : [],
    })),
    {
      path: '*',
      key: 'NOT_FOUND',
      element: <NotFoundPage />,
    },
  ];

  return useRoutes(routes);
};

export default RenderRoutes;
