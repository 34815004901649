import { Delimiter } from '../../types/collections.todo';

const generateURL = (
  path: string,
  queryParams: any,
  delimiter: Delimiter = Delimiter.QUESTION_MARK,
): string => {
  const filteredParams = Object.entries(queryParams)
    .filter((param) =>
      typeof queryParams[param[0]] === 'number' || queryParams[param[0]]);
  // @ts-ignore
  const query = new URLSearchParams(Object.fromEntries(filteredParams));

  return `${path}${delimiter}${query.toString()}`;
};

export default generateURL;
