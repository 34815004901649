import { useMemo } from 'react';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import countries from 'data/containers/countriesExtended';

interface CountryListItem {
  label: string;
  value: string;
  code: string;
  callingCode: string;
}

const useCountryList = (): CountryListItem[] => {
  return useMemo(() => countries.map(country => ({
    label: country.label,
    value: country.value,
    code: country.code,
    callingCode: getCountryCallingCode(country.code as CountryCode)
  })), []);
};

export default useCountryList;
