import { authorizationHeader } from 'utils/api/getHeaders';
import { HOST } from 'constants/api';
import checkStatus from 'utils/api/checkStatus';
import responseParser from 'utils/api/responseParser';
import errorParser from 'utils/api/errorParser';

const postMultipartApiData = async (path: string, body: any): Promise<any> => (
  fetch(`${HOST}${path}`, {
    method: 'POST',
    headers: authorizationHeader,
    body,
  })
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser)
);

export default postMultipartApiData;
