import { HOST } from 'constants/api';
import responseParser from 'utils/api/responseParser';
import checkStatus from 'utils/api/checkStatus';
import errorParser from 'utils/api/errorParser';
import jwtDecode from 'jwt-decode';
import { getHeaders, authorizationHeader } from './getHeaders';

// interface DecodedToken {
//   exp: number;
//   [key: string]: any;
// }

const getApiData = async (path: string, extraToken?: string): Promise<any> => {
  const headers = { headers: getHeaders() };
  const url = `${HOST}${path}`;

  return fetch(url, headers)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};

export default getApiData;
