import { useEffect, useState } from 'react';
import useWindowDimensions from './useWindowDimensions';

export const usePageOffsets = (): [number, number] => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const { width: viewportWidth } = useWindowDimensions();

  useEffect(() => {
    const headerSelector = document.querySelector('header')?.clientHeight || 0;
    const footerSelector = document.querySelector('footer')?.clientHeight || 0;
    setHeaderHeight(headerSelector);
    setFooterHeight(footerSelector);
  }, [viewportWidth]);

  return [headerHeight, footerHeight];
};
