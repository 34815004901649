import { ReactElement, Suspense } from 'react';
import PropTypes from 'prop-types';

const Loader = ({ children }: { children: ReactElement }): ReactElement => (
  <Suspense fallback={null}>
    {children}
  </Suspense>
);

Loader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Loader;
