import { memo, ReactElement } from 'react';
import {
  Radio,
  Form,
  FormInstance,
  InputNumber,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { decisionArray } from 'data/containers/formData';
import { useIntl } from 'react-intl';
import { convertArraySorted } from 'utils/convertArray';

interface IntakeFeeControlProps {
  form: FormInstance;
  name: string;
  label: string;
}

const IntakeFeeControl = ({
  form,
  name,
  label,
}: IntakeFeeControlProps): ReactElement => {
  const intl = useIntl();
  const { setFieldValue } = form;
  const inputName = `${name} Amount`;
  const inputLabel = `${label}Amount`;
  const radioOption = Form.useWatch([name], form);
  const chosenPositiveAnswer = radioOption === 'Yes';

  const onChangeRadioButton = (e: CheckboxChangeEvent): void => {
    setFieldValue(name, e.target.value);
    setFieldValue(inputName, '');
  };

  return (
    <>
      <Form.Item
        name={name}
        className="max-height-group"
        label={intl.formatMessage({ id: label })}
        rules={[{ required: true }]}
      >
        <Radio.Group options={convertArraySorted(decisionArray)} onChange={onChangeRadioButton} className="vertical-list" />
      </Form.Item>
      {chosenPositiveAnswer && (
        <Form.Item
          name={inputName}
          className="max-height-group"
          label={intl.formatMessage({ id: inputLabel })}
          rules={[{ required: chosenPositiveAnswer }]}
        >
          <InputNumber min={1} placeholder={intl.formatMessage({ id: inputLabel })} />
        </Form.Item>
      )}
    </>
  );
};

export default memo(IntakeFeeControl);
