import {
  ReactElement, useEffect, useState, useCallback,
} from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Checkbox,
  FormInstance,
  Row,
  Col,
  Radio,
  DatePicker,
  Select,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useIntl, FormattedMessage } from 'react-intl';
import { convertArraySorted } from 'utils/convertArray';
import { useMobileDesign } from 'hooks/useMobileDesign';
import {
  clientCountries,
  decisionArray,
  companyTypesArray,
  jobTypesArray,
  candidateLocationArray,
  numberOfInterviews,
  yearsInBusiness,
} from 'data/containers/formData';
import frequency from 'data/containers/frequency';
import { FormatterClient } from 'types/airtable';
import industryOfBusiness from 'data/containers/industryOfBusiness';
import Picker from 'components/Picker';
import IntakeSelect from 'components/IntakeSelect';
import IntakeFeeControl from 'components/IntakeFeeControl';
import StyledSwitch from 'components/StyledSwitch';
import styles from './TalentAcquisitionForm.module.scss';

interface TalentAcquisitionFormProps {
  form: FormInstance;
  loading: boolean;
  clientInitialValues: FormatterClient;
  submitForm: () => void;
}

const TalentAcquisitionForm = ({
  form, clientInitialValues, loading, submitForm,
}: TalentAcquisitionFormProps): ReactElement => {
  const intl = useIntl();
  const isMobile = useMobileDesign();
  const fieldsValues = Form.useWatch([], form);
  const [showInvoiceContact, setShowInvoiceContact] = useState(true);
  const [hideSkilledFields, setHideSkilledFields] = useState(false);
  const dateFormat = 'M/D/YYYY';

  const extraFields = {
    Frequency: frequency[0],
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true });
  }, [fieldsValues]);

  const disabledDate = (current: Dayjs): boolean => current && current < dayjs().endOf('day');

  const isFieldDisabled = (fieldName: keyof FormatterClient): boolean => (
    clientInitialValues && clientInitialValues[fieldName] !== undefined);

  const toggleShowInvoiceContact = useCallback(() => {
    setShowInvoiceContact(!showInvoiceContact);
  }, [showInvoiceContact]);

  const toggleTypeForm = useCallback(() => {
    setHideSkilledFields(!hideSkilledFields);
  }, [hideSkilledFields]);

  const validateAllowedCharacters = (_: any, value: string): Promise<void> => {
    const regex = /^[0-9$€£¥₹-\s]*$/;
    if (value && !regex.test(value)) {
      return Promise.reject(
        new Error(intl.formatMessage({ id: 'form.tao.invalidCharacters' })),
      );
    }
    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      scrollToFirstError
      name="intakeForm"
      autoComplete="off"
      className="form-container"
      initialValues={{ ...clientInitialValues, ...extraFields }}
      onFinish={submitForm}
    >
      <div className={styles.section}>
        <Col span={24}>
          <StyledSwitch onChange={toggleTypeForm} leftLabel='label.tao.skilled' rightLabel='label.tao.unskilled' />
        </Col>
        <h2>{intl.formatMessage({ id: 'form.tao.title.companyInformation' })}</h2>
        <Row gutter={[24, 16]}>
          <Col span={24} md={12} className="offset-right">
            <Form.Item name="Public or Private?" label={intl.formatMessage({ id: 'form.tao.companyType' })} rules={[{ required: true }]}>
              <Radio.Group
                buttonStyle="solid"
                options={convertArraySorted(companyTypesArray)}
                optionType="button"
                disabled={isFieldDisabled('Public or Private?')}
              />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item name="Company Name" label={intl.formatMessage({ id: 'form.tao.companyName' })} rules={[{ required: true }]}>
              <Input
                placeholder={intl.formatMessage({ id: 'form.tao.companyName.placeholder' })}
                disabled={isFieldDisabled('Company Name')}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="Company Website" label={intl.formatMessage({ id: 'form.tao.companyWebsite' })} rules={[{ required: true }]}>
              <Input
                placeholder={intl.formatMessage({ id: 'form.tao.companyWebsite.placeholder' })}
                disabled={isFieldDisabled('Company Website')}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <IntakeSelect
              list={clientCountries}
              form={form}
              fieldName="Client Country"
              fieldLabel="form.tao.clientCountry"
              placeholder="form.tao.clientCountry.placeholder"
              required
              disabled={isFieldDisabled('Client Country')}
            />
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="City" label={intl.formatMessage({ id: 'form.tao.city' })} rules={[{ required: true }]}>
              <Input
                placeholder={intl.formatMessage({ id: 'form.tao.city.placeholder' })}
                disabled={isFieldDisabled('City')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Company Address" label={intl.formatMessage({ id: 'form.tao.companyAddress' })} rules={[{ required: true }]}>
              <Input
                placeholder={intl.formatMessage({ id: 'form.tao.companyAddress.placeholder' })}
                disabled={isFieldDisabled('Company Address')}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="E-mail" label={intl.formatMessage({ id: 'form.tao.email' })} rules={[{ required: true }]}>
              <Input
                placeholder={intl.formatMessage({ id: 'form.tao.companyAddress.placeholder' })}
                disabled={isFieldDisabled('E-mail')}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="Phone Number" label={intl.formatMessage({ id: 'form.tao.phoneNumber' })} rules={[{ required: true }]}>
              <Input
                placeholder={intl.formatMessage({ id: 'form.tao.phoneNumber.placeholder' })}
                disabled={isFieldDisabled('Phone Number')}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <IntakeSelect
              list={industryOfBusiness}
              form={form}
              fieldName="Industry"
              fieldLabel="form.tao.industry"
              placeholder="form.tao.clientCountry.placeholder"
              disabled={isFieldDisabled('Industry')}
              required
            />
          </Col>
          <Col span={24} md={12}>
            <IntakeSelect
              list={yearsInBusiness}
              form={form}
              fieldName="Years in Business"
              fieldLabel="form.tao.yearsInBusiness"
              placeholder="form.tao.yearsInBusiness.placeholder"
              sortArray={false}
              disabled={isFieldDisabled('Years in Business')}
            />
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="Point of Contact" label={intl.formatMessage({ id: 'form.tao.pointOfContact' })} rules={[{ required: true }]}>
              <Input
                placeholder={intl.formatMessage({ id: 'form.tao.pointOfContact.placeholder' })}
                disabled={isFieldDisabled('Point of Contact')}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="POC Position" label={intl.formatMessage({ id: 'form.tao.position' })}>
              <Input
                placeholder={intl.formatMessage({ id: 'form.tao.position.placeholder' })}
                disabled={isFieldDisabled('POC Position')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <p>{intl.formatMessage({ id: 'label.tao.sendInvoice' })}</p>
            <StyledSwitch onChange={toggleShowInvoiceContact} />
          </Col>
          {showInvoiceContact && (
            <>
              <Col span={24} md={12}>
                <Form.Item
                  name="Invoicing name"
                  label={intl.formatMessage({ id: 'form.tao.invoicingContact' })}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={intl.formatMessage({ id: 'form.tao.invoicingContact.placeholder' })}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="Invoicing email"
                  label={intl.formatMessage({ id: 'form.tao.invoicingEmail' })}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={intl.formatMessage({ id: 'form.tao.invoicingEmail.placeholder' })}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </div>

      <div className={styles.section}>
        <h2>{intl.formatMessage({ id: 'form.tao.title.jobRequirements' })}</h2>
        <Row gutter={[24, 16]}>
          <Col span={24} md={12}>
            <Form.Item name="Job Title" label={intl.formatMessage({ id: 'form.tao.jobTitle' })} rules={[{ required: true }]}>
              <Input placeholder={intl.formatMessage({ id: 'form.tao.jobTitle.placeholder' })} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="Full Location (Internal purposes only)"
              label={intl.formatMessage({ id: 'form.tao.location' })}
              rules={[{ required: true }]}
            >
              <Input placeholder={intl.formatMessage({ id: 'form.tao.location.placeholder' })} />
            </Form.Item>
          </Col>
          {!hideSkilledFields && (
            <Col span={24} md={12} className="offset-right">
              <Form.Item name="Job Type" className="max-height-group" label={intl.formatMessage({ id: 'form.tao.jobType' })}>
                <Checkbox.Group options={convertArraySorted(jobTypesArray)} />
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12}>
            <Form.Item name="Work Schedule" label={intl.formatMessage({ id: 'form.tao.workSchedule' })} rules={[{ required: true }]}>
              <Input placeholder={intl.formatMessage({ id: 'form.tao.workSchedule.placeholder' })} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item name="Number of Openings" label={intl.formatMessage({ id: 'form.tao.numberOfOpenings' })} rules={[{ required: true }]}>
              <InputNumber min={1} placeholder={intl.formatMessage({ id: 'form.tao.numberOfOpenings.placeholder' })} />
            </Form.Item>
          </Col>
          {!hideSkilledFields && (
            <>
              <Col span={24}>
                <Form.Item
                  name="Job Description (EMPLOYER)"
                  className="max-height-group"
                  label={intl.formatMessage({ id: 'form.tao.jobDescription' })}
                  rules={[{ required: true }]}
                >
                  <Input.TextArea rows={7} placeholder={intl.formatMessage({ id: 'form.tao.jobDescription.placeholder' })} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="Qualifications" label={intl.formatMessage({ id: 'form.tao.previousExperience' })} rules={[{ required: true }]}>
                  <Input.TextArea rows={7} placeholder={intl.formatMessage({ id: 'form.tao.previousExperience.placeholder' })} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="Skill Requirement" label={intl.formatMessage({ id: 'form.tao.skillsetRequirements' })} rules={[{ required: true }]}>
                  <Input.TextArea rows={7} placeholder={intl.formatMessage({ id: 'form.tao.skillsetRequirements.placeholder' })} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="Drug Test"
                  className="max-height-group"
                  label={intl.formatMessage({ id: 'form.tao.drugTest' })}
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="Back Check"
                  className="max-height-group"
                  label={intl.formatMessage({ id: 'form.tao.backCheck' })}
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
                </Form.Item>
              </Col>
              <Col span={24} md={12} className="offset-right">
                <Form.Item
                  name="Domestic/Overseas"
                  className="max-height-group"
                  label={intl.formatMessage({ id: 'form.tao.candidateLocation' })}
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(candidateLocationArray)} className="vertical-list" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="Language Requirements" label={intl.formatMessage({ id: 'form.tao.languageRequirements' })}>
                  <Input placeholder={intl.formatMessage({ id: 'form.tao.languageRequirements.placeholder' })} />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </div>

      <div className={styles.section}>
        <h2>{intl.formatMessage({ id: 'form.tao.title.compensationBenefits' })}</h2>
        <Row gutter={[24, 16]}>
          <Col span={24} md={12}>
            <Form.Item
              name="Starting wage"
              label={intl.formatMessage({ id: 'form.tao.startingWage' })}
              rules={[
                { required: true },
                { validator: validateAllowedCharacters },
              ]}
            >
              <Input placeholder={intl.formatMessage({ id: 'form.tao.startingWage.placeholder' })} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="Frequency"
              label={intl.formatMessage({ id: 'label.tao.frequency' })}
              className="picker-mobile"
              rules={[{ required: true }]}
            >
              {!isMobile ? (
                <Select
                  options={convertArraySorted(frequency)}
                  placeholder={intl.formatMessage({ id: 'label.tao.frequency.placeholder' })}
                />
              ) : (
                <Picker
                  name="Frequency"
                    // @ts-ignore
                  form={form}
                  label={intl.formatMessage({ id: 'label.tao.frequency' })}
                  placeholder={intl.formatMessage({ id: 'label.tao.frequency.placeholder' })}
                  formatMessage={intl.formatMessage}
                  onReject={() => {}}
                  defaultValue={frequency[0]}
                  columnDataChildren={(
                    convertArraySorted(frequency).map(({ value, label }) => (
                      <Select.Option
                        key={value}
                        value={value}
                        title={label}
                        selectTitle={label}
                      >
                        {label}
                      </Select.Option>
                    ))
                  )}
                />
              )}
            </Form.Item>
          </Col>
          {!hideSkilledFields && (
            <Col span={24} md={12} className="offset-right">
              <Form.Item
                name="Benefits"
                className="max-height-group"
                label={intl.formatMessage({ id: 'form.tao.benefits' })}
                rules={[{ required: true }]}
              >
                <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
              </Form.Item>
            </Col>
          )}
        </Row>
      </div>
      
      {!hideSkilledFields && (
        <>
          <div className={styles.section}>
            <h2>{intl.formatMessage({ id: 'form.tao.title.culturalFit' })}</h2>
            <p className={styles.sectionDisclaimer}>{intl.formatMessage({ id: 'form.tao.culturalFit.description' })}</p>
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Form.Item
                  name="Soft Skill"
                  label={intl.formatMessage({ id: 'form.tao.culturalSoftSkills' })}
                  rules={[{ required: true }]}
                >
                  <Input.TextArea rows={7} placeholder={intl.formatMessage({ id: 'form.tao.culturalSoftSkills.placeholder' })} />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className={styles.section}>
            <h2>{intl.formatMessage({ id: 'form.tao.title.recruitmentProcess' })}</h2>
            <Row gutter={[24, 16]}>
              <Col span={24} md={12}>
                <Form.Item
                  name="Start date"
                  label={intl.formatMessage({ id: 'form.tao.startDate' })}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    // @ts-ignore
                    disabledDate={disabledDate}
                    format={dateFormat}
                    placeholder={dateFormat}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="Timelines & Comments"
                  label={intl.formatMessage({ id: 'form.tao.timelines' })}
                >
                  <Input placeholder={intl.formatMessage({ id: 'form.tao.timelines.placeholder' })} />
                </Form.Item>
              </Col>
              <Col span={24} md={12} className="offset-right">
                <Form.Item
                  name="# of Interviews"
                  className="max-height-group"
                  label={intl.formatMessage({ id: 'form.tao.numberInterviews' })}
                  rules={[{ required: true }]}
                >
                  <Radio.Group options={convertArraySorted(numberOfInterviews)} className="two-column-list" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="Preferred Interview Date"
                  label={intl.formatMessage({ id: 'form.tao.preferredInterviewDate' })}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    // @ts-ignore
                    disabledDate={disabledDate}
                    format={dateFormat}
                    placeholder={dateFormat}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={9} className="offset-right">
                <Button
                  type="primary"
                  loading={loading}
                  className="btn btn-primary zoom-button"
                  htmlType="button"
                  target="_blank"
                  block
                  href="https://scheduler.zoom.us/joblio-interview-scheduler/client_interview"
                >
                  <FormattedMessage id="form.tao.bookInterview"/>
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}

      <div className={styles.section}>
        <h2>{intl.formatMessage({ id: 'form.tao.title.feeSchedule' })}</h2>
        <Row gutter={[24, 16]}>
          <Col span={24} md={12}>
            <IntakeFeeControl
              form={form}
              name="Onboarding Fee"
              label="form.tao.onboardingFee"
            />
          </Col>
          <Col span={24} md={12}>
            <IntakeFeeControl
              form={form}
              name="Monthly ACE Fee"
              label="form.tao.monthlyACEFee"
            />
          </Col>
          <Col span={24} md={12}>
            <IntakeFeeControl
              form={form}
              name="Retention Fee"
              label="form.tao.retentionFee"
            />
          </Col>
          <Col span={24} md={12}>
            <IntakeFeeControl
              form={form}
              name="Direct Hire Fee"
              label="form.tao.directHireFee"
            />
          </Col>
          <Col span={24} md={12} className="offset-right">
            <Form.Item
              name="Other Fees"
              label={intl.formatMessage({ id: 'form.tao.otherFees' })}
            >
              <InputNumber placeholder={intl.formatMessage({ id: 'form.tao.otherFees' })} />
            </Form.Item>
          </Col>
          {!hideSkilledFields && (
            <Col span={24} md={12} className="offset-right">
              <Form.Item
                name="TFA"
                className="max-height-group"
                label={intl.formatMessage({ id: 'form.tao.timelyFeedbackAgreement' })}
                rules={[{ required: true }]}
              >
                <Radio.Group options={convertArraySorted(decisionArray)} className="vertical-list" />
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={12}>
            <Form.Item
              name="BusDev email"
              label={intl.formatMessage({ id: 'form.tao.busdev' })}
              rules={[{ required: true }]}
            >
              <Input placeholder={intl.formatMessage({ id: 'form.tao.busdev.placeholder' })} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="Sales Person Name"
              label={intl.formatMessage({ id: 'form.tao.submittedBy' })}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="max-height-group submit-container">
              <Button
                type="primary"
                loading={loading}
                className="btn btn-primary"
                htmlType="submit"
              >
                <FormattedMessage id="form.intake.submit" />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default TalentAcquisitionForm;
