import LoadingIndicator from 'components/LoadingIndicator';
import { bool } from 'prop-types';
import styles from './LoadingOverlay.module.scss';
import { ReactElement } from 'react';

const LoadingOverlay = ({ isLoading }: { isLoading: boolean }): ReactElement | null => (
  isLoading ? (
    <div className={styles.LoadingOverlay}>
      <LoadingIndicator />
    </div>
  ) : null
);

LoadingOverlay.propTypes = {
  isLoading: bool.isRequired,
};

export default LoadingOverlay;
