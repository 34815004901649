import {
  useState, useEffect, ReactElement,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { post } from 'utils/api/api';
import { setCredentials } from 'features/auth/authSlice';
import { selectShowSidebar, toggleSidebar } from 'features/sidebar/sidebarSlice';

import { INTAKE } from 'constants/pathNames';
import { AuthSteps, AuthMessages } from 'constants/enums';
import LoadingOverlay from 'components/LoadingOverlay';
import CheckEmail from './CheckEmail';
import Email from './Email';
import Login from './Login';
import Registration from './Registration';
import ChangePassword from './ChangePassword';
import styles from './Authentication.module.scss';

const Authentication = (): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const showSidebar = useSelector(selectShowSidebar);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authStep, setAuthStep] = useState<AuthSteps>(AuthSteps.CheckEmail);
  const [canSubmit, setCanSubmit] = useState(false);
  const [secondTitle, setSecondTitle] = useState('');
  const [title, setTitle] = useState('auth.label.userLogin');
  const [userName, setUserName] = useState('');

  const handleFieldsChange = (): void => {
    const fieldsValue = form.getFieldsValue();
    const fieldsError = form.getFieldsError();

    if (Object.keys(fieldsValue).every((field) => fieldsValue[field])
      && !fieldsError.some((field) => field.errors.length)) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const handleCheckEmail = (): void => {
    setIsLoading(true);
    const { email } = form.getFieldsValue(['email']);

    post('/auth/checkEmail', { email })
      .then(({ exists, existsInAirTable, name = '' }) => {
        setUserName(name);

        if (exists) {
          setAuthStep(AuthSteps.Login);
        } else if (!exists && existsInAirTable) {
          setAuthStep(AuthSteps.RegistrationOldUsers);
        } else {
          setAuthStep(AuthSteps.Registration);
        }
        setCanSubmit(false);
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleLogin = async (): Promise<void> => {
    setIsLoading(true);

    const { email, password } = form.getFieldsValue(['email', 'password']);

    return post('/auth/login', { email, password })
      .then(({ accessToken, userId, airtableUserId }) => {
        dispatch(setCredentials({
          token: accessToken,
          user: {
            airtableUserId,
            id: userId,
            email,
          },
        }));
        dispatch(toggleSidebar());
      })
      .catch(({ msg }) => {
        if (msg?.message === AuthMessages.IncorrectPassword) {
          form.setFields([
            {
              name: 'password',
              errors: [intl.formatMessage({ id: 'auth.validation.incorrectPassword' })],
            },
          ]);
        }
        console.error('Login error');
        setCanSubmit(false);
      })
      .finally(() => setIsLoading(false));
  };

  const handleForgotPassword = (): void => {
    setAuthStep(AuthSteps.ForgotPassword);
  };

  const handleChangePassword = (): void => {
    setIsLoading(true);

    const {
      email, password, verification,
    } = form.getFieldsValue(['email', 'password', 'verification']);

    post('/auth/password', { email, password, verification })
      .then(() => {
        handleLogin();
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRegistration = (): void => {
    setIsLoading(true);

    const {
      email, password, verification,
    } = form.getFieldsValue(['email', 'password', 'verification']);

    post('/auth/signup', { email, password, verification })
      .then(() => {
        handleLogin()
          .then(() => navigate(INTAKE));
      })
      .catch(() => {
        form.setFields([
          {
            name: 'verification',
            errors: [intl.formatMessage({ id: 'auth.validation.incorrectCode' })],
          },
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    switch (authStep) {
      case AuthSteps.Registration: {
        setSecondTitle('auth.label.welcomeToJoblio');
        setTitle('auth.label.createAccount');
        break;
      }
      case AuthSteps.RegistrationOldUsers: {
        setSecondTitle('auth.label.welcomeOldUsers');
        setTitle('auth.label.createAccount');
        break;
      }
      case AuthSteps.CheckEmail: {
        setSecondTitle('');
        setTitle('auth.label.userLogin');
        break;
      }
      case AuthSteps.Login: {
        setSecondTitle('');
        setTitle('auth.label.userLogin');
        break;
      }
      case AuthSteps.ForgotPassword: {
        setSecondTitle('auth.label.passwordRecovery');
        break;
      }
      default: {
        setSecondTitle('');
        setTitle('auth.label.userLogin');
        break;
      }
    }
  }, [authStep]);

  useEffect(() => (): void => {
    form.resetFields();
    setAuthStep(AuthSteps.CheckEmail);
  }, [showSidebar]);

  const renderContent = (step: AuthSteps): ReactElement => {
    switch (step) {
      case AuthSteps.Login:
        return (
          <Login
            handleSubmit={handleLogin}
            handleForgotPassword={handleForgotPassword}
            canSubmit={canSubmit}
          />
        );
      case AuthSteps.Registration:
      case AuthSteps.RegistrationOldUsers:
        return (
          <Registration
            form={form}
            handleSubmit={handleRegistration}
            canSubmit={canSubmit}
          />
        );
      case AuthSteps.ForgotPassword:
        return (
          <ChangePassword
            form={form}
            handleSubmit={handleChangePassword}
            canSubmit={canSubmit}
          />
        );
      case AuthSteps.CheckEmail:
      default:
        return (
          <CheckEmail
            handleSubmit={handleCheckEmail}
            canSubmit={canSubmit}
          />
        );
    }
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <h2 className={styles.title}><FormattedMessage id={title} /></h2>
      {secondTitle && <p className={styles.secondTitle}><FormattedMessage id={secondTitle} /></p>}
      {(userName && authStep === AuthSteps.Login) && (
        <p className={styles.secondTitle}><FormattedMessage id="auth.label.welcomeBack" values={{ userName }} /></p>
      )}
      <Form
        form={form}
        className="form-container"
        requiredMark={false}
        autoComplete="off"
        onFieldsChange={handleFieldsChange}
      >
        <Email authStep={authStep} />
        {renderContent(authStep)}
      </Form>
    </>
  );
};

export default Authentication;
