export const GTM_EVENTS = {
  SESSION_START: 'join_session_start', // triggered when user opens the app
  SELECT_LANGUAGE: 'join_select_language', // triggered when user selects a language from the dropdown on header
  SELECT_INDUSTRY: 'join_select_industry', // triggered when user selects an industry from the dropdown on header
  SELECT_COUNTRY: 'join_select_country', // triggered when user selects a country from the dropdown on filters or apply jobs page
  SELECT_GENDER: 'join_gender_select', // triggered when user selects gender from the dropdown on apply jobs page
  SELECT_COUNTRY_OF_ORIGIN: 'join_location_country', // triggered when user selects citizenship from the dropdown on apply jobs page
  PHONE_VALIDATE_START: 'join_phone_validate_start', // triggered when user clicks on validate phone number button on apply jobs page
  PHONE_VALIDATE_FINISH: 'join_phone_validate_finish', // triggered when user finishes validates phone number button on apply jobs page
  PHONE_COUNTER_SELECT: 'join_phone_country_select', // triggered when user selects phone country code from the dropdown on apply jobs page
  JOB_DRILL: 'join_job_details', // triggered when user clicks on a job card details on MAIN page
  JOB_DRILL_V: 'join_job_details', // triggered when user clicks on a job card details on Browser all jobs page
  BROWSE_ALL_JOBS: 'join_browse_all_jobs', // triggered when user clicks on the browse all jobs button on MAIN page
  SIGN_UP_EMAIL: 'join_sign_in_email', // triggered when user clicks on the sign up button on auth page
  SIGN_UP_GOOGLE: 'join_login_google', // triggered when user clicks on the sign up with google button on auth page
  SIGN_UP_FACEBOOK: 'join_login_facebook', // triggered when user clicks on the sign up with facebook button on auth page
  SIGN_UP_APPLE: 'join_login_apple', // triggered when user clicks on the sign up with apple button on auth page
  SIGN_IN_EMAIL: 'join_login_email', // triggered when user clicks on the sign in button on auth page
  JOB_APPLY_TOP: 'join_job_apply_start', // triggered when user clicks on the apply top button on job details page
  JOB_APPLY_BOTTOM: 'join_job_apply_start', // triggered when user clicks on the apply bottom button on job details page
  JOB_APPLY_FINISH: 'join_job_apply_finish', // triggered when user clicks on the apply button on job details page
  JOIN_ENTER_EMAIL: 'join_enter_email', // triggered when user clicks on the enter email button on auth page
  JOIN_ENTER_PASSWORD: 'join_enter_password', // triggered when user clicks on the enter password button on auth page
  TERMS_CONDITIONS: 'join_agree_t&c', // triggered when user clicks on the terms and conditions checkbox /employer or in apply jobs page
  ANDROID_DOWNLOAD: 'join_job_android_download', // triggered when user clicks on the android download button on finished last screen
  IOS_DOWNLOAD: ' join_job_ios_download', // triggered when user clicks on the ios download button on finished last screen
  LOGIN_LINK: 'join_login', // triggered when user clicks on the login link in the header
  FILTER_JOBS: 'join_filter_jobs', // triggered when user clicks on the filter button on filters modal
  CLEAR_JOBS: 'join_clear_filter', // triggered when user clicks on the clear button on filter modal
  CONGRATULATIONS: 'join_congratulations', // triggered when user is redirected to congratulations page
  DOWNLOAD_CUAET_GUIDE: 'join_download_cuaet_guide', // triggered when user clicks on the download cuaet guide button for Ukraine
  DARK_MODE: 'join_dark_mode', // triggered when user clicks on the dark mode toggle in the header
  INTAKE_FORM: 'join_intake_form', // triggered when user clicks on the intake form button on the finished last screen
  TALENT_ACQUISITION_FORM: 'join_talent_acquisition_form', // triggered when user clicks on the tao form button on the finished last screen
};
