export default {
  ENGLISH: 'en',
  RUSSIAN: 'ru',
  TAJIK: 'tg',
  ROMANIAN: 'ro',
  KAZAKH: 'kk',
  KYRGYZ: 'ky',
  UZBEK: 'uz',
  TURKMENI: 'tk',
  AZERBIJANI: 'az',
  UKRAINIAN: 'uk',
  ARMENIAN: 'hy',
  ITALIAN: 'it',
  GERMAN: 'de',
  FRENCH: 'fr',
  POLAND: 'pl',
  GEORGIAN: 'ka',
  TURKISH: 'tr',
  CROATIAN: 'hr',
  SPANISH: 'es',
  ARABIC: 'ar',
  BURMESE: 'my',
};
