import { FormattedMessage } from 'react-intl';
import getFormattedAmount from 'utils/getInfo/getFormattedAmount';
import styles from './Salary.module.scss';
import truncate from 'utils/truncate';

const Salary = ({
  jobInfo,
  styleName,
  dataQa,
}) =>
// const showMinGrossPay = getFormattedAmount(minGrossPay);
// const showMinNetPay = getFormattedAmount(minNetPay);
//
// const showMaxGrossPay = maxGrossPay && (maxGrossPay.money?.amount !== minGrossPay.money?.amount)
//     && ` ${isPreview ? '' : '-'} ${getFormattedAmount(maxGrossPay)}`;
// const showMaxNetPay = maxNetPay && (maxNetPay.money?.amount !== minNetPay.money?.amount)
//     && ` ${isPreview ? '' : '-'} ${getFormattedAmount(maxNetPay)}`;

  (
    <>
      <p>
        <span className={styles.JobsPrice} data-qa={dataQa.salary}>
          {truncate(jobInfo, 20)}
        </span>
      </p>
      {/* // : ( */}
      {/* //   <p> */}
      {/* //     <span className={styles[styleName]} data-qa={dataQa.salary}> */}
      {/* //       {isPreview ? (showMaxGrossPay || showMinGrossPay) : ( */}
      {/* //         <> */}
      {/* //           {showMinGrossPay} */}
      {/* //           {showMaxGrossPay} */}
      {/* //         </> */}
      {/* //       )} */}
      {/* //     </span> */}
      {/* //     <span className={styles.PeriodInfo} data-qa={dataQa.period}> */}
      {/* //       <FormattedMessage id={`job.pay_unit.${payPeriod?.toLowerCase()}`} /> */}
      {/* //     </span> */}
      {/* //   </p> */}
      {/* // )} */}
      {/* {bonus && ( */}
      {/* <p> */}
      {/*  <span className={styles[styleName]} data-qa={dataQa.bonusAmount}> */}
      {/*    {getFormattedAmount(bonus)} */}
      {/*  </span> */}
      {/*  <span className={styles.PeriodInfo} data-qa={dataQa.bonusPeriod}> */}
      {/*    <FormattedMessage id="job.bonus" /> */}
      {/*  </span> */}
      {/* </p> */}
      {/* )} */}
    </>
  );
export default Salary;
