const responseParser = async (response: Response): Promise<any> => {
  let result: any;

  try {
    result = await response.json();
  } catch {
    result = response;
  }

  return result;
};

export default responseParser;
