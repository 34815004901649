import { lazy, ReactElement } from 'react';
import {
  APPLY,
  APPLY_INVITE,
  JOB_DETAILS,
  JOBS,
  MAIN,
  USER,
  NOT_FOUND,
  INTAKE,
  INTAKE_THANK_YOU,
  TALENT_ACQUISITION,
  TALENT_ACQUISITION_THANK_YOU,
  TALENT_ACQUISITION_ACCEPT_DECLINE,
  ADAMO,
  AMBASSADOR,
  AMBASSADOR_JOBS,
  AMBASSADOR_INTAKE,
  AMBASSADOR_THANK_YOU,
  AMBASSADOR_DETAILS,
  CHAT, ADMIN,
  QUESTIONNAIRE,
  QUESTIONNAIRE_THANK_YOU,
  USER_PROFILE,
} from 'constants/pathNames';

import RouteWithLayout from 'routes/components/RouteWithLayout';
import BrowseJobsPage from 'pages/BrowseJobsPage';
import JobDetailsPage from 'pages/JobDetailsPage';
import ApplyInvitePage from 'pages/ApplyInvitePage';
import IntakePage from 'pages/IntakePage';
import AmbassadorIntakePage from 'pages/AmbassadorIntakePage';
import TalentAcquisitionPage from 'pages/TalentAcquisitionPage';
import TalentAcquisitionChangeStatusPage from 'pages/TalentAcquisitionChangeStatusPage';
import IntakeThankYouPage from 'pages/IntakeThankYouPage';
import AdamoPage from 'pages/AdamoPage';
import ChatPage from 'pages/ChatPage';
import QuestionnairePage from 'pages/QuestionnairePage';
import QuestionnaireThankYouPage from 'pages/QuestionnaireThankYouPage';
import UserProfilePage from 'pages/UserProfilePage';

const NotFoundPage = lazy(() => import(/* chunkName: 'NotFoundPage' */ 'pages/NotFoundPage'));

const MainPage = lazy(() => import(/* chunkName: 'MainPage' */ 'pages/MainPage'));

type RouteObject = {
  path: string;
  key: string;
  element: ReactElement;
  protectedRoute?: boolean;
  children?: RouteObject[];
};

const createRoute = (
  path: string,
  key: string,
  element: ReactElement,
  protectedRoute: boolean = false,
  children: RouteObject[] = [],
): RouteObject => ({
  path,
  key,
  protectedRoute,
  element,
  children,
});

const Index = [
  createRoute(APPLY, 'APPLY_ROOT', <RouteWithLayout />, false, [
  ]),
  createRoute(MAIN, 'MAIN_ROOT', <RouteWithLayout />, false, [
    createRoute(MAIN, 'MAIN', <MainPage />),
    createRoute(JOB_DETAILS, 'JOB_DETAILS', <JobDetailsPage />),
    createRoute(JOBS, 'JOBS', <BrowseJobsPage />),
    createRoute(APPLY_INVITE, 'APPLY_INVITE', <ApplyInvitePage />),
    createRoute(INTAKE, 'INTAKE', <IntakePage />),
    createRoute(INTAKE_THANK_YOU, 'INTAKE_THANK_YOU', <IntakeThankYouPage />),
    createRoute(ADAMO, 'ADAMO', <AdamoPage />),
    createRoute(AMBASSADOR, 'AMBASSADOR', <MainPage />),
    createRoute(AMBASSADOR_JOBS, 'AMBASSADOR_JOBS', <BrowseJobsPage />),
    createRoute(AMBASSADOR_INTAKE, 'AMBASSADOR_INTAKE', <AmbassadorIntakePage />),
    createRoute(AMBASSADOR_THANK_YOU, 'AMBASSADOR_THANK_YOU', <IntakeThankYouPage />),
    createRoute(AMBASSADOR_DETAILS, 'AMBASSADOR_DETAILS', <JobDetailsPage />),
    createRoute(TALENT_ACQUISITION, 'TALENT_ACQUISITION', <TalentAcquisitionPage />),
    createRoute(TALENT_ACQUISITION_ACCEPT_DECLINE, 'TALENT_ACQUISITION_ACCEPT', <TalentAcquisitionChangeStatusPage />),
    createRoute(TALENT_ACQUISITION_THANK_YOU, 'TALENT_ACQUISITION_THANK_YOU', <IntakeThankYouPage />),
    createRoute(QUESTIONNAIRE, 'QUESTIONNAIRE', <QuestionnairePage />),
    createRoute(QUESTIONNAIRE_THANK_YOU, 'QUESTIONNAIRE_THANK_YOU', <QuestionnaireThankYouPage />),
  ]),
  createRoute(ADMIN, 'ADMIN', <RouteWithLayout config={{ hideFooter: true }} />, false, [
    createRoute(CHAT, 'CHAT', <ChatPage />),
  ]),
  createRoute(USER, 'USER', <RouteWithLayout />, true, [
    createRoute(USER_PROFILE, 'USER_PROFILE', <UserProfilePage />, true),
  ]),
  createRoute(NOT_FOUND, 'NOT_FOUND', <NotFoundPage />),
];

export default Index;
