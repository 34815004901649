import { FC } from 'react';
import { Form, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as IconEmail } from 'assets/icons/email.svg';
import { AuthSteps } from 'constants/enums';

import styles from '../Authentication.module.scss';

interface EmailProps {
  authStep: AuthSteps;
}

const Email: FC<EmailProps> = ({ authStep }) => {
  const intl = useIntl();
  const isLoginStep = authStep !== AuthSteps.CheckEmail;

  return (
    <Form.Item
      label={intl.formatMessage({ id: 'form.email' })}
      name="email"
      data-qa="emailInputField"
      className={styles.emailContainer}
      rules={[
        {
          required: true,
          message: <FormattedMessage id="validation.email" />,
        },
        {
          type: 'email',
          message: <FormattedMessage id="validation.email.valid" />,
        },
      ]}
    >
      <Input
        suffix={<IconEmail className="icon" />}
        data-qa="emailInputField"
        disabled={isLoginStep}
      />
    </Form.Item>
  );
};

export default Email;
