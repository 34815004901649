import { ReactElement, useContext } from 'react';
import cs from 'classnames';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { toggleSidebar, selectShowSidebar } from 'features/sidebar/sidebarSlice';

import Authentication from 'components/Authentication';

import styles from './Sidebar.module.scss';

const Sidebar = (): ReactElement => {
  const dispatch = useDispatch();
  const showSidebar = useSelector(selectShowSidebar);

  return (
    <>
      {showSidebar && <div className={styles.overlay} />}
      <div className={cs(styles.container, { [styles.show]: showSidebar })}>
        <Button
          className={styles.closeButton}
          onClick={() => dispatch(toggleSidebar())}
        >
          <CloseIcon />
        </Button>
        <Authentication />
      </div>
    </>
  );
};

export default Sidebar;
