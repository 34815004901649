import { TAOFields } from 'types/airtable';
import { FormattedTalentAcquisitionPDF } from 'types/pdf';
import getFullDate from 'utils/getInfo/getFullDate';
import getPDFNotification from 'utils/getInfo/getPDFNotification';

const formatTalentAcquisitionPDFData = (fields: TAOFields): FormattedTalentAcquisitionPDF => ({
  approvalActivity: [
    { label: `${fields['E-mail']}\n${getFullDate()}`, value: '', type: 'text' },
    {
      label: `Notification\n${getFullDate()}`,
      value: getPDFNotification(fields['E-mail'], fields['Company Name']),
      type: 'text',
    },
  ],
  companyInformation: [
    { label: 'Company Name', value: fields['Company Name'], type: 'text' },
    { label: 'Company Website', value: fields['Company Website'] || '', type: 'link' },
    { label: 'Company Address', value: fields['Company Address'] || '', type: 'text' },
    { label: 'Industry of Business', value: fields.Industry, type: 'rounded' },
    { label: 'Company Type', value: fields['Public or Private?'], type: 'rounded' },
    { label: 'Years in Business', value: fields['Years in Business'] || '', type: 'rounded' },
    { label: 'Point of Contact', value: fields['Point of Contact'], type: 'text' },
    { label: 'Position', value: fields['POC Position'] || '', type: 'text' },
    { label: 'E-mail', value: fields['E-mail'], type: 'link' },
    { label: 'Phone Number', value: fields['Phone Number'], type: 'text' },
    {
      label: 'Invoicing Contact',
      value: fields['Invoicing name'] || fields['Point of Contact'],
      type: 'text',
    },
    {
      label: 'Invoicing Email',
      value: fields['Invoicing email'] || fields['E-mail'],
      type: 'text',
    },
  ],
  jobRequirements: [
    { label: 'Job Title', value: fields['Job Title'], type: 'text' },
    { label: 'Location', value: fields['Full Location (Internal purposes only)'], type: 'text' },
    { label: 'Job Type', value: (fields['Job Type'] && fields['Job Type'].join(' | ')) || '', type: 'rounded' },
    { label: 'Work Schedule', value: fields['Work Schedule'], type: 'text' },
    { label: 'Number of Openings', value: fields['Number of Openings'], type: 'text' },
    {
      label: 'Job Description',
      value: fields['Job Description (EMPLOYER)'],
      type: 'text',
      fullWidth: true,
    },
    {
      label: 'Previous Experience & Qualifications',
      value: fields.Qualifications,
      type: 'text',
      fullWidth: true,
    },
    {
      label: 'Skillset Requirements',
      value: fields['Skill Requirement'],
      type: 'text',
      fullWidth: true,
    },
    { label: 'Drug Test Required', value: fields['Drug Test'], type: 'rounded' },
    { label: 'Background Check Required', value: fields['Back Check'], type: 'rounded' },
    { label: 'Language Requirements', value: fields['Language Requirements'] || '', type: 'text' },
    { label: 'Domestic or International Candidates?', value: fields['Domestic/Overseas'], type: 'rounded' },
  ],
  compensationBenefits: [
    { label: 'Salary Range', value: fields['Starting wage'], type: 'text' },
    { label: 'Benefits', value: fields.Benefits, type: 'rounded' },
  ],
  culturalFit: [
    {
      label: 'Cultural & Soft Skills',
      value: fields['Soft Skill'],
      type: 'text',
      fullWidth: true,
    },
  ],
  recruitmentProcess: [
    { label: 'Timelines & Mutual Expectations', value: fields['Timelines & Comments'] || '', type: 'text' },
    { label: 'Estimated Start Date', value: getFullDate(fields['Start date']), type: 'text' },
    { label: 'Number of Interviews', value: fields['# of Interviews'], type: 'rounded' },
  ],
  feeSchedule: [
    {
      label: 'Onboarding Fee',
      value: fields['Onboarding Fee Amount'] ?? 'No',
      type: fields['Onboarding Fee Amount'] ? 'text' : 'rounded',
    },
    {
      label: 'Monthly ACE Fee',
      value: fields['Monthly ACE Fee Amount'] ?? 'No',
      type: fields['Monthly ACE Fee Amount'] ? 'text' : 'rounded',
    },
    {
      label: 'Retention Fee',
      value: fields['Retention Fee Amount'] ?? 'No',
      type: fields['Retention Fee Amount'] ? 'text' : 'rounded',
    },
    {
      label: 'Direct Hire Fee',
      value: fields['Direct Hire Fee Amount'] ?? 'No',
      type: fields['Direct Hire Fee Amount'] ? 'text' : 'rounded',
    },
    { label: 'Timely Feedback Agreement', value: fields.TFA, type: 'rounded' },
    { label: 'Submitted By', value: fields['Sales Person Name'] || '', type: 'text' },
  ],
});

export default formatTalentAcquisitionPDFData;
