import { SortDirectionType } from 'types/airtable';
import responseParser from 'utils/api/responseParser';
import checkStatus from 'utils/api/checkStatus';
import errorParser from 'utils/api/errorParser';

export const buildParams = (pageSize: number = 3, viewName: string = 'Grid View', additionalParams: object = {}): URLSearchParams =>
  new URLSearchParams({
    view: viewName,
    pageSize: pageSize.toString(),
    ...additionalParams,
  });

export const buildSortParams = (sortField: string = '', sortDirection: SortDirectionType = 'asc'): string =>
  (sortField ? `sort[0][field]=${sortField}&sort[0][direction]=${sortDirection}` : '');

export const fetchData = async (endpoint: string, options = {}): Promise<any> => {
  return fetch(endpoint, options)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
};
