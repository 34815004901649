import { Spin } from 'antd';
import { useIntl } from 'react-intl';
import styles from './LoadingIndicator.module.scss';
import { ReactElement } from 'react';

export default (): ReactElement => {
  const intl = useIntl();
  const loadingText = `${intl.formatMessage({ id: 'utils.loading' })}...`;

  return (
    <Spin
      size="large"
      data-qa="loadingCircle"
      className={styles.LoadingIndicator}
    >
      {loadingText}
    </Spin>
  );
};
