import { ReactElement, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'config/antdThemeConfig';
import { ConfigProvider } from 'antd';
import { AppDispatch } from 'app/store';
import { fetchUserData } from 'features/auth/authThunks';
import { selectAppLocale, selectAppLocaleMessages } from 'features/locale/localeSlice';
import { selectAuthToken } from 'features/auth/authSlice';
import RenderRoutes from 'routes/components/RenderRoutes';
import useAppVersionCheck from 'hooks/useAppVersionCheck';
import ErrorBoundary from './layouts/ErrorBoundary';
import Loader from './layouts/Suspense';
import GDPRPopup from './components/GDPRPopup';
import LOCALES from './constants/locales';

const App = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const messages = useSelector(selectAppLocaleMessages);
  const locale = useSelector(selectAppLocale);
  const { isOldVersion, clearCache, error } = useAppVersionCheck();

  useEffect(() => {
    if (token) {
      dispatch(fetchUserData());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (isOldVersion) {
      clearCache();
    }
  }, [isOldVersion]);

  if (error) {
    console.error(error);
  }

  return (
    <>
      <ConfigProvider theme={theme}>
        {messages && (
          <IntlProvider
            locale={locale}
            defaultLocale={LOCALES.ENGLISH}
            messages={messages}
          >
            <Loader>
              <ErrorBoundary>
                <Router>
                  <RenderRoutes />
                </Router>
                <GDPRPopup />
              </ErrorBoundary>
            </Loader>
          </IntlProvider>
        )}
      </ConfigProvider>
    </>
  );
};

export default App;
