import { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Checkbox,
  FormInstance,
  Row,
  Col,
} from 'antd';
import cs from 'classnames';
import { InfoCircleOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useIntl, FormattedMessage } from 'react-intl';
import { convertArray } from 'utils/convertArray';
import { LEGAL_BASE_URL, PRIVACY_PATH } from 'constants/application';
import jobAreas from 'data/containers/jobAreas';
import languages from 'data/containers/languages';
import countriesExtended from 'data/containers/countriesExtended';
import skillSets from 'data/containers/skillSets';
import CountryPreview from 'components/CountryPreview';
import SelectCustom from 'components/SelectCustom';
import MultiSelectCustom from 'components/MultiSelectCustom';
import PhoneSelect from 'components/PhoneSelect';

interface IntakeFormProps {
  form: FormInstance;
  loading: boolean;
  submitForm: () => void;
  initialValues?: Record<string, any>;
}

const IntakeForm = ({
  form, loading, submitForm, initialValues = {},
}: IntakeFormProps): ReactElement => {
  const intl = useIntl();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState<boolean>(false);
  const [showStateField, setShowStateField] = useState<boolean>(false);
  const fieldsValues = Form.useWatch([], form);

  const defaultValidateMessages = {
    required: 'Field is required',
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => setIsFormValid(true),
      () => setIsFormValid(false),
    );
  }, [fieldsValues]);

  useEffect(() => {
    if (fieldsValues && fieldsValues['Currently Residing']) {
      const countriesToShowStateField = ['United States', 'Canada'];
      setShowStateField(countriesToShowStateField.includes(fieldsValues['Currently Residing']));
    } else {
      setShowStateField(false);
    }
  }, [fieldsValues]);

  useEffect(() => {
    if (initialValues.Email) {
      form.setFieldsValue({ Email: initialValues.Email });
    }
  }, [initialValues.Email, form]);

  const onChangePrivacyCheckbox = (e: CheckboxChangeEvent): void => {
    setIsPrivacyChecked(e.target.checked);
  };

  const isFieldDisabled = (fieldName: string): boolean => (
    initialValues && initialValues[fieldName] !== undefined);

  const privacyPolicyLink = (
    <a
      href={`${LEGAL_BASE_URL}en${PRIVACY_PATH}`}
      target="_blank"
      rel="noreferrer"
      aria-label={intl.formatMessage({ id: 'form.intake.policyLink' })}
    >
      <FormattedMessage id="form.intake.policyLink" />
    </a>
  );

  const renderCountryOption = (item: { value: string; label: string; code?: string }): ReactElement => (
    <CountryPreview countryCode={item.code} countryName={item.label} />
  );

  const renderCountryLabel = (option: { value: string; label: string }): ReactElement => (
    <CountryPreview countryName={option.label} />
  );

  return (
    <Form
      form={form}
      scrollToFirstError
      name="intakeForm"
      autoComplete="off"
      className="form-container"
      validateMessages={defaultValidateMessages}
      initialValues={{ ...initialValues }}
    >
      <Row gutter={[24, 16]}>
        <Col span={24} md={12}>
          <Form.Item
            name="First Name"
            label={intl.formatMessage({ id: 'form.intake.firstName' })}
            rules={[{ required: true }]}
          >
            <Input placeholder={intl.formatMessage({ id: 'form.intake.placeholder.firstName' })} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Last Name"
            label={intl.formatMessage({ id: 'form.intake.lastName' })}
            rules={[{ required: true }]}
          >
            <Input placeholder={intl.formatMessage({ id: 'form.intake.placeholder.lastName' })} />
          </Form.Item>
        </Col>
        <Col span={24} md={12} className={cs({ 'offset-right': !showStateField })}>
          <Form.Item
            name="Currently Residing"
            label={intl.formatMessage({ id: 'form.intake.countryOfLivingNew' })}
            className="picker-mobile"
            rules={[{ required: true }]}
          >
            <SelectCustom
              name="Currently Residing"
              form={form}
              options={countriesExtended}
              placeholderId="form.intake.placeholder.countryOfLivingNew"
              labelId="form.intake.countryOfLivingNew"
              optionRender={renderCountryOption}
              labelRender={renderCountryLabel}
            />
          </Form.Item>
        </Col>
        {showStateField && (
          <Col span={24} md={12}>
            <Form.Item
              name="State/Province of residence"
              label={intl.formatMessage({ id: 'form.intake.stateOfResidence' })}
              rules={[{
                pattern: /^[a-zA-Z\- ]*$/,
                message: intl.formatMessage({ id: 'form.intake.validation.latinCharacters' }),
              }]}
            >
              <Input placeholder={intl.formatMessage({ id: 'form.intake.placeholder.stateOfResidence' })} />
            </Form.Item>
          </Col>
        )}
        <Col span={24} md={12}>
          <Form.Item
            name="City of Residence"
            label={intl.formatMessage({ id: 'form.intake.cityOfResidence' })}
            rules={[{
              pattern: /^[a-zA-Z\- ]*$/,
              message: intl.formatMessage({ id: 'form.intake.validation.latinCharacters' }),
            }]}
          >
            <Input placeholder={intl.formatMessage({ id: 'form.intake.placeholder.cityOfResidence' })} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Mailing Address"
            label={intl.formatMessage({ id: 'form.intake.mailAddress' })}
            tooltip={{
              title: intl.formatMessage({ id: 'form.intake.mailAddress.info' }),
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input placeholder={intl.formatMessage({ id: 'form.intake.mailAddress.placeholder' })} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Citizenship"
            label={intl.formatMessage({ id: 'form.intake.citizenshipNew' })}
            rules={[{ required: true }]}
          >
            <MultiSelectCustom
              name="Citizenship"
              labelId="form.intake.citizenshipNew"
              form={form}
              options={countriesExtended}
              optionRender={renderCountryOption}
              labelRender={renderCountryLabel}
              placeholderId="form.intake.placeholder.citizenshipNew"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item name="Phone Number" label={intl.formatMessage({ id: 'form.intake.phone' })} rules={[{ required: true }]}>
            <PhoneSelect name="Phone Number" form={form} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item name="Email" label={intl.formatMessage({ id: 'form.intake.email' })} initialValue="" rules={[{ required: true }]}>
            <Input
              placeholder={intl.formatMessage({ id: 'form.intake.placeholder.email' })}
              disabled={isFieldDisabled('Email')}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Languages"
            label={intl.formatMessage({ id: 'form.intake.languages' })}
            rules={[{ required: true }]}
          >
            <MultiSelectCustom
              name="Languages"
              labelId="form.intake.languages"
              form={form}
              options={convertArray(languages)}
              placeholderId="form.intake.placeholder.languages"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="What are your skills"
            label={intl.formatMessage({ id: 'form.intake.professionNew' })}
            rules={[{ required: true }]}
          >
            <MultiSelectCustom
              name="What are your skills"
              labelId="form.intake.professionNew"
              form={form}
              options={convertArray(skillSets)}
              placeholderId="form.intake.placeholder.professionNew"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="Looking for a job in the following areas"
            label={intl.formatMessage({ id: 'form.intake.occupationNew' })}
            rules={[{ required: true }]}
          >
            <MultiSelectCustom
              name="Looking for a job in the following areas"
              labelId="form.intake.occupationNew"
              form={form}
              options={convertArray(jobAreas)}
              placeholderId="form.intake.placeholder.occupationNew"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="max-height-group"
            rules={[{ required: true }]}
          >
            <Checkbox onChange={onChangePrivacyCheckbox}>
              <FormattedMessage id="form.intake.policy" values={{ link: privacyPolicyLink }} />
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div className='intake-submit-container'>
        <Button
          type="primary"
          loading={loading}
          className="btn btn-primary intake-button"
          htmlType="submit"
          onClick={submitForm}
          disabled={!isFormValid || !isPrivacyChecked}
        >
          <FormattedMessage id="form.intake.submit" />
        </Button>
      </div>
    </Form>
  );
};

export default IntakeForm;
